import { useAuth0 } from '@auth0/auth0-react'
import logo from 'images/logo512.png'
import { Link } from 'react-router-dom'
import Path, { Menu, MenuForDesigner } from 'routes/Path'
import MenuLogout from 'images/menu-logout.svg'
import { Role, useAuth } from 'providers/AuthProvider'

interface Props {
  isMinimized: boolean
}

export default function SidebarMenu(prop: Props) {
  const { logout } = useAuth0()
  const { getRole } = useAuth()
  const activePathname = window.location.pathname

  const getLinkStyle = (path: string) => {
    const style = `${
      prop.isMinimized ? 'sidebar-menu-item-minimized' : 'sidebar-menu-item'
    } items-center flex`
    let isSelected = activePathname === path
    // for all quizzes path
    if (path === Path.quizzes.path) {
      isSelected = activePathname.indexOf(Path.quizzes.path) !== -1
    }
    // for all architectures path
    else if (path === Path.architectures.path) {
      isSelected = activePathname.indexOf(Path.architectures.path) !== -1
    }

    let bg = ''
    if (isSelected) {
      bg = prop.isMinimized
        ? 'sidebar-menu-selected-bg-min'
        : 'sidebar-menu-selected-bg'
    }
    return `${style} ${bg}`
  }

  const getLogoStyle = () => {
    const style = 'h-[88px] w-[92px]'
    return prop.isMinimized ? `${style}` : `${style} mr-[48px]`
  }

  const menuByRole = getRole() === Role.Designer ? MenuForDesigner : Menu

  return (
    <div className="sidebar-gradient-bg h-max side-menu">
      <div className="flex min-h-screen w-full flex-col items-center justify-between py-[8vh]">
        <img src={logo} className={getLogoStyle()} alt="logo" />
        <div className="sidebar-menu-scrollbar flex w-full flex-col items-center">
          {menuByRole.map((item, index) => (
            <Link
              data-testid={`link-${item.title}`}
              key={index}
              className={getLinkStyle(item.path)}
              to={item.path}
            >
              {prop.isMinimized && (
                <div className="ml-[36px] flex justify-center">
                  <img src={item.icon} alt="icon" width={'80%'} />
                </div>
              )}
              {!prop.isMinimized && (
                <div className="ml-[34px] w-[140px] text-left">
                  <span className="mentem-button-sidebar text-[#121419]">
                    {item.title}
                  </span>
                </div>
              )}
            </Link>
          ))}
        </div>
        <button
          data-testid="btn-logout"
          className="rounded-[30px] bg-[#FFFFFF] py-[10px]"
          onClick={() =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            })
          }
        >
          {prop.isMinimized && (
            <div className="flex justify-center px-[20px]">
              <img src={MenuLogout} alt="menu-logout" />
            </div>
          )}
          {!prop.isMinimized && (
            <span className="mentem-button-sidebar px-[30px]">Log out</span>
          )}
        </button>
      </div>
    </div>
  )
}
