import { useCallback, useEffect, useState } from 'react'
import { Permission, Role, useAuth } from 'providers/AuthProvider'
import ManageImages from 'views/ManageImages'
import UploadImages from 'views/UploadImages'
import ManageTags from 'views/ManageTags'
import { useApi } from '../providers/ApiProvider'

enum Section {
  None = 0,
  Search = 1,
  Upload = 2,
  ManageImages = 3,
  ManageTags = 4,
}

export default function ImagesSection() {
  const { pingApiUtility } = useApi()
  const [section, setSection] = useState<Section>(Section.None)
  const { getRole, hasPermission } = useAuth()
  // const getRole = () => {
  //   return Role.Admin
  // }
  // const hasPermission = (permission: Permission) => {
  //   if (permission === Permission.CreateImages) {
  //     return true
  //   } else if (permission === Permission.UpdateImages) {
  //     return true
  //   } else if (permission === Permission.DeleteImages) {
  //     return true
  //   } else if (permission === Permission.ReadImages) {
  //     return true
  //   }
  // }
  const isActiveSection = (targetSection: Section) => {
    let className =
      section === targetSection
        ? 'text-white bg-primary border-primary'
        : 'text-[#3C415D] bg-white'
    className = `${className} mentem-menu-button btn-sm btn rounded-3xl hover:text-white hover:bg-primary hover:border-primary normal-case ml-6 mr-6 mt-2 mb-2`
    return className
  }

  const switchSection = (section: Section) => {
    setSection(section)
  }

  const onSelectSearchImages = () => {
    switchSection(section === Section.Search ? Section.None : Section.Search)
  }

  const onSelectUploadImages = () => {
    switchSection(section === Section.Upload ? Section.None : Section.Upload)
  }

  const onSelectManageImages = () => {
    switchSection(
      section === Section.ManageImages ? Section.None : Section.ManageImages,
    )
  }

  const onSelectManageTags = () => {
    switchSection(
      section === Section.ManageTags ? Section.None : Section.ManageTags,
    )
  }

  const canManageImages = useCallback((): Boolean => {
    if (getRole() === Role.SuperAdmin) {
      return true
    }
    if (
      hasPermission(Permission.ReadImages) ||
      hasPermission(Permission.CreateImages) ||
      hasPermission(Permission.UpdateImages) ||
      hasPermission(Permission.DeleteImages)
    ) {
      return true
    }
    return false
  }, [getRole, hasPermission])

  useEffect(() => {
    // for author default to search
    setSection(canManageImages() ? Section.Search : Section.None)
  }, [canManageImages])

  useEffect(() => {
    pingApiUtility()
  }, [pingApiUtility])

  return (
    <>
      {canManageImages() && (
        <div className="pt-8 text-center">
          <button
            data-testid="btn-search-images"
            className={isActiveSection(Section.Search)}
            onClick={onSelectSearchImages}
          >
            <span className="pl-1 pr-1">Search</span>
          </button>
          {(getRole() === Role.SuperAdmin || getRole() === Role.Designer) && (
            <>
              <button
                data-testid="btn-upload-images"
                className={isActiveSection(Section.Upload)}
                onClick={onSelectUploadImages}
              >
                <span className="pl-1 pr-1">Upload</span>
              </button>
              <button
                data-testid="btn-manage-images"
                className={isActiveSection(Section.ManageImages)}
                onClick={onSelectManageImages}
              >
                <span className="pl-1 pr-1">Manage images</span>
              </button>
              <button
                data-testid="btn-manage-tags"
                className={isActiveSection(Section.ManageTags)}
                onClick={onSelectManageTags}
              >
                <span className="pl-1 pr-1">Manage subjects</span>
              </button>
            </>
          )}
        </div>
      )}

      {section === Section.Search && <ManageImages mode="SEARCH" />}
      {section === Section.Upload && <UploadImages />}
      {section === Section.ManageImages && <ManageImages mode="MANAGE" />}
      {section === Section.ManageTags && <ManageTags />}
    </>
  )
}
