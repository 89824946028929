import { useEffect, useState } from 'react'

export enum CheckboxSize {
  Small = '18',
  Medium = '24',
  Large = '30',
}

export enum CheckboxTheme {
  Normal = '#000000',
  White = '#FFFFFF',
  Primary = '#BA61FF',
  Secondary = '#92929D',
  Disabled = '#D9D9D9',
}

interface CheckboxProps {
  id: string
  checked: boolean
  onChange?: (checked: boolean) => void
  size?: CheckboxSize
  theme?: CheckboxTheme
}

export default function Checkbox({
  id,
  checked,
  onChange,
  size,
  theme,
}: CheckboxProps) {
  const [localChecked, setLocalChecked] = useState(checked)
  const localSize = size || CheckboxSize.Medium
  const localTheme = theme || CheckboxTheme.Normal

  const selectedSvg = () => {
    return (
      <svg
        width={localSize}
        height={localSize}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 2.25H5C3.75736 2.25 2.75 3.25736 2.75 4.5V13.5C2.75 14.7426 3.75736 15.75 5 15.75H14C15.2426 15.75 16.25 14.7426 16.25 13.5V4.5C16.25 3.25736 15.2426 2.25 14 2.25ZM14.75 13.5C14.75 13.9142 14.4142 14.25 14 14.25H5C4.58579 14.25 4.25 13.9142 4.25 13.5V4.5C4.25 4.08579 4.58579 3.75 5 3.75H14C14.4142 3.75 14.75 4.08579 14.75 4.5V13.5ZM8.69091 10.0425L11.5259 6.29249H11.5184C11.7711 5.96112 12.2445 5.89732 12.5759 6.14999C12.9073 6.40265 12.9711 6.87612 12.7184 7.20749L9.29091 11.7075C9.14931 11.8914 8.93053 11.9994 8.69841 12C8.46757 12.0012 8.24902 11.8961 8.10591 11.715L6.28341 9.38249C6.02866 9.05526 6.08743 8.58348 6.41466 8.32874C6.74188 8.074 7.21366 8.13276 7.46841 8.45999L8.69091 10.0425Z"
          fill={localTheme}
        />
      </svg>
    )
  }

  const deselectedSvg = () => {
    return (
      <svg
        width={localSize}
        height={localSize}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 15.75H5H5C3.75736 15.75 2.75 14.7426 2.75 13.5V4.5V4.5C2.75 3.25736 3.75736 2.25 5 2.25H14C15.2426 2.25 16.25 3.25736 16.25 4.5V13.5C16.25 14.7426 15.2426 15.75 14 15.75ZM5 3.75C4.58579 3.75 4.25 4.08579 4.25 4.5V13.5C4.25 13.9142 4.58579 14.25 5 14.25H14C14.4142 14.25 14.75 13.9142 14.75 13.5V4.5C14.75 4.08579 14.4142 3.75 14 3.75L5 3.75Z"
          fill={localTheme}
        />
      </svg>
    )
  }

  useEffect(() => {
    setLocalChecked(checked)
  }, [checked])

  return (
    <div
      id={id}
      className="flex items-center justify-center cursor-pointer"
      onClick={() => {
        setLocalChecked(!localChecked)
        if (onChange) onChange(!localChecked)
      }}
    >
      {localChecked ? selectedSvg() : deselectedSvg()}
    </div>
  )
}
