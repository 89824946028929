import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GeneratedQuestion } from 'apis/entities/quiz.entity'

interface QuestionsState {
  // for authoring
  generatedQuestions: GeneratedQuestion[]
}

// Define initial state
const initialState: QuestionsState = {
  generatedQuestions: {} as GeneratedQuestion[],
}

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    storeGeneratedQuestions(
      state,
      action: PayloadAction<{ generatedQuestions: GeneratedQuestion[] }>,
    ) {
      const { generatedQuestions } = action.payload
      state.generatedQuestions = generatedQuestions
    },
    updateGeneratedQuestion(
      state,
      action: PayloadAction<{
        index: number
        generatedQuestion: GeneratedQuestion
      }>,
    ) {
      const { index, generatedQuestion } = action.payload
      state.generatedQuestions[index] = generatedQuestion
    },
    deleteGeneratedQuestions(state) {
      state.generatedQuestions = []
    },
  },
})

// Extract action creators from the slice
export const {
  storeGeneratedQuestions,
  updateGeneratedQuestion,
  deleteGeneratedQuestions,
} = questionsSlice.actions

export const currentQuestions = (state: { value: QuestionsState }) =>
  state.value

// Export the reducer
export default questionsSlice.reducer
