import { useCallback, useEffect, useRef, useState } from 'react'
import { Image } from 'apis/entities/image.entity'
import ImageGrid from './ImageGrid'
import Spinner from './Spinner'
import ImageCarousel from './ImageCarousel'
import { useApi } from 'providers/ApiProvider'
import SearchTagsInput from './SearchTagsInput'
import { ToastUtil } from 'utils/ToastUtil'
import { StyleUtil } from 'utils/StyleUtil'
import { Permission, useAuth } from 'providers/AuthProvider'
import { Category } from 'apis/entities/category.entity'
import { ImageCategory } from 'core/constants'
import { Tag } from 'apis/entities/tag.entity'
import DropDownMenu, { DropDownItem } from './DropDownMenu'
import ChipList, { ChipItem } from './ChipList'

export default function ManageImages(props: { mode?: string }) {
  const refSearchTextInput = useRef<string>('')
  const [showNumber, setShowNumber] = useState<number>(24)
  const {
    deleteImagesByTagIds,
    deleteImages,
    reassign,
    getImagesBySearch,
    getImageCategories,
    getTags,
    setImageCategory,
  } = useApi()
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [showResult, setShowResult] = useState<boolean>(false)
  const [showPage, setShowPage] = useState<number>(1)
  const [nextPage, setNextPage] = useState<number>(-1)
  const [images, setImages] = useState<Image[]>([])
  const [selectedImageId, setSelectedImageId] = useState<string>('')
  const [hiddenCarousel, setHiddenCarousel] = useState<boolean>(true)
  const [tags, setTags] = useState<Tag[]>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [selectedCategory, setSelectedCategory] = useState<Category>()
  const [reassignModalCategoryHACKY, setReassignModalCategoryHACKY] =
    useState<Category>()

  const [isFetchedTags, setIsFetchedTags] = useState<boolean>(false)
  const [isFetchedCategories, setIsFetchedCategories] = useState<boolean>(false)
  const refSubcategoryIds = useRef<string[]>([])
  const refTagIds = useRef<string[]>([])
  const separator = ','

  const { hasPermission } = useAuth()
  const [isLoadingDeleteFromImageCatalog, setIsLoadingDeleteFromImageCatalog] =
    useState<boolean>(false)
  const [isLoadingReassignSubjects, setIsLoadingReassignSubjects] =
    useState<boolean>(false)
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([])
  const [isDeleteFromImageCatalog, setIsDeleteFromImageCatalog] =
    useState<boolean>(false)
  const [isReassignSubjects, setIsReassignSubjects] = useState<boolean>(false)
  const [reassignTagIds, setReassignTagIds] = useState<string[]>([])
  const refSelectAll = useRef<HTMLInputElement>(null)
  const refInputDescription = useRef<HTMLInputElement | null>(null)
  const [selectedDescription, setSelectedDescription] = useState<string>('')

  const fetchImageCategories = useCallback(async () => {
    try {
      const categories = await getImageCategories()
      setCategories([
        ...categories.sort((a, b) => a.name.localeCompare(b.name)),
        {
          id: '_ALL_',
          name: 'All types',
          subcategories: [],
        },
      ])
      setIsFetchedCategories(true)
      // default selected category
      if (categories.length > 0) {
        setSelectedCategory(categories[0])
      }
    } catch (err) {
      console.error(err)
      setCategories([])
    }
  }, [getImageCategories])

  const fetchTags = useCallback(async () => {
    try {
      const tags = await getTags()
      setTags(tags)
      setIsFetchedTags(true)
    } catch (err) {
      console.error(err)
      setTags([])
    }
  }, [getTags])

  const isFetched = (): boolean => {
    return isFetchedTags && isFetchedCategories
  }

  const searchImages = async (page?: number, size?: number) => {
    console.log(`searchImages=${refSearchTextInput.current}`)
    // TODO: deprecated, remove later
    const tagNames = refSearchTextInput.current
      .split(separator)
      .filter((v) => v.trim().length > 0)
      .join(separator)
    // reset
    setImages([])
    setIsSearching(true)
    setShowResult(false)
    const tSize = size || 0
    const tPage = tSize === 0 ? 0 : page || 1
    // const tagIds = refSelectedTagId.current ? [refSelectedTagId.current] : []
    const tagIds = refTagIds.current
    const categoryIds = selectedCategory ? [selectedCategory.id] : []
    const description = refSearchTextInput.current
    const [images, tmpNextPage] = await getImagesBySearch(
      tPage,
      tSize,
      tagNames,
      tagIds,
      categoryIds,
      refSubcategoryIds.current,
      description,
    )
    setShowPage(tPage)
    setNextPage(tmpNextPage)
    setIsSearching(false)
    // console.log(images)
    setImages(images || [])
    setSelectedImageId('')
    setShowResult(true)

    setIsDeleteFromImageCatalog(false)
  }

  // 0 = all
  const onSelectShowNumber = async (item: DropDownItem) => {
    // reset page
    const value = item.value || 0
    setShowPage(1)
    setShowNumber(value)
    await searchImages(1, value)
  }

  const onCopy = (url: string) => {
    navigator.clipboard.writeText(encodeURI(url))
    ToastUtil.success('URL link copied to clipboard')
  }

  const onSelectImages = (imageId: string, checked: boolean) => {
    if (checked) {
      setSelectedImageIds([...selectedImageIds, imageId])
    } else {
      setSelectedImageIds(selectedImageIds.filter((id) => id !== imageId))
    }
  }

  const onPageLeft = async () => {
    // previous page
    const page = Math.max(showPage - 1, 1)
    await searchImages(page, showNumber || 0)
  }

  const onPageRight = async () => {
    // next page
    await searchImages(nextPage, showNumber || 0)
  }

  const onSearchTextInputChanged = (value: string) => {
    refSearchTextInput.current = value
  }

  const showInputSubjects = (): boolean => {
    const list = [
      ImageCategory.BackgroundImages.valueOf(),
      // ImageCategory.OtherImages.valueOf(),
      // ImageCategory.DigitalBadges.valueOf(),
    ]
    return (selectedCategory && list.includes(selectedCategory.name)) || false
  }

  const onSelectedCategory = (item: DropDownItem) => {
    // reset subjects, description and subcategories
    // refSelectedTagId.current = undefined
    refTagIds.current = []
    refSubcategoryIds.current = []
    refSearchTextInput.current = ''
    setSelectedDescription('')
    setSelectedImageIds([])
    const category = categories.find((category) => item.id === category.id)
    if (category === undefined || selectedCategory?.id === category.id) return
    setSelectedCategory(category)
    // searchImages(1, showNumber || 0) // already triggered by useEffect
  }

  const onSelectedChipList = (items: ChipItem[]) => {
    refTagIds.current = items.map((item) => item.id)
    searchImages(1, showNumber)
  }

  const onCickSelectAll = () => {
    refSelectAll.current?.click()
  }

  const onToggleSelectAll = () => {
    if (refSelectAll.current?.checked) {
      setSelectedImageIds(images.map((image) => image.id))
    } else {
      setSelectedImageIds([])
    }
  }

  const onDeleteFromImageCatalog = async () => {
    if (selectedImageIds.length === 0) {
      ToastUtil.warning('Please select at least one image')
      return
    }
    setIsDeleteFromImageCatalog(true)
  }

  const onCancelDeleteFromImageCatalog = () => {
    setIsDeleteFromImageCatalog(false)
  }

  const onConfirmDeleteFromImageCatalog = async () => {
    // call api to delete images from image catalog˝
    setIsDeleteFromImageCatalog(false)
    setIsLoadingDeleteFromImageCatalog(true)
    let success = false
    if (refTagIds.current.length) {
      for (const tagId of refTagIds.current) {
        await deleteImagesByTagIds(tagId, selectedImageIds)
      }
      success = true
    } else {
      success = await deleteImages(selectedImageIds)
    }
    setIsLoadingDeleteFromImageCatalog(false)
    if (success) {
      // reload images
      setImages(images.filter((image) => !selectedImageIds.includes(image.id)))
      // reset selected image ids
      setSelectedImageIds([])
      ToastUtil.success('Deleted successfully')
    } else {
      ToastUtil.error('Deletion failed')
    }
  }

  const getActionButtonStyle = (loading: boolean) => {
    const style = StyleUtil.buttonPrimary
    if (loading) {
      return `${style} loading disabled`
    }
    return style
  }

  const getActionButtonText = (loading: boolean, text: string) => {
    return loading ? '' : text
  }

  const onReassignSubjects = () => {
    if (selectedImageIds.length === 0) {
      ToastUtil.warning('Please select at least one image')
      return
    }

    // if only one image is selected, get description of that image and pre-fill to input
    if (selectedImageIds.length === 1) {
      const selectedImage = images.filter(
        (image) => selectedImageIds[0] === image.id,
      )[0]
      if (selectedImage.description !== undefined) {
        setSelectedDescription(selectedImage.description || '')
      }
    } else {
      setSelectedDescription('')
    }

    setIsReassignSubjects(true)
    // hacky crap.
    setReassignModalCategoryHACKY(undefined)
  }

  const onSaveModal = async () => {
    const result = await setImageCategory(
      // @ts-ignore
      reassignModalCategoryHACKY.id, // will ALWAYS be defined, otherwise I suck at my job
      selectedImageIds,
    )
    if (result.success) {
      setImages((prev) => {
        return prev.map((image) => {
          if (selectedImageIds.includes(image.id)) {
            return {
              ...image,
              // @ts-ignore
              categoryId: reassignModalCategoryHACKY.id,
            }
          }
          return image
        })
      })
    } else {
      ToastUtil.error('Re-assign category failed')
      return
    }

    let description: string | undefined | null =
      refInputDescription.current?.value
    const isClear = description === undefined || description === ''

    // const list = [ImageCategory.OtherImages.valueOf()]
    // return (selectedCategory && list.includes(selectedCategory.name)) || false
    if (
      isClear &&
      reassignModalCategoryHACKY?.name === ImageCategory.OtherImages.valueOf()
    ) {
      ToastUtil.warning('Please enter image description')
      return
    }

    if (isClear) {
      description = null
    }

    setIsLoadingReassignSubjects(true)
    try {
      // @ts-ignore
      const success = await reassign(selectedImageIds, {
        tagIds: reassignTagIds,
        subcategoryIds: refSubcategoryIds.current, // reassignSubcategoryIds,
        ...(description !== undefined ? { description } : {}),
      })
      setIsLoadingReassignSubjects(false)
      if (success) {
        reloadAfterSaved()
        ToastUtil.success('Re-assigned subjects successfully')
        setIsReassignSubjects(false)
        // setReassignModalCategoryHACKY(undefined)
      } else {
        ToastUtil.error('Re-assign subjects failed')
      }
    } catch (err) {
      console.error(err)
      ToastUtil.error('Re-assign subjects failed')
    }
  }

  const reloadAfterSaved = () => {
    // reload images
    searchImages(1, showNumber)
    // fetchImages(undefined, undefined)
    // reset selected image ids
    setSelectedImageIds([])
    setReassignTagIds([])
    setSelectedDescription('')
  }

  const onClickReassignTag = (tagId: string) => {
    const target = document.getElementById(`reassign-tag-${tagId}`)
    target?.click()
  }

  const onChangeReassignTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    if (target.dataset.id === undefined) return
    let newTagIds = []
    if (target.checked) {
      newTagIds = [...reassignTagIds, target.dataset.id]
    } else {
      newTagIds = reassignTagIds.filter((id) => id !== target.dataset.id)
    }
    // remove duplicates
    const uniqueTagIds = [...new Set(newTagIds)]
    setReassignTagIds(uniqueTagIds)
  }

  useEffect(() => {
    fetchTags()
    fetchImageCategories()
  }, [fetchTags, fetchImageCategories])

  useEffect(() => {
    if (selectedCategory && tags.length) {
      searchImages(1, showNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, tags])
  return (
    <div className="mt-4 flex flex-col">
      {!isFetched() && <Spinner />}
      {isFetched() && (
        <div className="mt-4 flex flex-col">
          <div className="flex flex-row items-center">
            <div className="flex-grow items-start">
              <SearchTagsInput
                key={selectedCategory?.id}
                onInputChanged={onSearchTextInputChanged}
                onEnter={async () => {
                  await searchImages(1, showNumber || 0)
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                isSearching={isSearching}
              />
            </div>
            <div
              className="flex-shrink-0"
              style={{
                marginLeft: 34,
              }}
            >
              <DropDownMenu
                items={categories}
                onSelected={onSelectedCategory}
                customHACK={true}
              />
            </div>
            <div className="ml-auto w-8 flex-shrink-0"></div>
            <div className="ml-auto flex-shrink-0">
              <DropDownMenu
                items={[
                  { id: '0', name: 'Show 24', value: 24 },
                  { id: '1', name: 'Show 48', value: 48 },
                  { id: '2', name: 'Show 96', value: 96 },
                  { id: '3', name: 'Show all', value: 0 },
                ]}
                onSelected={onSelectShowNumber}
                customHACK={true}
              />
            </div>
          </div>
          <div className="mb-8 mt-5 ">
            {showInputSubjects() && tags.length > 0 && (
              <>
                <ChipList
                  // key={selectedCategory?.id}
                  items={tags}
                  onSelected={onSelectedChipList}
                />
                {/*<div className="m-8" />*/}
              </>
            )}
            {props.mode === 'MANAGE' &&
              !isSearching &&
              showResult &&
              images.length > 0 && (
                <div className="mt-4 flex items-center">
                  <input
                    data-testid="input-select-all"
                    ref={refSelectAll}
                    type="checkbox"
                    onChange={onToggleSelectAll}
                  />
                  <label
                    data-testid="btn-select-all"
                    onClick={onCickSelectAll}
                    className="checkbox-label ml-2 cursor-pointer"
                  >
                    Select all
                  </label>
                  {isDeleteFromImageCatalog && (
                    <>
                      <span className="ml-6 mr-4 text-[16px]">
                        Are you sure?
                      </span>
                      <button
                        data-testid="btn-confirm-delete"
                        onClick={onConfirmDeleteFromImageCatalog}
                        className={getActionButtonStyle(
                          isLoadingDeleteFromImageCatalog,
                        )}
                      >
                        {getActionButtonText(
                          isLoadingDeleteFromImageCatalog,
                          'Yes',
                        )}
                      </button>
                      <span
                        data-testid="btn-cancel-delete"
                        onClick={onCancelDeleteFromImageCatalog}
                        className="ml-4 cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                      >
                        Cancel
                      </span>
                    </>
                  )}
                  {!isDeleteFromImageCatalog &&
                    hasPermission(Permission.DeleteImages) && (
                      <span
                        data-testid="btn-delete-from-catalogue"
                        onClick={onDeleteFromImageCatalog}
                        className="ml-6 cursor-pointer text-[16px] text-primary underline hover:font-bold hover:opacity-[0.8]"
                      >
                        Delete from catalogue
                      </span>
                    )}
                  <span
                    data-testid="btn-reassign-subcategories"
                    onClick={onReassignSubjects}
                    className="ml-4 cursor-pointer text-[16px] font-bold text-[#6B69C1] hover:text-black"
                  >
                    Change category / subject / description
                  </span>
                </div>
              )}
          </div>
          <div>
            {isSearching && <Spinner />}
            {!isSearching && showResult && images.length === 0 && (
              <div className="flex justify-center">No image(s) found.</div>
            )}
            {!isSearching && showResult && images.length > 0 && (
              <>
                <ImageCarousel
                  images={images}
                  selectedId={selectedImageId}
                  hidden={hiddenCarousel}
                  onCopy={onCopy}
                  onClose={() => setHiddenCarousel(true)}
                />
                <ImageGrid
                  images={images}
                  showButtonLeft={showPage > 1}
                  showButtonRight={nextPage > 0}
                  onCopy={onCopy}
                  onClick={(imageId: string) => {
                    setSelectedImageId(imageId)
                    setHiddenCarousel(false)
                  }}
                  onPageLeft={onPageLeft}
                  onPageRight={onPageRight}
                  {...(props.mode === 'MANAGE'
                    ? {
                        onSelect: onSelectImages,
                        selectedImageIds: selectedImageIds,
                        setSelectedImageIds: setSelectedImageIds,
                      }
                    : {})}
                />
              </>
            )}
          </div>
        </div>
      )}

      {isReassignSubjects && (
        <div
          className="absolute left-0 top-0 z-[100] h-full w-full bg-white bg-opacity-[0.7]"
          onClick={(event) => {
            // @ts-ignore
            if (event.target.classList?.contains?.('__closeOnClick')) {
              setIsReassignSubjects(false)
            }
          }}
        >
          <div className="__closeOnClick flex h-full items-center justify-center">
            <form
              method="dialog"
              className="modal-box"
              style={{
                boxShadow: `0px 317px 89px 0px rgba(0, 0, 0, 0.00), 0px 203px 81px 0px rgba(0, 0, 0, 0.01), 0px 114px 68px 0px rgba(0, 0, 0, 0.05), 0px 51px 51px 0px rgba(0, 0, 0, 0.09), 0px 13px 28px 0px rgba(0, 0, 0, 0.10)`,
                // width: 410,
                width: 428,
                overflow: 'auto',
                maxHeight: '100%',
                minHeight: 240,
              }}
            >
              <button
                data-testid="btn-close-reassign"
                onClick={() => {
                  setIsReassignSubjects(false)
                  // setReassignModalCategoryHACKY(undefined)
                }}
                className="btn-ghost btn-sm btn-circle btn absolute right-8 top-8 text-lg"
              >
                ✕
              </button>
              <div
                style={{
                  marginTop: 50,
                }}
              >
                <DropDownMenu
                  items={[
                    {
                      id: '',
                      name: 'Choose the category you’d like to re-assign to',
                      isLabel: true,
                      default: true,
                    },
                    ...categories.map((cat) => {
                      return {
                        id: cat.id,
                        name: cat.name,
                        // selected: selectedCategory?.id === cat.id,
                      }
                    }),
                  ].filter((x) => x.id !== '_ALL_')}
                  onSelected={async (item: DropDownItem) => {
                    setReassignModalCategoryHACKY(
                      categories.find((cat) => cat.id === item.id),
                    )
                  }}
                  customHACK={true}
                />
              </div>

              {reassignModalCategoryHACKY?.name ===
                ImageCategory.BackgroundImages.valueOf() && (
                <>
                  <h3
                    className="ml-2 py-4 text-[16px] font-bold"
                    style={{
                      width: 372,
                      height: 32,
                      margin: '0 auto',
                      marginTop: 26,
                      marginBottom: 24,
                      textAlign: 'center',
                      border: '1px solid #F0F0F0',
                      paddingTop: 4,
                      borderRadius: 6,
                      boxShadow:
                        '0px 0px 1px 0px rgba(48, 49, 51, 0.05), 0px 16px 24px 0px rgba(48, 49, 51, 0.10)',
                    }}
                  >
                    Choose the subject(s) you'd like to assign to
                  </h3>
                  <ul>
                    {tags.map((tag, index) => {
                      return (
                        <li key={index} className="flex items-center p-2">
                          <input
                            data-testid="input-reassign-tag"
                            id={`reassign-tag-${tag.id}`}
                            data-id={tag.id}
                            type="checkbox"
                            onChange={onChangeReassignTag}
                          />
                          <label
                            className="checkbox-label-normal ml-2 cursor-pointer"
                            onClick={() => onClickReassignTag(tag.id)}
                          >
                            {tag.name}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </>
              )}
              {reassignModalCategoryHACKY && (
                <>
                  {reassignModalCategoryHACKY?.name !==
                    ImageCategory.BackgroundImages.valueOf() && (
                    <>
                      <h3 className="ml-2 py-4 text-[16px] font-bold">
                        Add or change image description
                      </h3>
                      <div className="flex justify-center">
                        <div className="relative mx-2 w-full max-w-lg">
                          <label className="w-full">
                            <input
                              data-testid="input-change-description"
                              ref={refInputDescription}
                              type="text"
                              className={`${StyleUtil.inputDescription} pl-[24px]`}
                              // className="pl-4"
                              style={{
                                border: '0.6px solid #32374E',
                                borderRadius: 3,
                                boxShadow: 'none',
                                height: 36,
                                lineHeight: 24,
                                fontSize: 16,
                                fontWeight: 400,
                              }}
                              placeholder="New image description here"
                              defaultValue={selectedDescription}
                              autoFocus={true}
                            />
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      minHeight: 30,
                      overflow: 'hidden',
                      display: 'block',
                    }}
                  ></div>
                  <button
                    data-testid="btn-save-reassign"
                    onClick={onSaveModal}
                    className={`${getActionButtonStyle(
                      isLoadingReassignSubjects,
                    )}`}
                    style={{
                      float: 'right',
                      // position: 'absolute',
                      // bottom: 8,
                      // right: 8,
                    }}
                  >
                    {getActionButtonText(isLoadingReassignSubjects, 'Save')}
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
