import {
  WithAuthenticationRequiredOptions,
  useAuth0,
  withAuthenticationRequired,
} from '@auth0/auth0-react'
import ErrorBoundary from 'pages/ErrorBoundary'
import Spinner from 'views/Spinner'

type ProtectedRouteProps = {
  component: React.ComponentType<object>
  options?: WithAuthenticationRequiredOptions | undefined
}

const ProtectedRoute = ({ component, options }: ProtectedRouteProps) => {
  const { isLoading, error } = useAuth0()
  if (isLoading) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <Spinner />
      </div>
    )
  }
  if (error) {
    console.error(error)
    return <ErrorBoundary />
  }
  const Component = withAuthenticationRequired(component, options)
  return <Component />
}

export default ProtectedRoute
