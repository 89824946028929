import {
  ArchClient,
  ArchCourse,
  ArchModule,
} from 'apis/entities/architecture.entity'

// ArchClient structure: clinet -> program -> intake -> course -> module -> topic

export enum ArchActionType {
  Client = 'client',
  Program = 'program',
  Intake = 'intake',
  Course = 'course',
  Module = 'module',
  Topic = 'topic',
}

export const getIntakeCountByProgramId = (
  client: ArchClient,
  programId: string,
): number => {
  const program = client.programs.find((program) => program.id === programId)
  return program?.intakes.length || 0
}

export const getCourseCountByIntakeId = (
  client: ArchClient,
  intakeId: string,
): number => {
  // find the intake in the client's programs
  const intake = client.programs
    .flatMap((program) => program.intakes)
    .find((intake) => intake.id === intakeId)
  return intake?.courses.length || 0
}

export const getModuleCountByCourseId = (
  client: ArchClient,
  courseId: string,
): number => {
  // find the course in the client's programs
  const course = client.programs
    .flatMap((program) => program.intakes)
    .flatMap((intake) => intake.courses)
    .find((course) => course.id === courseId)
  return course?.modules.length || 0
}

export const getTopicCountByModuleId = (
  client: ArchClient,
  moduleId: string,
): number => {
  // find the module in the client's programs
  const module = client.programs
    .flatMap((program) => program.intakes)
    .flatMap((intake) => intake.courses)
    .flatMap((course) => course.modules)
    .find((module) => module.id === moduleId)
  return module?.topics.length || 0
}

/**
 * sample 1: Are you sure you want to delete these 2 modules, including 6 topics?
 * sample 2: Are you sure you want to delete these 2 modules, including 1 topic?
 * sample 3: Are you sure you want to delete these 4 topics?
 * sample 4: Are you sure you want to delete these 6 topics?
 * sample 5: Are you sure you want to delete this course?
 * sample 6: Are you sure you want to delete this topic?
 * sample 7: Are you sure you want to delete these 2 courses, including 12 modules?
 * sample 8: Are you sure you want to delete this course, including 6 modules?
 * @returns delete confirmation text
 */
// if single parent use `this`, if more than one parent use `these`
// if single child use single form, if more than one child use plural form
export const getDeleteConfirmationMessage = (
  parent: string,
  parentCount: number,
  child: string,
  childCount: number,
): string => {
  if (parentCount === 1) {
    if (childCount === 1) {
      return `Are you sure you want to delete this ${parent}, including ${childCount} ${child}?`
    } else if (child) {
      return `Are you sure you want to delete this ${parent}, including ${childCount} ${child}s?`
    } else {
      return `Are you sure you want to delete this ${parent}?`
    }
  } else if (child) {
    if (childCount === 1) {
      return `Are you sure you want to delete these ${parentCount} ${parent}s, including ${childCount} ${child}?`
    } else {
      return `Are you sure you want to delete these ${parentCount} ${parent}s, including ${childCount} ${child}s?`
    }
  } else {
    return `Are you sure you want to delete these ${parentCount} ${parent}s?`
  }
}

export const getModuleByTopicId = (
  client: ArchClient,
  topicId: string,
): ArchModule | null => {
  let selectedModule: ArchModule | null = null
  client.programs.forEach((program) => {
    program.intakes.forEach((intake) => {
      intake.courses.forEach((course) => {
        course.modules.forEach((module) => {
          module.topics.forEach((topic) => {
            if (topic.id === topicId) {
              selectedModule = module
            }
          })
        })
      })
    })
  })
  return selectedModule
}

export const getCourseByModuleId = (
  client: ArchClient,
  moduleId: string,
): ArchCourse | null => {
  let selectedCourse: ArchCourse | null = null
  client.programs.forEach((program) => {
    program.intakes.forEach((intake) => {
      intake.courses.forEach((course) => {
        course.modules.forEach((module) => {
          if (module.id === moduleId) {
            selectedCourse = course
          }
        })
      })
    })
  })
  return selectedCourse
}

export const getExportFileNameForCourse = (courseName: string): string => {
  // remove special characters and replace with hyphen
  return `${courseName.replace(/[^a-zA-Z0-9]/g, '-')}.xlsx`
}

export const convertIntakeNameToDate = (intakeName: string): Date | null => {
  // possible intake name format: 'Jan 2024', 'Feb-2024', 'March 2024'
  // if intake name is not in the expected format, return null
  let intakeNameParts = intakeName.split(' ').filter((part) => part.trim())
  if (intakeNameParts.length !== 2) {
    intakeNameParts = intakeName.split('-').filter((part) => part.trim())
  }
  // console.log(`intakeNameParts: ${intakeNameParts}, intakeName: ${intakeName}`)
  if (intakeNameParts.length !== 2) {
    return null
  }

  const month = intakeNameParts[0]
  const year = intakeNameParts[1]

  // validate year
  if (isNaN(parseInt(year))) {
    return null
  }

  const monthIndex = new Date(Date.parse(`1 ${month} 2020`)).getMonth()
  if (isNaN(monthIndex)) {
    return null
  }
  const date = new Date(Date.parse(`${year},${monthIndex + 1},1`))
  // console.log(`intakeName: ${intakeName}, date: ${date}`)
  return date
}

export const sortIntakeOptionsByCreatedAt = (intakeOptions: any[]): any[] => {
  // sort intake options by createdAt date descending
  return intakeOptions.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )
}

export const sortIntakeOptionsByDate = (intakeOptions: any[]): any[] => {
  // sort intake options by date first, otherwise by alphabet ascending
  // separate intake options into two arrays: one with date, one without date
  const intakeOptionsWithDate = intakeOptions.filter((intakeOption) =>
    convertIntakeNameToDate(intakeOption.name),
  )

  const intakeOptionsWithoutDate = intakeOptions.filter(
    (intakeOption) => !convertIntakeNameToDate(intakeOption.name),
  )

  // sort intake options with date by date
  const sortedIntakeOptionsWithDate = intakeOptionsWithDate.sort(
    (a, b) =>
      convertIntakeNameToDate(a.name)!.getTime() -
      convertIntakeNameToDate(b.name)!.getTime(),
  )

  // sort intake options without date by alphabet ascending
  const sortedIntakeOptionsWithoutDate = intakeOptionsWithoutDate.sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  // combine two sorted arrays
  // console.log(sortedIntakeOptionsWithDate)
  // console.log(sortedIntakeOptionsWithoutDate)
  return sortedIntakeOptionsWithDate.concat(sortedIntakeOptionsWithoutDate)
}
