import closeSvg from '../images/close2.svg'

interface Props {
  onCreateDraft: () => void
  onCreateWithExcel: () => void
  onCreateWithQuestionGenerator: () => void
  onClose: () => void
}

export default function WidgetCreateQuiz({
  onCreateDraft,
  onCreateWithExcel,
  onCreateWithQuestionGenerator,
  onClose,
}: Props): JSX.Element {
  return (
    <div
      className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex items-center justify-center"
      onClick={
        // click outside the widget to close
        (e) => {
          if (e.target === e.currentTarget) {
            onClose()
          }
        }
      }
    >
      <div className="h-auto flex flex-col bg-white shadow-xl rounded-[15px] gap-[36px] p-[12px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={onClose}
        />
        <div className="flex flex-col items-center gap-8 pb-8 px-16">
          <button
            className="mentem-button-large-secondary w-[270px]"
            onClick={onCreateDraft}
          >
            Create draft
          </button>
          <button
            className="mentem-button-large-secondary w-[270px]"
            onClick={onCreateWithQuestionGenerator}
          >
            Auto generate
          </button>
          <button
            className="mentem-button-large-primary w-[270px]"
            onClick={onCreateWithExcel}
          >
            Import from Excel
          </button>
        </div>
      </div>
    </div>
  )
}
