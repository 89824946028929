import { useEffect } from 'react'
import Path from 'routes/Path'
import Spinner from 'views/Spinner'

function HomePage() {
  useEffect(() => {
    // refdirect to authoring page
    const timer = setTimeout(() => {
      window.location.href = Path.authoring.path
    }, 1000)
    return () => clearTimeout(timer)
  })

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <Spinner />
    </div>
  )
}

export default HomePage
