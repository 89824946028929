import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useApi } from '../providers/ApiProvider'
import SidebarLayout from 'components/SidebarLayout'
import { Permission, useAuth } from 'providers/AuthProvider'
import { StyleUtil } from 'utils/StyleUtil'
import ButttonSpinner from 'views/ButtonSpinner'
import { GeneratedQuestion } from 'apis/entities/quiz.entity'
import { ToastUtil } from 'utils/ToastUtil'
import QuestionEditViewForAuthoring from 'views/QuestionEditViewForAuthoring'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
  storeGeneratedQuestions,
  deleteGeneratedQuestions,
} from 'app/QuestionsSlice'
import {
  DocumentCheckerResponse,
  LanguageToolMatch,
} from 'apis/entities/document.entity'

function AuthoringPage() {
  const {
    publish,
    upload,
    downloadDocument,
    generateQuestions,
    updateDocumentByGeneratedQuestions,
    documentChecker,
  } = useApi()
  const { auth0User: user, hasPermission } = useAuth()
  const [file, setFile] = useState<File>()
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [publishUrl, setPublishUrl] = useState<string | null>(null)
  const [documentId, setDocumentId] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isUploaded, setIsUploaded] = useState<boolean>(false)
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [isGenerated, setIsGenerated] = useState<boolean>(false)
  const [isPublishing, setIsPublishing] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isPublished, setIsPublished] = useState<boolean>(false)
  const [documentError, setDocumentError] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isDryRun: boolean = process.env.REACT_APP_DRY_RUN === 'true'
  const [questions, setQuestions] = useState<GeneratedQuestion[]>([])
  const [showQuestions, setShowQuestions] = useState<boolean>(false)
  const generatedQuestions = useAppSelector(
    (state) => state.questions.generatedQuestions,
  )
  const dispatch = useAppDispatch()

  const [result, setResult] = useState<DocumentCheckerResponse | null>(null)
  const [isChecking, setIsChecking] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const scrollToHighlight = (id: number) => {
    const iframe = document.getElementById('content') as HTMLIFrameElement
    if (iframe) {
      iframe.contentWindow?.postMessage(
        { type: 'scroll-to-highlight', data: `highlighted-${id}` },
        '*',
      )
    }
  }

  const checkDocument = useCallback(async () => {
    if (!documentId) return
    setIsChecking(true)
    try {
      const result = await documentChecker(documentId)
      if (
        result &&
        result.spelling_items &&
        result.grammar_items &&
        (result.spelling_items.length > 0 || result.grammar_items.length > 0)
      ) {
        setResult(result)

        // pass data to iframe
        const iframe = document.getElementById('content') as HTMLIFrameElement
        if (iframe) {
          // join spelling and grammar items
          const items = result.spelling_items.concat(result.grammar_items)
          // const words = items.map((item) => item.incorrect)
          iframe.contentWindow?.postMessage(
            { type: 'document-checker', data: items },
            '*',
          )
        }
      } else {
        ToastUtil.error('No spelling or grammar errors found')
      }
    } catch (e) {
      console.error(e)
      ToastUtil.error('Failed to check document')
    } finally {
      setIsChecking(false)
    }
  }, [documentChecker, documentId])

  const onCheck = async () => {
    try {
      setIsChecking(true)
      setResult(null)
      await checkDocument()
      setIsChecked(true)
    } catch (e) {
      console.error(e)
      ToastUtil.error('Failed to check document')
    } finally {
      setIsChecking(false)
    }
  }

  const handlePublish = async () => {
    if (!documentId || isPublishing || isPublished) return
    try {
      setIsPublishing(true)
      const data = await publish(documentId)
      console.log(data)
      setIsPublishing(false)
      const success = data['success'] as boolean
      if (success === true) {
        setPublishUrl(data['publishUrl'])
        setIsPublished(true)
        ToastUtil.success('Published!')
      } else {
        ToastUtil.error('Failed to publish!')
      }
    } catch (err) {
      console.error(err)
      setIsPublishing(false)
      setIsPublished(false)
    } finally {
      if (isDryRun) {
        setPublishUrl('https://www.mentem.co')
        setIsPublished(true)
      }
    }
  }

  const handleUpload = async () => {
    console.log(file)
    // console.log(user)
    if (!file || isUploading) return
    let formData = new FormData()
    formData.append('file', file)
    formData.append('auth0Id', user?.sub || '')

    let username = ''
    if (user?.given_name && user?.family_name) {
      username = `${user?.given_name} ${user?.family_name}`
    } else {
      username = user?.nickname || user?.name || ''
    }
    formData.append('username', username)

    try {
      // reset iframe
      setPreviewUrl(null)
      setDownloadUrl(null)
      setPublishUrl(null)
      setDocumentId(null)
      setDocumentError(null)
      // reset states
      setIsPublished(false)
      setIsUploading(true)
      setIsGenerating(false)
      setIsGenerated(false)
      setIsChecking(false)
      setIsChecked(false)
      setResult(null)
      setIsSaving(false)
      setQuestions([])
      setShowQuestions(false)
      const data = await upload(formData)
      console.log(data)
      setIsUploading(false)
      setIsUploaded(true)
      setPreviewUrl(data['previewUrl'])
      setDownloadUrl(data['downloadUrl'])
      setDocumentId(data['id'])
    } catch (err) {
      console.error(err)
      setIsUploading(false)
      const message = (err as any).message
      if (message) {
        // document format error
        setDocumentError(message.replaceAll('\n', '<br/>'))
      } else {
        // fallback general error
        setDocumentError(`Failed to upload!<br/>${err}`)
      }
    } finally {
      if (isDryRun) {
        setPreviewUrl('https://www.mentem.co')
        setDownloadUrl('https://www.mentem.co')
        setDocumentId('test_id')
      }
    }
  }

  const renderFileChosen = (): string => {
    if (!file) return 'No file chosen'
    return file.name
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }
    let tmpFile = e.target.files[0] as any
    if (!tmpFile) return
    tmpFile['originalname'] = tmpFile.name
    setFile(tmpFile)
  }

  const copyEmbedCode = () => {
    const title = 'Course'
    const url = getPreviewOrPublishUrl()
    const code = `<iframe width="100%" height="1000" src="${url}" title="${title}" frameborder="0" allow="web-share;fullscreen;"></iframe>`
    navigator.clipboard.writeText(code)
    // Get the snackbar DIV
    let x = document.getElementById('snackbar')
    if (x === null || x === undefined) return
    // Add the "show" class to DIV
    x.className = 'show'
    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      let x = document.getElementById('snackbar')
      if (x === null || x === undefined) return
      x.className = x.className.replace('show', '')
    }, 3000)
  }

  const getPreviewOrPublishUrl = (): string => {
    // publish url override preview url
    const url = publishUrl ? publishUrl : previewUrl
    return url || ''
  }

  const onDownload = async () => {
    if (!documentId) return
    const blob = await downloadDocument(documentId)
    const blobURL = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = blobURL
    link.download = `website.zip`
    link.click()
    URL.revokeObjectURL(blobURL)
  }

  const callUpdateDocumentByGeneratedQuestions = async () => {
    if (!documentId) return
    try {
      setIsSaving(true)
      await updateDocumentByGeneratedQuestions(documentId, generatedQuestions)
      // hide and clear questions
      setIsGenerated(false)
      setShowQuestions(false)
      setQuestions([])
      ToastUtil.success('Questions updated!')
      refreshPreview()
    } catch (err) {
      console.error(err)
    } finally {
      setIsSaving(false)
    }
  }

  const refreshPreview = () => {
    setPreviewUrl(null)
    setTimeout(() => {
      setPreviewUrl(getPreviewOrPublishUrl())
    }, 100)
  }

  const toggleGenerate = async () => {
    if (!documentId) {
      ToastUtil.error('Please upload a document first!')
      return
    }

    // hide and clear questions
    if (isGenerated) {
      setQuestions([])
      dispatch(deleteGeneratedQuestions())
      setShowQuestions(false)
      setIsGenerating(false)
      setIsGenerated(false)
      return
    }

    // generate questions
    try {
      setIsGenerating(true)
      const data: GeneratedQuestion[] = await generateQuestions(documentId, 2)
      // console.log(data)
      if (data.length > 0) {
        setQuestions(data)
        setIsGenerated(true)
        setShowQuestions(true)
        dispatch(storeGeneratedQuestions({ generatedQuestions: data }))
      } else {
        ToastUtil.error('Failed to generate questions!')
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsGenerating(false)
    }
  }

  const renderQuestionsButtonText = (): string => {
    if (isGenerated) return 'Clear questions'
    return 'Generate questions'
  }

  const isLoading = isUploading || isGenerating || isPublishing || isSaving

  const showSaveToTopicButton =
    !isPublished && showQuestions && questions.length > 0

  const getPreviewStyle = (): string => {
    return isChecked ? 'flex-1 h-[100%]' : 'h-[100%] w-full'
  }

  const getPreviewContainerStyle = (): string => {
    return previewUrl ? 'h-[70vh]' : 'h-[100%]'
  }

  const renderCheckerResultList = (
    title: string,
    items: LanguageToolMatch[],
  ) => {
    if (!items || items.length === 0) return <></>
    return (
      <>
        <h2 className="font-bold text-[24px]">{title}</h2>
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              className="m-1 p-2 border-solid border-2 border-sky-500 cursor-pointer"
              onClick={() => scrollToHighlight(item.id)}
            >
              <div className="flex flex-col divide-y divide-dashed">
                <div className="text-[black] flex flex-row">
                  <span className="min-w-[72px]">Text:</span>
                  {/* highlight incorrect text with yellow background */}
                  <span>
                    {item.context.text
                      .split(item.incorrect)
                      .map((part, index) => (
                        <span key={index}>
                          {part}
                          {index === 0 && (
                            <span className="bg-yellow-200">
                              {item.incorrect}
                            </span>
                          )}
                        </span>
                      ))}
                  </span>
                </div>
                <div className="text-[blue] flex flex-row">
                  <span className="min-w-[72px]">Message:</span>
                  <span>{item.message}</span>
                </div>
                <div className="text-[red] flex flex-row">
                  <span className="min-w-[72px]">Incorrect:</span>
                  <span>{item.incorrect}</span>
                </div>
                <div className="text-[green] flex flex-row">
                  <span className="min-w-[72px]">Correct:</span>
                  <span>{item.correct}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </>
    )
  }

  // debug
  useEffect(() => {
    // const id = '9e69a780-c4d6-41e0-bceb-9a0d14305715'
    // const previewUrl = `https://s3.ap-southeast-2.amazonaws.com/dev.test-content.mentem.co/${id}/index.html`
    // console.log('debug', id, previewUrl)
    // setDocumentId(id)
    // setPreviewUrl(previewUrl)
  }, [])

  return (
    <SidebarLayout>
      <div className={StyleUtil.container}>
        {!isUploaded && (
          <p className={StyleUtil.headline}>Choose a Word document to upload</p>
        )}
        <div className="authoring-container">
          <div className="w-[96%]">
            <div className="pb-[8px]">
              <div className="flex flex-row items-center justify-center gap-16 p-[24px]">
                <div className="flex flex-row items-center justify-center gap-12">
                  <label
                    htmlFor="file-input"
                    className={`cursor-pointer font-bold ${
                      isUploaded ? 'text-[#121419]' : 'text-[#696974]'
                    } `}
                  >
                    {isUploaded ? 'Choose new file' : 'Choose file'}
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    data-testid="input-file"
                    className="hidden"
                    ref={inputRef}
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                  <span className={`text-[#121419] ${file && 'font-bold'}`}>
                    {renderFileChosen()}
                  </span>
                </div>
                {file && (
                  <button
                    data-testid="btn-upload"
                    className={`${
                      isUploaded
                        ? StyleUtil.buttonSecondary
                        : StyleUtil.buttonPrimary
                    }`}
                    onClick={handleUpload}
                    disabled={isLoading}
                  >
                    {isUploading ? <ButttonSpinner /> : 'Upload'}
                  </button>
                )}
              </div>
              <div className="mb-[8px] flex flex-row gap-8 items-center justify-center">
                {showSaveToTopicButton && <div className="grow w-32" />}
                {!showSaveToTopicButton && <div className="grow" />}
                {isPublished && (
                  <button
                    data-testid="btn-copy-embed-code"
                    className={StyleUtil.buttonPrimary}
                    onClick={copyEmbedCode}
                  >
                    Copy embed code
                  </button>
                )}
                {previewUrl && (
                  <a
                    href={getPreviewOrPublishUrl()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      data-testid="btn-preview"
                      className={StyleUtil.buttonPrimary}
                    >
                      Preview
                    </button>
                  </a>
                )}
                {isPublished && downloadUrl && (
                  <button
                    data-testid="btn-download"
                    onClick={onDownload}
                    className={StyleUtil.buttonPrimary}
                  >
                    Download
                  </button>
                )}
                {documentId &&
                  hasPermission(Permission.PublishAuthoring) &&
                  !isPublished && (
                    <button
                      data-testid="btn-publish"
                      className={StyleUtil.buttonPrimary}
                      onClick={handlePublish}
                      disabled={isLoading}
                    >
                      {isPublishing && <ButttonSpinner />}
                      {!isPublishing && !isPublished && 'Publish'}
                    </button>
                  )}
                {isPublished && <span>Published!</span>}
                {documentId && (
                  <>
                    {!isPublished && (
                      <button
                        data-testid="btn-generate"
                        className={`${
                          isGenerated
                            ? StyleUtil.buttonSecondary
                            : StyleUtil.buttonPrimary
                        }`}
                        onClick={toggleGenerate}
                        disabled={isLoading}
                      >
                        {isGenerating ? (
                          <ButttonSpinner />
                        ) : (
                          renderQuestionsButtonText()
                        )}
                      </button>
                    )}

                    <button
                      className={StyleUtil.buttonPrimary}
                      disabled={isChecking}
                      onClick={onCheck}
                    >
                      {isChecking ? <ButttonSpinner /> : 'Check'}
                    </button>

                    <div className="grow" />

                    {showSaveToTopicButton && (
                      <button
                        data-testid="btn-save-to-topic"
                        className={`${StyleUtil.buttonPrimary}`}
                        onClick={callUpdateDocumentByGeneratedQuestions}
                        disabled={isLoading}
                      >
                        {isSaving ? <ButttonSpinner /> : 'Save to topic'}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>

            {showQuestions && documentId && questions.length > 0 && (
              <div className="authoring-questions-container">
                <div className="authoring-questions-box mentem-scrollbar-2">
                  {questions.map((_question, index) => (
                    <QuestionEditViewForAuthoring
                      key={index}
                      index={index}
                      documentId={documentId}
                      locked={isPublished}
                      disabled={isLoading}
                    />
                  ))}
                </div>
              </div>
            )}

            {documentError && (
              <div dangerouslySetInnerHTML={{ __html: documentError }}></div>
            )}

            <div
              className={`flex flex-row gap-2 pt-[16px] w-full ${getPreviewContainerStyle()}`}
            >
              {result && (
                <div className="flex-1">
                  <div className="overflow-auto h-[100%]">
                    {renderCheckerResultList('Spelling', result.spelling_items)}
                    {renderCheckerResultList('Grammar', result.grammar_items)}
                  </div>
                </div>
              )}

              {previewUrl && (
                <div className={getPreviewStyle()}>
                  <iframe
                    title="web-content"
                    id="content"
                    data-testid="iframe-content"
                    width={'100%'}
                    height={'100%'}
                    src={previewUrl}
                  />
                </div>
              )}
            </div>

            <div id="snackbar">Copied!</div>
          </div>
        </div>
      </div>
    </SidebarLayout>
  )
}

export default AuthoringPage
