import axios from 'axios'

// TODO: use Provider
export const HandbookAPI = {
  api: axios.create({
    baseURL: 'https://036hjyrjoh.execute-api.ap-southeast-2.amazonaws.com',
  }),
  search: async function (query: String) {
    const response: any = await this.api.get(`/search?q=${query}`)
    return response.data
  },
}
