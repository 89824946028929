import { useApi } from 'providers/ApiProvider'
import { Tag } from 'apis/entities/tag.entity'
import { useCallback, useEffect, useState } from 'react'
import Spinner from './Spinner'
import { StyleUtil } from 'utils/StyleUtil'
import { Permission, useAuth } from 'providers/AuthProvider'
import { ToastUtil } from 'utils/ToastUtil'

export default function ManageImages() {
  const { hasPermission } = useAuth()
  const { getTags, updateTag, deleteTag } = useApi()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)
  const [tags, setTags] = useState<Tag[]>([])
  const [selectedTagId, setSelectedTagId] = useState<string>('')

  const fetchTags = useCallback(async () => {
    setIsLoading(true)
    const data = await getTags()
    setIsLoading(false)
    setTags(data)
  }, [getTags])

  const onSave = async (tagId: string) => {
    const input = document.getElementById(
      `tag-input-${tagId}`,
    ) as HTMLInputElement
    const value = input.value
    if (value) {
      try {
        setIsLoadingSave(true)
        setSelectedTagId(tagId)
        await updateTag(tagId, value)
        ToastUtil.success('Changed successfully')
        setIsLoadingSave(false)
        setSelectedTagId('')
      } catch (error) {
        ToastUtil.error('Change failed')
      }
    }
  }

  const onDelete = async (tagId: string) => {
    // show confirm delete
    const confirmDelete = document.getElementById(`confirm-delete-tag-${tagId}`)
    confirmDelete?.classList.remove('hidden')

    // hide delete button
    const buttonDelete = document.getElementById(`button-delete-tag-${tagId}`)
    buttonDelete?.classList.add('hidden')
  }

  const onCancelDelete = (tagId: string) => {
    // hide confirm delete
    const confirmDelete = document.getElementById(`confirm-delete-tag-${tagId}`)
    confirmDelete?.classList.add('hidden')

    // show delete button
    const buttonDelete = document.getElementById(`button-delete-tag-${tagId}`)
    buttonDelete?.classList.remove('hidden')
  }

  const onConfirmDelete = async (tagId: string) => {
    setIsLoadingDelete(true)
    setSelectedTagId(tagId)
    const result = await deleteTag(tagId)
    setIsLoadingDelete(false)
    setSelectedTagId('')
    if (result) {
      ToastUtil.success('Deleted successfully')
      await fetchTags()
    } else {
      ToastUtil.error('Deletion failed')
    }
  }

  const getActionButtonStylePrimary = (
    loading: boolean,
    tagId: string,
  ): string => {
    const style = StyleUtil.buttonPrimary
    return loading && selectedTagId === tagId ? `${style} loading` : style
  }

  const getActionButtonStyleSecondary = (
    loading: boolean,
    tagId: string,
  ): string => {
    const style = StyleUtil.buttonSecondary
    return loading && selectedTagId === tagId ? `${style} loading` : style
  }

  const getActionButtonText = (
    loading: boolean,
    tagId: string,
    text: string,
  ): string => {
    return loading && selectedTagId === tagId ? '' : text
  }

  useEffect(() => {
    fetchTags()
  }, [fetchTags])

  return (
    <>
      {isLoading && <Spinner />}
      <div className="flex items-center justify-center">
        <ul data-testid="list-tags" className="w-full mt-8">
          {tags &&
            tags.map((tag, index) => {
              return (
                <li
                  data-testid={`li-${tag.name}`}
                  key={tag.id}
                  className="flex flex-row items-center justify-center"
                >
                  <span className="text-black text-right w-[24px]">
                    {index + 1}.
                  </span>
                  <input
                    data-testid="input-tag"
                    className="mentem-input-text pt-[6px] pl-[24px] pb-[6px] pr-[24px] border-[0.6px] border-[#32374E] rounded-[3px] w-1/2 m-2"
                    type="text"
                    id={`tag-input-${tag.id}`}
                    defaultValue={tag.name}
                  />
                  <button
                    data-testid="btn-save-tag"
                    onClick={() => onSave(tag.id)}
                    className={`${getActionButtonStyleSecondary(
                      isLoadingSave,
                      tag.id,
                    )} w-[120px]`}
                  >
                    {getActionButtonText(isLoadingSave, tag.id, 'Rename')}
                  </button>
                  {hasPermission(Permission.DeleteImages) && (
                    <button
                      data-testid="btn-delete-tag"
                      id={`button-delete-tag-${tag.id}`}
                      onClick={() => onDelete(tag.id)}
                      className={`${StyleUtil.buttonPrimary} ml-2 w-[120px]`}
                    >
                      Delete
                    </button>
                  )}
                  <div
                    id={`confirm-delete-tag-${tag.id}`}
                    className="flex flex-row items-center justify-center hidden"
                  >
                    <span className="ml-4 mr-4 text-[16px]">Are you sure?</span>
                    <button
                      data-testid="btn-confirm-delete-tag"
                      onClick={() => onConfirmDelete(tag.id)}
                      className={getActionButtonStylePrimary(
                        isLoadingDelete,
                        tag.id,
                      )}
                    >
                      {getActionButtonText(isLoadingDelete, tag.id, 'Yes')}
                    </button>
                    <span
                      data-testid="btn-cancel-delete-tag"
                      onClick={() => onCancelDelete(tag.id)}
                      className="ml-4 underline text-blue-800 hover:opacity-[0.8] text-[16px] cursor-pointer"
                    >
                      Cancel
                    </span>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    </>
  )
}
