import { toast } from 'react-toastify'

export const ToastUtil = {
  success: (message: string) => {
    // replace speical characters to `-` to avoid duplicate toast
    const toastId = message.replace(/[^a-zA-Z0-9]/g, '-')
    if (toast.isActive(message)) {
      return
    }
    toast.success(message, {
      icon: true,
      toastId: toastId,
    })
  },
  warning: (message: string) => {
    // replace speical characters to `-` to avoid duplicate toast
    const toastId = message.replace(/[^a-zA-Z0-9]/g, '-')
    if (toast.isActive(message)) {
      return
    }
    toast.warning(message, {
      toastId: toastId,
    })
  },
  error: (message: string) => {
    // replace speical characters to `-` to avoid duplicate toast
    const toastId = message.replace(/[^a-zA-Z0-9]/g, '-')
    if (toast.isActive(message)) {
      return
    }
    toast.error(message, {
      toastId: toastId,
    })
  },
}
