/* eslint-disable jsx-a11y/heading-has-content */
import { FileObject } from 'apis/entities/file.entity'
import { getFileSize } from 'utils/FileUtil'

interface ImagePreivewProps {
  fileObject: FileObject
  onDelete: (id: string) => void
  onSelect?: (id: string, checked: boolean) => void
}

export default function ImagePreivew(props: ImagePreivewProps) {
  const objectURL = props.fileObject.objectURL
  const file = props.fileObject.file
  const fileSize = getFileSize(file.size)

  const onDelete = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLButtonElement
    if (target.dataset.id !== undefined) {
      props.onDelete(target.dataset.id)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    // console.log(target.checked, target.dataset.id)
    if (props.onSelect === undefined || target.dataset.id === undefined) return
    props.onSelect(target.dataset.id, target.checked)
  }

  return (
    <li
      id={objectURL}
      className="block w-1/2 p-1 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/4"
    >
      <article
        tabIndex={0}
        className="imagePreview focus:shadow-outline relative w-full cursor-pointer rounded-md bg-gray-100 text-black shadow-sm focus:outline-none"
      >
        <img
          alt={file.name}
          className="sticky aspect-[4/3] h-full w-full rounded-md bg-fixed object-cover"
          src={objectURL}
        />
        <section className="absolute top-0 z-20 flex h-full w-full flex-col break-words rounded-md px-1 py-1 text-xs">
          <div className="flex">
            <p className="size rounded-sm bg-white p-1 text-xs">{fileSize}</p>
            <div className="ml-auto flex items-center">
              {props.onSelect && (
                <div className="ml-auto mr-1 flex rounded-[3px] bg-white p-1">
                  <input
                    data-testid="checkbox"
                    type="checkbox"
                    data-id={objectURL}
                    onChange={onChange}
                    defaultChecked
                  />
                </div>
              )}
              <button
                type="button"
                title="btn-delete"
                className="delete ml-auto rounded-md p-1 hover:bg-gray-300 focus:outline-none"
                data-id={objectURL}
                onClick={onDelete}
              >
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="pointer-events-none"
                >
                  <path
                    d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
        </section>
      </article>
    </li>
  )
}
