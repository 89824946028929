import { Image } from 'apis/entities/image.entity'
import ImageThumbnail from './ImageThumbnail'
import { useRef, useState } from 'react'

interface ImageGridProps {
  images: Image[]
  showButtonLeft: boolean
  showButtonRight: boolean
  onCopy: (url: string) => void
  onClick?: (imageId: string) => void
  onSelect?: (imageId: string, checked: boolean) => void
  onPageLeft?: () => void
  onPageRight?: () => void
  selectedImageIds?: string[]
  setSelectedImageIds?: (imageIds: string[]) => void
}

export default function ImageGrid(props: ImageGridProps) {
  const refBtnLeft = useRef<HTMLButtonElement | null>(null)
  const refBtnRight = useRef<HTMLButtonElement | null>(null)
  const buttonStyle =
    'btn bg-transparent border-transparent hover:bg-transparent hover:border-transparent'
  // save UX for all roles
  const showFileSize = true
  const stateImageIds = useState<string[]>([])
  const selectedImageIds = props.selectedImageIds || stateImageIds[0]
  const setSelectedImageIds = props.setSelectedImageIds || stateImageIds[1]
  const onCopy = (url: string) => {
    props.onCopy(url)
  }

  const onClickLeft = () => {
    props.onPageLeft && props.onPageLeft()
  }

  const onClickRight = () => {
    props.onPageRight && props.onPageRight()
  }

  const arrowStyleLeft = () => {
    return `${buttonStyle} ${props.showButtonLeft ? '' : 'invisible'}`
  }

  const arrowStyleRight = () => {
    return `${buttonStyle} ${props.showButtonRight ? '' : 'invisible'}`
  }

  return (
    <>
      <div
        id="image-grid"
        data-testid="image-grid"
        className="grid grid-cols-4 gap-4 pb-4 pt-0"
      >
        {props.images.map((image, index) => {
          return (
            <ImageThumbnail
              index={index}
              key={image.id}
              checked={selectedImageIds.includes(image.id)}
              onCopy={onCopy}
              onClick={props.onClick && ((imageId) => props.onClick?.(imageId))}
              onSelect={
                props.onSelect &&
                ((imageId, checked) => {
                  props.onSelect?.(imageId, checked)
                  if (checked) {
                    setSelectedImageIds([...selectedImageIds, imageId])
                  } else {
                    setSelectedImageIds(
                      selectedImageIds.filter((v) => v !== imageId),
                    )
                  }
                })
              }
              image={image}
              showFileSize={showFileSize}
            />
          )
        })}
      </div>
      <div className="mx-0 mb-10 flex justify-between">
        <button
          data-testid="btn-left"
          ref={refBtnLeft}
          style={{ padding: 0 }}
          onClick={onClickLeft}
          className={arrowStyleLeft()}
        >
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.731603 11.1741C0.201455 10.6546 0.202363 9.80452 0.721073 9.28616V9.27285L8.71522 1.2842C9.23484 0.751621 10.0742 0.751621 10.6072 1.2842C11.1268 1.80346 11.1268 2.64227 10.6072 3.17484L4.878 8.90007H20.334C21.0668 8.90007 21.6664 9.4859 21.6664 10.2315C21.6664 10.9638 21.0668 11.563 20.334 11.563H4.90464L10.6189 17.2733C11.1386 17.7793 11.1386 18.6314 10.6189 19.1507H10.6056C10.3525 19.3903 10.0061 19.5368 9.65965 19.5368L9.6743 19.5514C9.31457 19.5514 8.96815 19.405 8.72833 19.1653L0.734184 11.1767C0.733322 11.1758 0.732462 11.1749 0.731603 11.1741Z"
              fill="#51597B"
            />
          </svg>
        </button>
        <button
          data-testid="btn-right"
          ref={refBtnRight}
          style={{ padding: 0 }}
          onClick={onClickRight}
          className={arrowStyleRight()}
        >
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.107 8.89251L11.3868 3.17657C10.8535 2.64363 10.8535 1.80425 11.3855 1.28464C11.9055 0.751698 12.7455 0.751698 13.2788 1.2833L21.2788 9.27739C21.6134 9.60315 21.7327 10.0723 21.6367 10.5039C21.5682 10.8221 21.385 11.099 21.1329 11.289L13.2657 19.1504C13.0124 19.3902 12.6657 19.5368 12.3191 19.5368L12.3324 19.5514C11.9724 19.5514 11.6257 19.4049 11.3857 19.165C10.8524 18.6454 10.8524 17.7927 11.3724 17.2731V17.2598L17.0792 11.5572H1.66634C0.919674 11.5572 0.333008 10.9577 0.333008 10.2249C0.333008 9.47874 0.919674 8.89251 1.66634 8.89251H17.107Z"
              fill="#51597B"
            />
          </svg>
        </button>
      </div>
    </>
  )
}
