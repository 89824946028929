import { StyleUtil } from './StyleUtil'

function getAlternatingStyle() {
  let isHeader1 = true
  let isCell1 = true

  return (what: string, startRow = false) => {
    if (what === 'td') {
      if (startRow) isCell1 = true
      else isCell1 = !isCell1
    } else {
      if (startRow) isHeader1 = true
      else isHeader1 = !isHeader1
    }
    if (what === 'td') {
      return isCell1 ? 'mentem-table-cell-2' : 'mentem-table-cell-1'
    }
    return isHeader1
      ? StyleUtil.stickyTableHeader1
      : StyleUtil.stickyTableHeader2
  }
}

export const DirectionAsc: Direction = 'ascending'
export const DirectionDesc: Direction = 'descending'
export type Direction = 'ascending' | 'descending' | ''

export const tooltipStyle = {
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '6px',
}

export const tooltipStyleReverse = {
  backgroundColor: '#6b69c1',
  color: '#fff',
  borderRadius: '6px',
}

export const alternateStyle = getAlternatingStyle()
