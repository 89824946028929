import dragAndDropSvg from 'images/drag-and-drop.svg'
import { FileObject } from 'apis/entities/file.entity'
import { useApi } from 'providers/ApiProvider'
import { useEffect, useRef, useState } from 'react'
import { ToastUtil } from 'utils/ToastUtil'
import { getExtensionIcon, isImage } from 'utils/FileUtil'

type Props = {
  originalFile?: string
  originalFileName?: string
  onAdded: (url: string, type: string, name: string, size: number) => void
  disabled?: boolean
}

export default function UploadMedia({
  originalFile,
  originalFileName,
  onAdded,
  disabled,
}: Props) {
  const refInput = useRef<HTMLInputElement | null>(null)
  const [file, setFile] = useState<FileObject>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { uploadFile } = useApi()
  const [preview, setPreview] = useState<string | undefined>()
  const [mediaFile, setMediaFile] = useState<string | undefined | null>(
    originalFile,
  )
  const [fileName, setFileName] = useState<string | undefined | null>(
    originalFileName,
  )
  const [seconds, setSeconds] = useState(0)

  const hasFiles = (e: React.DragEvent<HTMLDivElement>) => {
    const types = e.dataTransfer.types
    return types.indexOf('Files') > -1
  }

  const addFile = (f: FileObject) => {
    setFile(f)
  }

  const triggerUploadFile = async (newFile: File) => {
    try {
      const file = { objectURL: URL.createObjectURL(newFile), file: newFile }
      addFile(file)

      // set preview if file is image
      if (newFile.type.startsWith('image') && file) {
        // console.log(file.objectURL)
        setPreview(file.objectURL)
      } else {
        setPreview(undefined)
      }

      // upload file
      setIsLoading(true)
      const fileUrl = await uploadFile(newFile)
      if (fileUrl) {
        setMediaFile(fileUrl)
        setFileName(newFile.name)
        onAdded(fileUrl, newFile.type, newFile.name, newFile.size)
      } else {
        ToastUtil.error('Upload failed. Please try again.')
      }
    } catch (error) {
      console.error(error)
      ToastUtil.error('Upload failed. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return
    // has file already, no more upload is allowed
    if (mediaFile) return

    e.preventDefault()
    // console.log(e.dataTransfer.files)
    // upload file
    try {
      const newFiles: File[] = []
      for (const file of e.dataTransfer.files) {
        newFiles.push(file)
      }
      // pick first file only
      const newFile = newFiles[0]
      await triggerUploadFile(newFile)
    } catch (error) {
      console.error(error)
    }
  }

  const onDragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!hasFiles(e)) {
      return
    }
  }

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // console.log('onDragLeaveHandler')
  }

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    if (hasFiles(e)) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const addFiles = (newFiles: File[]) => {
    if (newFiles.length === 0) return
    // pick first file only
    const newFile = newFiles[0]
    if (newFile) {
      triggerUploadFile(newFile)
    }
  }

  const onInputFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || []
    // console.log(files)
    const newFiles: File[] = []
    for (const file of files) {
      newFiles.push(file)
    }
    addFiles(newFiles)
    // reset selected files
    refInput.current!.value = ''
  }

  const onSelect = () => {
    if (disabled) return
    // prompt user to select file
    refInput.current?.click()
  }

  const renderMedia = () => {
    if (mediaFile) {
      // switch case for images, videos and others
      const type = mediaFile.split('.').pop()
      // check if it is image
      if (type && isImage(type)) {
        return (
          <a
            href={mediaFile}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row justify-center items-center"
          >
            <img src={mediaFile} alt="media" style={{ maxHeight: '30vh' }} />
          </a>
        )
      } else if (type === 'mp4') {
        return (
          <div className="flex flex-row justify-center items-center">
            <video width="320" height="240" controls>
              <source src={mediaFile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      } else {
        // other types, display link
        return (
          <a href={mediaFile} target="_blank" rel="noreferrer">
            <div className="flex flex-row gap-2 items-center justify-center">
              <img src={getExtensionIcon(mediaFile)} alt={mediaFile} />
              {fileName}
            </div>
          </a>
        )
      }
    }
  }

  const renderLoadingText = () => {
    // console.log('seconds', seconds)
    const mod = (seconds % 3) + 1
    return `File is loading${'.'.repeat(mod)}` // repeat . based on seconds
  }

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isLoading) {
      timer = setTimeout(() => {
        setSeconds((prevSeconds) => prevSeconds + 1)
      }, 1000) // 1000 milliseconds = 1 second
    } else {
      setSeconds(0)
    }
    // Cleanup function to clear the timer when the component unmounts or when the dependency array changes
    return () => clearTimeout(timer)
  }, [isLoading, seconds]) // Re-run effect when the value of seconds changes

  useEffect(() => {
    if (!originalFile) {
      setMediaFile(undefined)
      setPreview(undefined)
      setFileName(undefined)
    }
  }, [originalFile])

  return (
    <div className="flex flex-col gap-2">
      {mediaFile && (
        <div className="flex flex-row items-center justify-center">
          {renderMedia()}
        </div>
      )}
      {!mediaFile && (
        <>
          <input
            data-testid="hidden-input"
            id="hidden-input"
            title="hidden-input"
            type="file"
            multiple={false}
            className="hidden"
            ref={refInput}
            onChange={onInputFiles}
          />
          <div
            className={`add-media-container cursor-pointer ${
              isLoading ? 'add-media-container-loading' : ''
            }`}
            onDrop={onDropHandler}
            onDragOver={onDragOverHandler}
            onDragLeave={onDragLeaveHandler}
            onDragEnter={onDragEnterHandler}
            onClick={onSelect}
          >
            {!file && !isLoading && (
              <img src={dragAndDropSvg} alt="drag-and-drop" />
            )}
            {isLoading && preview && (
              <img className="add-media-preview" src={preview} alt="preview" />
            )}
            {isLoading && (
              <p className="add-media-loading">{renderLoadingText()}</p>
            )}
            {!isLoading && <p className="add-media-drop">Drop file here</p>}
          </div>
        </>
      )}
    </div>
  )
}
