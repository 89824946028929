/**
 * StyleUtil className
 */
export const StyleUtil = {
  container: 'w-full px-12 py-[calc(8vh+40px)]',
  containerNoScroll: 'w-full h-full px-12 py-[calc(8vh+40px)] overflow-hidden',
  headline: 'mentem-headline',
  headlineSmall: 'mentem-headline-small',
  buttonPrimary:
    'mentem-button w-fit text-white bg-primary border-primary btn-sm btn rounded-3xl hover:text-[#3C415D] hover:bg-white hover:border-[#D5D5DB] shadow-lg normal-case',
  buttonSecondary:
    'mentem-button w-fit text-[#3C415D] bg-white border-[#D5D5DB] btn-sm btn rounded-3xl hover:text-white hover:bg-primary hover:border-primary shadow-lg normal-case',
  buttonTertiary:
    'mentem-button w-fit text-white bg-red-500 border-red-500 btn-sm btn rounded-3xl hover:text-[#3C415D] hover:bg-white hover:border-[#D5D5DB] shadow-lg normal-case',
  buttonPrimarySection:
    'mentem-button w-fit text-white bg-primary border-primary btn-sm btn rounded-3xl hover:text-white hover:bg-primary hover:border-primary shadow-lg normal-case',
  buttonSecondarySection:
    'mentem-button w-fit text-[#3C415D] bg-white border-[#D5D5DB] btn-sm btn rounded-3xl hover:text-white hover:bg-primary hover:border-primary shadow-lg normal-case',
  inputSearch:
    'text-sm font-bold w-full py-2 px-2 pl-4 rounded-3xl border border-solid border-neutral-300 bg-transparent bg-clip-padding text-black outline-none shadow-lg',
  inputDescription:
    'text-sm font-bold w-full py-2 px-2 pl-4 rounded-sm border border-solid border-neutral-300 bg-transparent bg-clip-padding text-black outline-none shadow-lg',
  inputSearchWithIcon:
    'text-sm font-bold w-full py-2 px-2 pl-10 rounded-3xl border border-solid border-neutral-300 bg-transparent bg-clip-padding text-black outline-none shadow-lg',
  dropDownSearch:
    'w-full max-w-md absolute z-50 mt-9 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
  stickyTableHeader1: 'sticky top-0 z-1 mentem-table-header-1',
  stickyTableHeader2: 'sticky top-0 z-1 mentem-table-header-2',
}
