import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Quiz, QuizQuestion } from 'apis/entities/quiz.entity'
import { isPrd } from 'utils/EnvUtil'

// Define the type for the quiz state for quiz details page
interface QuizState {
  value: Quiz
}

// Define initial state
const initialState: QuizState = {
  value: {} as Quiz,
}

// Create a slice for managing quiz state
export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    storeQuiz(state, action: PayloadAction<{ quiz: Quiz }>) {
      // console.log('storeQuiz', action.payload)
      const { quiz } = action.payload
      if (!isPrd) {
        console.log('storeQuiz', quiz)
      }
      state.value = quiz
    },
    updateNameAndDescription(
      state,
      action: PayloadAction<{ name: string; description: string }>,
    ) {
      const { name, description } = action.payload
      // console.log('updateNameAndDescription', name, description)
      state.value.name = name
      state.value.description = description
    },
    updatePositions(state, action: PayloadAction<{ positions: string[] }>) {
      const { positions } = action.payload
      // console.log('updatePositions', positions)
      state.value.positions = positions
    },
    updateQuestions(
      state,
      action: PayloadAction<{ questions: QuizQuestion[] }>,
    ) {
      const { questions } = action.payload
      // console.log('updateQuestions', questions)
      state.value.questions = questions
    },
    addQuestion(state, action: PayloadAction<{ question: QuizQuestion }>) {
      const { question } = action.payload
      // console.log('addQuestion', question)
      state.value.questions.push(question)
    },
    updateQuestion(state, action: PayloadAction<{ question: QuizQuestion }>) {
      const { question } = action.payload
      // console.log('updateQuestion', question)
      const index = state.value.questions.findIndex((q) => q.id === question.id)
      state.value.questions[index] = question
    },
    deleteQuestion(state, action: PayloadAction<{ questionId: string }>) {
      const { questionId } = action.payload
      // console.log('deleteQuestion', questionId)
      state.value.questions = state.value.questions.filter(
        (q) => q.id !== questionId,
      )
    },
  },
})

// Extract action creators from the slice
export const {
  storeQuiz,
  updateNameAndDescription,
  updatePositions,
  updateQuestions,
  addQuestion,
  updateQuestion,
  deleteQuestion,
} = quizSlice.actions

export const currentQuiz = (state: { value: QuizState }) => state.value

// Export the reducer
export default quizSlice.reducer
