import { useRef } from 'react'

export type MultipleSelectItem = {
  id: string
  name: string
  selected: boolean
}

type Props = {
  items: MultipleSelectItem[]
  onSelected: (ids: string[]) => void
  multiple?: boolean // default true
}

export default function MultipleSelect({
  items,
  onSelected,
  multiple = true,
}: Props) {
  const refSelectedIds = useRef<string[]>(
    items.filter((item) => item.selected).map((item) => item.id),
  )

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('onChangeCheckbox')
    const target = e.target as HTMLInputElement
    const targetId = target.dataset.id
    if (targetId === undefined) return

    // deselect all
    // console.log('multiple', multiple)
    if (!multiple) {
      refSelectedIds.current = []
      const inputs = document.querySelectorAll(
        'input[type=checkbox]',
      ) as NodeListOf<HTMLInputElement>
      inputs.forEach((input) => {
        if (input.dataset.id !== targetId) {
          input.checked = false
        }
      })
    }

    if (target.checked) {
      refSelectedIds.current.push(targetId)
    } else {
      refSelectedIds.current = refSelectedIds.current.filter(
        (id) => id !== targetId,
      )
    }
    onSelected(refSelectedIds.current)
  }

  const onClickLabel = (e: React.MouseEvent<HTMLElement>) => {
    try {
      const target = e.target as HTMLElement
      const input = target.parentElement?.children[0] as HTMLInputElement
      // toggle checkbox
      input.click()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className="flex flex-wrap">
      {items.map((item) => {
        return (
          <div
            key={item.id + Math.random()}
            className="mr-6 mb-2 flex items-center"
          >
            <input
              type="checkbox"
              data-id={item.id}
              onChange={onChangeCheckbox}
              defaultChecked={item.selected}
            />
            <div className="w-[8px] cursor-pointer" onClick={onClickLabel}>
              &nbsp;
            </div>
            <label
              className="checkbox-label cursor-pointer"
              onClick={onClickLabel}
            >
              {item.name}
            </label>
          </div>
        )
      })}
    </div>
  )
}
