import SidebarLayout from 'components/SidebarLayout'
import WidgetUploadQuiz from 'views/WidgetUploadQuiz'
import { useEffect, useState } from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { Role, useAuth } from 'providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import Path from 'routes/Path'
import ManageQuizzes from 'views/ManageQuizzes'
import WidgetCreateQuiz from 'views/WidgetCreateQuiz'
import { isDev } from 'utils/EnvUtil'
import WidgetQuestionGenerator from 'views/WidgetQuestionGenerator'
import WidgetQuestionGeneratorOld from 'views/WidgetQuestionGeneratorOld'

function QuizReviewPage() {
  const navigate = useNavigate()
  const { getRole } = useAuth()
  const [showUploadWidget, setShowUploadWidget] = useState<boolean>(false)
  const [showCreateWidget, setShowCreateWidget] = useState<boolean>(false)
  const [showQuestionGeneratorWidget, setShowQuestionGeneratorWidget] =
    useState<boolean>(false)

  useEffect(() => {
    if (getRole() === Role.Designer) {
      // redirect to home
      navigate(Path.root.path)
      return
    }
  }, [getRole, navigate])

  return (
    <>
      <SidebarLayout>
        <div className={StyleUtil.container}>
          <p className={StyleUtil.headline}>Mentem quiz tool</p>
          <div className="flex flex-col items-center justify-center mt-8">
            <ManageQuizzes
              setShowCreateWidget={(show: boolean) => {
                setShowCreateWidget(show)
              }}
            />
          </div>
        </div>
      </SidebarLayout>
      {showCreateWidget && (
        <WidgetCreateQuiz
          onCreateDraft={() => {
            navigate(Path.quizzes.path + '/create')
          }}
          onCreateWithExcel={() => {
            setShowUploadWidget(true)
          }}
          onCreateWithQuestionGenerator={() => {
            setShowQuestionGeneratorWidget(true)
          }}
          onClose={() => {
            setShowCreateWidget(false)
          }}
        />
      )}
      {showUploadWidget && (
        <WidgetUploadQuiz
          placeholder="Drag spreadsheet here to upload"
          onClose={(_refresh) => {
            setShowUploadWidget(false)
          }}
          onRedirect={(path) => {
            navigate(path)
          }}
        />
      )}
      {showQuestionGeneratorWidget &&
        (isDev ? (
          <WidgetQuestionGenerator
            multiple={true}
            onGenerated={() => {
              setShowCreateWidget(false)
              setShowQuestionGeneratorWidget(false)
            }}
            onClose={() => {
              setShowQuestionGeneratorWidget(false)
            }}
          />
        ) : (
          <WidgetQuestionGeneratorOld
            multiple={true}
            onGenerated={() => {
              setShowCreateWidget(false)
              setShowQuestionGeneratorWidget(false)
            }}
            onClose={() => {
              setShowQuestionGeneratorWidget(false)
            }}
          />
        ))}
    </>
  )
}

export default QuizReviewPage
