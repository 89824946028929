import { Image } from 'apis/entities/image.entity'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { useEffect, useState } from 'react'
import arrowLeft from 'images/arrow-left.svg'
import arrowRight from 'images/arrow-right.svg'
import buttonClose from 'images/close.svg'
import { ButtonCopy } from 'components/ButtonCopy'

interface ImageCarouselProps {
  images: Image[]
  selectedId: string
  hidden: boolean
  onCopy: (url: string) => void
  onClose?: () => void
}

export default function ImageCarousel(props: ImageCarouselProps) {
  const [selectedImage, setSelectedImage] = useState<Image>(
    props.images.find((x) => x.id === props.selectedId) || props.images[0],
  )

  const onChange = (index: number, item: any) => {
    const image = props.images[index]
    setSelectedImage(image)
  }
  useEffect(() => {
    setSelectedImage(
      props.images.find((x) => x.id === props.selectedId) || props.images[0],
    )
  }, [props.selectedId, props.images])

  if (props.hidden) return null

  return (
    <>
      <div
        className="bg-white bg-opacity-70"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 999,
          // pointerEvents: 'none', // Prevent clicks on the overlay
        }}
        onClick={(event) => {
          props.onClose?.()
        }}
      ></div>
      <div
        data-testid="image-carousel"
        className="image-carousel bg-white bg-opacity-70"
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            position: 'fixed',
            zIndex: 1000,
          }}
        >
          <div
            className="relative bg-white px-[24px] pt-[62px]"
            style={{
              zIndex: 1001,
              // pointerEvents: 'auto', // Restrict clicks to be inside the modal only
              width: 936,
              borderRadius: 6,
              boxShadow: `0px 627px 176px 0px rgba(0, 0, 0, 0.00), 
            0px 401px 161px 0px rgba(0, 0, 0, 0.01), 
            0px 226px 135px 0px rgba(0, 0, 0, 0.05), 
            0px 100px 100px 0px rgba(0, 0, 0, 0.09), 
            0px 25px 55px 0px rgba(0, 0, 0, 0.10)`,
              overflow: 'hidden',
              // height: 424,
              // transform: 'translateX(410px)', // Adjust this value to account for the left offset
            }}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              event.stopPropagation()
            } // Also prevent clicks on this div from propagating
          >
            <style>
              {`
                .image-carousel button.carousel-control-next {
                  margin-right: -0px;
                }
                .image-carousel .carousel-root {
                  margin-left: 0px;
                  margin-right: 0px;
                }
                .image-carousel .slider-wrapper {
                  padding-left: 42px;
                  padding-right: 42px;
                }
              `}
            </style>
            <Carousel
              // get index instead of id
              selectedItem={props.images.findIndex(
                (x) => x.id === props.selectedId,
              )}
              showThumbs={false}
              showArrows={true}
              dynamicHeight={true}
              infiniteLoop={true}
              width={'100%'}
              statusFormatter={(current, total) => `${current}/${total}`}
              renderArrowPrev={(onClickHandler, hasPrev, label) => {
                return (
                  <button
                    data-testid="btn-prev"
                    onClick={onClickHandler}
                    type="button"
                    className="control-arrow carousel-control-prev"
                  >
                    <img src={arrowLeft} className="mb-10" alt="" />
                  </button>
                )
              }}
              renderArrowNext={(onClickHandler, hasNext, label) => {
                return (
                  <button
                    data-testid="btn-next"
                    onClick={onClickHandler}
                    type="button"
                    className="control-arrow carousel-control-next"
                  >
                    <img src={arrowRight} className="mb-10" alt="" />
                  </button>
                )
              }}
              onChange={onChange}
            >
              {props.images.map((image, index) => {
                return (
                  <div key={index} className="bg-gray-300">
                    <img
                      alt=""
                      src={image.url}
                      className="h-[60vh] object-scale-down"
                    />
                  </div>
                )
              })}
            </Carousel>
            <div className="mb-[24px] mt-[24px] flex justify-center">
              <div className="flex items-center">
                <a
                  href={selectedImage.url}
                  className="text-blue-700 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedImage.url}
                </a>
                <ButtonCopy url={selectedImage.url} onClick={props.onCopy} />
              </div>
            </div>
            <div
              data-testid="btn-close"
              onClick={props.onClose}
              className="absolute top-0 w-fit -translate-x-1/2 transform cursor-pointer rounded-full bg-white p-2 opacity-[0.9] hover:bg-gray-100"
              style={{
                right: 0,
                marginTop: 12,
                // left: '50%',
                // transform: 'translateX(50%)',
              }}
            >
              <img src={buttonClose} alt="close" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
