import { useCallback, useEffect, useRef, useState } from 'react'
import { ToastUtil } from 'utils/ToastUtil'
import Emitter, { Events } from 'core/emitter'
import Spinner from './Spinner'
import { useApi } from 'providers/ApiProvider'
import { QuizQuestion } from 'apis/entities/quiz.entity'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'

interface Props {
  quizId: string
  question: QuizQuestion
  position: { x: number; y: number }
  onClose: () => void
}

export default function WidgetQuestionEditTopicLink({
  quizId,
  question,
  position,
  onClose,
}: Props): JSX.Element {
  const { updateQuizQuestion } = useApi()
  const { crawlDocumentUrl } = useArchitectureApi()
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState(question.document.topic)

  const [x, setX] = useState(position.x)
  const [y, setY] = useState(position.y)

  const refUrl = useRef<string>(question.document.url)
  const refDocId = useRef<string>(question.document.id)

  const validateUrl = useCallback((): boolean => {
    // test1 https://content.mentem.co/lx/9c2d0ac9-b21e-4d3b-bc67-14ecc3aa7d09/index.html
    // test2 https://test-content.mentem.co/lx/b9648f32-b5e6-42a6-90aa-19dee604f9fa/index.html
    // extract document id
    try {
      if (refUrl.current && refUrl.current.trim() !== '') {
        const url = new URL(refUrl.current)
        const path = url.pathname.split('/')
        const docId = path[path.length - 2]
        refDocId.current = docId
        return true
      } else {
        return false
      }
    } catch (e) {
      console.error(e)
      return false
    }
  }, [])

  const onChangeTopicLink = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      refUrl.current = e.target.value
      if (validateUrl()) {
        try {
          const data = await crawlDocumentUrl(refUrl.current)
          console.log('crawlDocumentUrl', data)
          if (!data) {
            throw new Error('Invalid URL')
          }
          if (data && data.title) {
            setTitle(data.title)
            ToastUtil.success('Valid URL')
          }
        } catch (e) {
          console.error(e)
          ToastUtil.warning('Invalid URL')
        }
      }
    },
    [crawlDocumentUrl, validateUrl],
  )

  const saveQuestion = useCallback(async () => {
    try {
      setLoading(true)
      // validate URL
      if (!validateUrl()) {
        ToastUtil.warning('Invalid URL')
        return
      }

      // check if document id is changed
      if (refDocId.current === question.document.id) {
        ToastUtil.warning('No changes detected')
        return
      }

      const data = await crawlDocumentUrl(refUrl.current)
      if (!data) {
        throw new Error('Invalid URL')
      }
      console.log('crawlDocumentUrl', data)
      // replace the topic to title
      const newDocument = {
        ...question.document,
        topic: data.title,
        url: refUrl.current,
      }

      // only update document id in question
      const newQuestion = {
        documentId: refDocId.current,
      } as any
      const result = await updateQuizQuestion(quizId, question.id, newQuestion)
      console.log('updateQuizQuestion', result)

      ToastUtil.success('Question updated')
      Emitter.emit(Events.UpdatedQuizQuestionTopicLink, {
        questionId: question.id,
        document: newDocument,
      })
      // close the widget
      onClose()
    } catch (e) {
      console.error(e)
      ToastUtil.error('Failed to update question')
    } finally {
      setLoading(false)
    }
  }, [
    crawlDocumentUrl,
    onClose,
    question,
    quizId,
    updateQuizQuestion,
    validateUrl,
  ])

  useEffect(() => {
    setX(position.x + 20)
    setY(position.y - 80)
  }, [position])

  return (
    <div
      className="w-full h-full absolute z-[999]"
      onClick={
        // click outside the widget to close
        (e) => {
          if (e.target === e.currentTarget) {
            onClose()
          }
        }
      }
    >
      {/* set the position */}
      <div
        className={`absolute w-[40%] h-fit`}
        style={{ top: `${y}px`, left: `${x}px` }}
      >
        <div className="h-full w-full flex flex-col bg-white shadow-xl rounded-[12px] border-[1px] border-[#D5D5DB] p-[12px]">
          <div className="flex flex-col items-center p-[12px]">
            {question && (
              <div className="flex flex-col items-center gap-[12px] w-full">
                <span className="w-full p-[8px]">{title}</span>
                {/* Topic link */}
                <input
                  type="text"
                  className="arch-add-input w-full border-[1px] border-[#6B69C1] p-[8px]"
                  placeholder="Copy topic URL here"
                  defaultValue={question.document.url}
                  onChange={onChangeTopicLink}
                  autoFocus
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      saveQuestion()
                    }
                  }}
                />
              </div>
            )}
          </div>
          {loading && <Spinner />}
        </div>
      </div>
    </div>
  )
}
