/* eslint-disable */
import SidebarLayout from 'components/SidebarLayout'
import { useApi } from 'providers/ApiProvider'
import { useAuth } from 'providers/AuthProvider'
import { useState, useRef, useEffect, ChangeEvent } from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import Spinner from 'views/Spinner'
import React from 'react'
import Papa from 'papaparse'
import DropDownMenu from '../views/DropDownMenu'
import excelSvg from 'images/excel.svg'

function UploadGrades() {
  const { isLogged, getRole, permissions, auth0User: user } = useAuth()
  const {
    getCanvasClients,
    uploadGrades,
    getCanvasCourses,
    getCanvasAssignments,
    getCanvasMarkingSheet,
  } = useApi()
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [canvasClients, setCanvasClients] = useState<any>([
    {
      id: 'none',
      name: 'No clients found',
      value: 'none',
    },
  ])
  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [isClientsLoading, setIsClientsLoading] = useState<boolean>(false)
  const [canvasCourses, setCanvasCourses] = useState<any>([
    {
      id: 'none',
      name: 'No courses found',
      value: 'none',
    },
  ])
  const [selectedCourse, setSelectedCourse] = useState<any>(null)
  const [isCoursesLoading, setIsCoursesLoading] = useState<boolean>(false)
  const [canvasAssignments, setCanvasAssignments] = useState<any>([
    {
      id: 'none',
      name: 'No assignments found',
      value: 'none',
    },
  ])
  const [selectedAssignment, setSelectedAssignment] = useState<any>(null)
  const [isAssignmentsLoading, setIsAssignmentsLoading] =
    useState<boolean>(false)
  useEffect(() => {
    const fetchCanvasClients = async () => {
      setIsClientsLoading(true)
      let clients = await getCanvasClients()
      clients = clients.map((client: any) => ({
        ...client,
        id: client.uuid,
        name: client.name,
        value: client.conn_id,
      }))
      if (clients.length > 0) {
        setCanvasClients([...clients])
        setSelectedClient(null)
      } else {
        setCanvasClients([
          {
            id: 'none',
            name: 'No clients found',
            value: 'none',
          },
        ])
        setSelectedClient(null)
      }
      setIsClientsLoading(false)
    }
    if (isLogged) fetchCanvasClients()
  }, [isLogged, getCanvasClients])

  useEffect(() => {
    const fetchCanvasCourses = async (clientConnId: string) => {
      setIsCoursesLoading(true)
      let courses = await getCanvasCourses(clientConnId)
      courses = courses.map((course: any) => ({
        ...course,
        id: course.id,
        name: course.name,
        value: course.id,
      }))
      if (courses.length > 0) {
        setCanvasCourses(courses)
        setSelectedCourse(courses[0])
      } else {
        setCanvasCourses([
          {
            id: 'none',
            name: 'No courses found',
            value: 'none',
          },
        ])
        setSelectedCourse(null)
      }
      setIsCoursesLoading(false)
    }
    if (selectedClient?.id) fetchCanvasCourses(selectedClient.value)
    // else {
    //   setCanvasCourses([
    //     {
    //       id: 'none',
    //       name: 'No courses found',
    //       value: 'none',
    //     },
    //   ])
    //   setSelectedCourse(null)
    // }
  }, [selectedClient, getCanvasCourses])

  useEffect(() => {
    const fetchCanvasAssignments = async (courseId: string) => {
      setIsAssignmentsLoading(true)
      let assignments = await getCanvasAssignments(
        selectedClient.value,
        courseId,
      )
      assignments = assignments.map((assignment: any) => ({
        ...assignment,
        id: assignment.id,
        name: assignment.name,
        value: assignment.id,
      }))
      assignments = assignments.filter(
        (assignment: any) => assignment.rubric?.length > 0,
      )

      if (assignments.length > 0) {
        setCanvasAssignments(assignments)
        setSelectedAssignment(assignments[0])
      } else {
        setCanvasAssignments([
          {
            id: 'none',
            name: 'No assignments found',
            value: 'none',
          },
        ])
        setSelectedAssignment(null)
      }
      setIsAssignmentsLoading(false)
    }
    if (selectedClient?.id && selectedCourse)
      fetchCanvasAssignments(selectedCourse.value)
  }, [selectedCourse, getCanvasAssignments])

  const downloadCSV = async (
    clientId: string,
    course: any,
    assignment: any,
    students: any,
  ) => {
    const criterions = assignment.rubric

    const headers = [
      'Name',
      ...criterions
        .map((criterion: any) => {
          return [
            criterion.description + ': feedback',
            criterion.description + ': score',
          ]
        })
        .flat(),
      'Student ID',
      'Course ID',
      'Assignment ID',
      'Client ID',
      // '__END_OF_ROW__',
    ]

    const dataRows = students.map((student: any) => {
      const row = [
        `${student.name}`,
        ...criterions
          .map((criterion: any) => {
            return ['', '']
          })
          .flat(),
        `'${student.id}`,
        `'${course.id}`,
        `'${assignment.id}`,
        `${clientId}`,
        // '__END_OF_ROW__',
      ]
      return row
    })
    const data = [headers, ...dataRows]

    // const csvContent = data.map((e) => e.join(',')).join('\n')
    // console.log(csvContent)
    const csvContent = Papa.unparse(data)

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'data.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const isLoading = isUploading || isSaving

  const hasFiles = (e: React.DragEvent<HTMLDivElement>) => {
    const types = e.dataTransfer.types
    return types.indexOf('Files') > -1
  }

  const onClickDownload = async (
    clientId: string,
    course: any,
    assignment: any,
    students: any,
  ) => {
    try {
      const blob = await getCanvasMarkingSheet(
        course.id,
        assignment.id,
        clientId,
      )
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url

      const filename = `${assignment.name}.xlsx`

      link.setAttribute('download', filename)
      document.body.appendChild(link)

      // Trigger the download
      link.click()

      // Cleanup
      link.parentNode?.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      ToastUtil.error('Failed to export quiz')
    }
  }

  return (
    <SidebarLayout>
      <style>{`
      `}</style>

      <div
        className={StyleUtil.containerNoScroll}
        style={{
          overflowY: 'auto',
        }}
      >
        <p className={StyleUtil.headline}>Upload grades</p>
        {canvasClients.length === 0 && <Spinner />}
        {canvasClients.length > 0 && (
          <>
            <div
              className="my-6 flex"
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {!isClientsLoading ? (
                <>
                  <DropDownMenu
                    items={[
                      {
                        id: null,
                        name: 'Choose a client',
                        value: null,
                        isLabel: true,
                      },
                      ...canvasClients,
                    ]}
                    onSelected={async (item) => {
                      setSelectedClient(item)
                    }}
                    // className="column"
                    style={{
                      // border: '1px solid red',
                      flexBasis: '220px',
                      marginRight: 12,
                    }}
                    customHACK={true}
                  />
                </>
              ) : (
                <div
                  style={{
                    flexBasis: '220px',
                    marginRight: 12,
                    height: 34,
                    marginTop: -32,
                  }}
                >
                  <Spinner />
                </div>
              )}

              {!isClientsLoading && !isCoursesLoading ? (
                <>
                  <DropDownMenu
                    items={canvasCourses}
                    onSelected={(item) => {
                      setSelectedCourse(item)
                    }}
                    // className="column"
                    style={{
                      // flexBasis: '360px',
                      flexGrow: 1,
                    }}
                    customHACK={true}
                  />
                </>
              ) : (
                <div
                  style={{
                    // flexBasis: '360px',
                    flexGrow: 1,
                    height: 34,
                    marginTop: -32,
                  }}
                >
                  <Spinner />
                </div>
              )}
              {!isClientsLoading &&
              !isCoursesLoading &&
              !isAssignmentsLoading ? (
                <DropDownMenu
                  items={canvasAssignments}
                  onSelected={(item) => {
                    setSelectedAssignment(item)
                  }}
                  style={{
                    flexBasis: '360px',
                    flexGrow: 1,
                    marginLeft: 12,
                  }}
                  customHACK={true}
                />
              ) : (
                <div
                  style={{
                    flexBasis: '360px',
                    flexGrow: 1,
                    marginLeft: 12,
                    height: 34,
                    marginTop: -32,
                  }}
                >
                  <Spinner />
                </div>
              )}
            </div>
            <div className="flex justify-center">
              <button
                className={
                  selectedAssignment
                    ? StyleUtil.buttonPrimary
                    : StyleUtil.buttonSecondary
                }
                onClick={() =>
                  onClickDownload(
                    selectedClient.value,
                    selectedCourse,
                    selectedAssignment,
                    selectedCourse.students,
                  )
                }
                style={{
                  ...(!selectedAssignment && {
                    cursor: 'not-allowed',
                    pointerEvents: 'none',
                  }),
                  marginBottom: 16,
                }}
              >
                Download marking sheet
              </button>
            </div>
          </>
        )}

        <article
          style={{
            maxWidth: 920,
            margin: '0 auto',
          }}
          aria-label="File Upload Modal"
          className="relative flex flex-col rounded-md bg-white pt-[76px] shadow-xl"
          onDrop={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault()
            // const newFiles: File[] = []
            // for (const file of e.dataTransfer.files) {
            //   newFiles.push(file)
            // }

            setSelectedFile(e.dataTransfer.files[0])
            // addFiles(newFiles)
          }}
          onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
            if (hasFiles(e)) {
              e.stopPropagation()
              e.preventDefault()
            }
          }}
          onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
            e.preventDefault()
            if (!hasFiles(e)) {
              return
            }
          }}
        >
          <section className="mx-auto flex h-full h-full w-full max-w-[682px] flex-col overflow-hidden p-8 pt-4">
            <header className="border-1 flex flex-col items-center justify-center border border-gray-400 py-16">
              <p className="flex flex-wrap justify-center py-8 font-semibold text-gray-900">
                <span>Drag file here to upload</span>
              </p>
              <input
                data-testid="hidden-input"
                id="hidden-input"
                title="hidden-input"
                type="file"
                // multiple={true}
                className="hidden"
                ref={inputRef}
                onInput={(e: ChangeEvent<HTMLInputElement>) => {
                  if (!e.target.files) {
                    return
                  }
                  const tmpFile = e.target.files[0] as any
                  if (!tmpFile) return
                  setSelectedFile(tmpFile)
                }}
              />
              <button
                data-testid="btn-select"
                type="button"
                id="btn-select"
                onClick={() => inputRef.current?.click()}
                className={`${
                  selectedFile
                    ? StyleUtil.buttonSecondary
                    : StyleUtil.buttonPrimary
                } mb-8 mt-8`}
              >
                <span className="pl-2 pr-2">Select Excel</span>
              </button>
            </header>

            {selectedFile && (
              <h1 className="font- pb-2 pt-9 font-medium text-gray-900 sm:text-lg">
                <img
                  src={excelSvg}
                  alt={selectedFile.name}
                  style={{ display: 'inline-block', marginRight: 8 }}
                />
                {selectedFile.name}
              </h1>
            )}
          </section>
          {isLoading && <Spinner />}
          <footer className="mb-9 flex justify-center px-8 pb-9 pt-0">
            <button
              data-testid="btn-submit"
              type="button"
              id="btn-submit"
              onClick={async () => {
                if (!selectedFile) {
                  ToastUtil.warning('Please select a file')
                  return
                }
                if (isUploading) return
                setIsUploading(true)
                const formData = new FormData()
                formData.append('file', selectedFile)
                try {
                  const data = await uploadGrades(formData)
                  console.log(data)
                  ToastUtil.success('Grades uploaded successfully')

                  if (data.errors?.length > 0) {
                    data.errors.forEach((err: any) => {
                      ToastUtil.error(`${err.studentName}: ${err.error}`)
                    })
                  }
                } catch (err) {
                  console.error(err)
                  // setIsUploading(false)
                  const message = (err as any).message
                  if (message) {
                    // document format error
                    ToastUtil.error(message)
                  } else {
                    // fallback general error
                    ToastUtil.error(`Failed to upload!\n${err}`)
                  }
                } finally {
                  setIsUploading(false)
                  setSelectedFile(null)
                  // @ts-ignore
                  inputRef.current.value && (inputRef.current.value = '')
                }
              }}
              disabled={isLoading}
              className={
                selectedFile
                  ? StyleUtil.buttonPrimary
                  : StyleUtil.buttonSecondary
              }
            >
              <span className="pl-2 pr-2">Upload grades</span>
            </button>
          </footer>
        </article>
      </div>
    </SidebarLayout>
  )
}

export default UploadGrades
