import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'

export interface Dimension {
  width: number
  height: number
}

interface AppProps {
  isMinimized: boolean
  setIsMinimized: (value: boolean) => void
  dimension: Dimension
}

const Context = createContext<AppProps | null>(null)

export const useApp = (): AppProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}

export const AppProvider = ({ children }: Props) => {
  const [isMinimized, setIsMinimized] = useState(false)
  const [dimension, setDimension] = useState<Dimension>({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  // to minimize the sidebar when the screen is small
  const WIDTH_THRESHOLD = 768

  const debounce = (fn: any, ms: number) => {
    let timer: any
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, null)
      }, ms)
    }
  }

  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      // console.log(
      //   `resize { width: ${window.innerWidth}, height: ${window.innerHeight} }}`,
      // )

      if (window.innerWidth <= WIDTH_THRESHOLD) {
        setIsMinimized(true)
      }

      setDimension({ width: window.innerWidth, height: window.innerHeight })
    }, 100)

    window.addEventListener('resize', debouncedResizeHandler)
    return () => window.removeEventListener('resize', debouncedResizeHandler)
  }, [])

  const providerValue = {
    isMinimized,
    setIsMinimized,
    dimension,
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
