import contactAdminSvg from 'images/contact-admin.svg'

function ErrorView() {
  return (
    <div className="w-full flex flex-col items-center">
      <p className="font-bold text-[18px] p-8">
        Sorry, you do not have access to this tool. If you think you should have
        access, please talk to your line manager or email
        <a
          href="mailto:hello@mentem.co"
          className="text-blue-700 hover:text-blue-500 pl-1"
        >
          hello@mentem.co
        </a>
      </p>
      <img src={contactAdminSvg} className="w-1/4 mt-8" alt="contact admin" />
    </div>
  )
}

export default ErrorView
