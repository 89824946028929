import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getCookie } from 'utils/CookieUtil'

export interface AuthState {
  isLoggedIn: boolean
}

export const cookieName = 'lx-session'
const isLoggedIn = (): boolean => {
  return getCookie(cookieName) !== null || getCookie(cookieName) !== undefined
}

const initialState: AuthState = {
  isLoggedIn: isLoggedIn(),
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggle: (state) => {
      state.isLoggedIn = !state.isLoggedIn
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggle, setIsLoggedIn } = authSlice.actions

export default authSlice.reducer
