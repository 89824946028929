import { useCallback, useEffect, useRef, useState } from 'react'
import AddTagsInput from './AddTagsInput'
import ImagePreivew from './ImagePreview'
import { useApi } from 'providers/ApiProvider'
import Spinner from './Spinner'
import { ToastUtil } from 'utils/ToastUtil'
import { StyleUtil } from 'utils/StyleUtil'
import { Category } from 'apis/entities/category.entity'
import MultipleSelect from './MultipleSelect'
import { Tag } from 'apis/entities/tag.entity'
import { ImageCategory } from 'core/constants'
import DropDownMenu, { DropDownItem } from './DropDownMenu'
import { ButtonCopy } from 'components/ButtonCopy'
import { FileObject } from 'apis/entities/file.entity'

export default function UploadImages() {
  const refInput = useRef<HTMLInputElement | null>(null)
  const refInputDescription = useRef<HTMLInputElement | null>(null)
  const refAddTagNames = useRef<string>('')
  const refAddTagInput = useRef<HTMLInputElement | null>(null)
  const [urls, setUrls] = useState<string[]>([])
  const [files, setFiles] = useState<FileObject[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const refSelectedFileUrls = useRef<string[]>([])
  const { uploadImage, getImageCategories, getTags } = useApi()
  const [categories, setCategories] = useState<Category[]>([])
  const [tags, setTags] = useState<Tag[]>([])
  const [selectedCategory, setSelectedCategory] = useState<Category>()
  const refSelectedIds = useRef<string[]>([])
  const [isFetchedTags, setIsFetchedTags] = useState<boolean>(false)
  const [isFetchedCategories, setIsFetchedCategories] = useState<boolean>(false)

  const fetchImageCategories = useCallback(async () => {
    try {
      const categories = await getImageCategories()

      setCategories(categories)
      setIsFetchedCategories(true)
      // default selected category
      if (categories.length > 0) {
        setSelectedCategory(
          categories.find((x) => {
            return x.name === ImageCategory.OtherImages.valueOf()
          }),
        )
      }
    } catch (err) {
      console.error(err)
    }
  }, [getImageCategories])

  const fetchTags = useCallback(async () => {
    try {
      const tags = await getTags()
      setTags(tags)
      setIsFetchedTags(true)
    } catch (err) {
      console.error(err)
    }
  }, [getTags])

  const isFetched = (): boolean => {
    return isFetchedTags && isFetchedCategories
  }

  const addFiles = (newFiles: File[]) => {
    const tmpFiles = newFiles.map((file) => {
      return { objectURL: URL.createObjectURL(file), file: file }
    })
    setFiles(files.concat(tmpFiles))
    refSelectedFileUrls.current = refSelectedFileUrls.current.concat(
      tmpFiles.map((file) => file.objectURL),
    )
  }

  const isEmpty = (): boolean => {
    return files.length === 0
  }

  const onSelect = () => {
    refInput.current?.click()
  }

  const onInputFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || []
    console.log(files)
    const newFiles: File[] = []
    for (const file of files) {
      newFiles.push(file)
    }
    addFiles(newFiles)
    // reset selected files
    refInput.current!.value = ''
  }

  const onDeletePreview = (id: string) => {
    // delete file by id
    // console.log(`delete id = ${id}`)
    const newFiles = files.filter((file) => {
      return file.objectURL !== id
    })
    setFiles(newFiles)
  }

  const onSelectPreivew = (id: string, checked: boolean) => {
    // console.log(`select id = ${id}, checked = ${checked}`)
    // add or remove selected file
    if (checked) {
      refSelectedFileUrls.current.push(id)
    } else {
      refSelectedFileUrls.current = refSelectedFileUrls.current.filter(
        (url) => {
          return url !== id
        },
      )
    }
  }

  const onSubmit = async () => {
    // console.log(refAddTagInput.current?.value)
    if (files.length === 0) {
      ToastUtil.warning('Please select files')
      return
    }

    const currentDescription = refInputDescription.current?.value
    if (requiredInputDescription() && !currentDescription) {
      ToastUtil.warning('Please input description')
      return
    }

    setIsLoading(true)
    const currentTagNames = refAddTagInput.current?.value // previous one is `refAddTagNames.current`
    const tmpUrls: string[] = []
    for (let i = 0; i < files.length; i++) {
      // console.log(files[i])
      // add description to selected files
      const objectURL = files[i].objectURL
      let description = undefined
      if (
        currentDescription &&
        refSelectedFileUrls.current.indexOf(objectURL) !== -1
      ) {
        description = currentDescription
      }
      try {
        // console.log(`description=${description}`)
        const url = await uploadImage(
          files[i].file,
          currentTagNames,
          refSelectedIds.current,
          description,
          selectedCategory?.id,
          [],
        )
        console.log(`uploaded url=${url}`)
        if (url) {
          tmpUrls.push(url)
        }
      } catch (err) {
        console.error(err)
        ToastUtil.error('Upload failed')
      }
    }
    setIsLoading(false)
    setUrls(tmpUrls)
    // reset files
    setFiles([])
    refSelectedFileUrls.current = []
    ToastUtil.success('Uploaded successfully')
  }

  const hasFiles = (e: React.DragEvent<HTMLDivElement>) => {
    const types = e.dataTransfer.types
    return types.indexOf('Files') > -1
  }

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    // console.log(e.dataTransfer.files)
    const newFiles: File[] = []
    for (const file of e.dataTransfer.files) {
      newFiles.push(file)
    }
    addFiles(newFiles)
  }

  const onDragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!hasFiles(e)) {
      return
    }
  }

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // console.log('onDragLeaveHandler')
  }

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    if (hasFiles(e)) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onAddTagsInputChanged = (value: string) => {
    refAddTagNames.current = value
  }

  const onSelectedCategory = (item: DropDownItem) => {
    const category = categories.find((category) => item.id === category.id)
    setSelectedCategory(category)
  }

  const onSelectedTags = (ids: string[]) => {
    refSelectedIds.current = ids
  }

  const showInputTopics = (): boolean => {
    const list = [
      ImageCategory.BackgroundImages.valueOf(),
      // ImageCategory.DigitalBadges.valueOf(),
    ]
    return (selectedCategory && list.includes(selectedCategory.name)) || false
  }

  const showInputDescription = (): boolean => {
    const list = [
      ImageCategory.OtherImages.valueOf(),
      ImageCategory.DigitalBadges.valueOf(),
    ]
    return (selectedCategory && list.includes(selectedCategory.name)) || false
  }

  const canMultipleSelectTopics = (): boolean => {
    const list = [ImageCategory.BackgroundImages.valueOf()]
    return (selectedCategory && list.includes(selectedCategory.name)) || false
  }

  const requiredInputDescription = (): boolean => {
    // const list = [ImageCategory.SpecificImages.valueOf()]
    // return (selectedCategory && list.includes(selectedCategory.name)) || false
    return false
  }

  const onCopy = (url: string) => {
    navigator.clipboard.writeText(encodeURI(url))
    ToastUtil.success('URL link copied to clipboard')
  }

  useEffect(() => {
    fetchTags()
    fetchImageCategories()
  }, [fetchTags, fetchImageCategories])

  return (
    <main className="mb-8 ml-8 mr-8 mt-4 max-w-full">
      {!isFetched() && <Spinner />}
      {isFetched() && (
        <>
          <div className="mb-4 ml-[2%] mr-[2%] mt-4 flex w-full flex-col">
            {categories.length > 0 && (
              <div className="m-4 flex flex-row">
                <div>
                  <p className="mentem-text-primary">
                    Choose the category you'd like to upload to
                  </p>
                </div>
                <div className="w-[43px]"></div>
                {categories.length > 0 && (
                  <DropDownMenu
                    items={categories.map((cat) => {
                      return {
                        ...cat,
                        default:
                          cat.name === ImageCategory.OtherImages.valueOf(),
                      }
                    })}
                    onSelected={onSelectedCategory}
                    customHACK={true}
                  />
                )}
              </div>
            )}
            {showInputTopics() && tags.length > 0 && (
              <div className="m-4">
                <p className="mentem-text-primary">
                  Choose the{' '}
                  {selectedCategory?.name ===
                  ImageCategory.BackgroundImages.valueOf()
                    ? 'subject'
                    : 'topic'}
                  (s) you’d like to assign to
                </p>
                <div className="m-4"></div>
                <MultipleSelect
                  items={tags.map((tag) => {
                    return {
                      id: tag.id,
                      name: tag.name,
                      selected: refSelectedIds.current.includes(tag.id),
                    }
                  })}
                  onSelected={onSelectedTags}
                  multiple={canMultipleSelectTopics()}
                />
              </div>
            )}
            {showInputTopics() && (
              <AddTagsInput
                onInputChanged={onAddTagsInputChanged}
                refInupt={refAddTagInput}
              />
            )}
          </div>
          <article
            aria-label="File Upload Modal"
            className="relative flex h-full flex-col rounded-md bg-white shadow-xl"
            onDrop={onDropHandler}
            onDragOver={onDragOverHandler}
            onDragLeave={onDragLeaveHandler}
            onDragEnter={onDragEnterHandler}
          >
            <section className="flex h-full h-full w-full flex-col overflow-hidden p-8 pt-4">
              <header className="border-1 flex flex-col items-center justify-center border border-gray-400 py-16">
                <p className="flex flex-wrap justify-center py-8 font-semibold text-gray-900">
                  <span>Drag file(s) here to upload</span>
                </p>
                <input
                  data-testid="hidden-input"
                  id="hidden-input"
                  title="hidden-input"
                  type="file"
                  multiple={true}
                  className="hidden"
                  ref={refInput}
                  onChange={onInputFiles}
                />
                <button
                  data-testid="btn-select"
                  type="button"
                  id="btn-select"
                  onClick={onSelect}
                  className={`${StyleUtil.buttonPrimary} mb-8 mt-8`}
                >
                  <span className="pl-2 pr-2">Select Files</span>
                </button>
              </header>

              {!isEmpty() && (
                <h1 className="pb-6 pt-8 font-semibold text-gray-900 sm:text-lg">
                  Preview
                </h1>
              )}

              <ul id="gallery" className="flex flex-1 flex-wrap">
                {files.map((file, index) => {
                  return (
                    <ImagePreivew
                      key={index}
                      fileObject={file}
                      onDelete={onDeletePreview}
                      onSelect={
                        showInputDescription() ? onSelectPreivew : undefined
                      }
                    />
                  )
                })}
              </ul>
            </section>
            {isLoading && <Spinner />}
            <div className="mb-4 flex justify-start px-8 text-blue-900">
              <ul>
                {urls &&
                  urls.map((url, index) => {
                    return (
                      <li key={index} className="p-2">
                        <div className="flex flex-row items-center p-1">
                          <img alt={url} src={url} className="w-[100px] p-2" />
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                          <ButtonCopy url={url} onClick={onCopy} />
                        </div>
                      </li>
                    )
                  })}
              </ul>
            </div>

            {showInputDescription() && (
              <div className="flex justify-center py-4">
                <div className="relative w-full max-w-lg">
                  <label className="w-full">
                    <input
                      data-testid="input-description"
                      id="input-description"
                      ref={refInputDescription}
                      type="text"
                      className={StyleUtil.inputSearch}
                      placeholder={
                        selectedCategory?.name ===
                        ImageCategory.DigitalBadges.valueOf()
                          ? 'Type badge name here'
                          : 'Add image description here'
                      }
                    />
                  </label>
                </div>
              </div>
            )}
            {/*{selectedCategory?.name === ImageCategory.OtherImages.valueOf() && (*/}
            {/*  <div className="pb-1">*/}
            {/*    <AddTagsInput*/}
            {/*      onInputChanged={onAddTagsInputChanged}*/}
            {/*      refInupt={refAddTagInput}*/}
            {/*      placeholder="Add tag(s) to images, separate tag(s) by comma"*/}
            {/*      style={{ maxWidth: 517, margin: '0 auto' }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
            <footer className="flex justify-center px-8 pb-8 pt-4">
              <button
                data-testid="btn-submit"
                type="button"
                id="btn-submit"
                onClick={onSubmit}
                disabled={isLoading}
                className={StyleUtil.buttonPrimary}
              >
                <span className="pl-2 pr-2">Upload</span>
              </button>
            </footer>
          </article>
        </>
      )}
    </main>
  )
}
