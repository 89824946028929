import MenuAuthoring from 'images/menu-authoring.svg'
import MenuSourcing from 'images/menu-sourcing.svg'
import MenuTopics from 'images/menu-topics.svg'
import MenuFiles from 'images/menu-files.svg'
import MenuQuestionGenerator from 'images/menu-question-generator.svg'
import MenuReview from 'images/menu-review.svg'

interface MenuItem {
  title: string
  path: string
  icon: string
}

const Path = {
  root: { title: 'Home', path: '/' } as MenuItem,
  error: { title: 'Error', path: '/error' } as MenuItem,
  architectures: {
    title: 'Architectures',
    path: '/architectures',
    icon: MenuAuthoring,
  } as MenuItem,
  authoring: {
    title: 'Authoring',
    path: '/authoring',
    icon: MenuAuthoring,
  } as MenuItem,
  authoringBeta: {
    title: 'Authoring beta',
    path: '/authoring-beta',
    icon: MenuAuthoring,
  } as MenuItem,
  sourcing: {
    title: 'Sourcing',
    path: '/sourcing',
    icon: MenuSourcing,
  } as MenuItem,
  files: {
    title: 'Files (deprecated)',
    path: '/files',
    icon: MenuFiles,
  } as MenuItem,
  topics: {
    title: 'Topics',
    path: '/topics',
    icon: MenuTopics,
  } as MenuItem,
  mediaPage: {
    title: 'Media',
    path: '/media',
    icon: MenuTopics,
  } as MenuItem,
  questionGenerator: {
    title: 'Question generator',
    path: '/question-generator',
    icon: MenuQuestionGenerator,
  } as MenuItem,
  quizzes: {
    title: 'Quizzes',
    path: '/quizzes',
    icon: MenuReview,
  } as MenuItem,
  uploadGrades: {
    title: 'Grades',
    path: '/upload-grades',
    icon: MenuTopics,
  } as MenuItem,
}

const Menu =
  process.env.REACT_APP_ENV === 'prd'
    ? [
        Path.architectures,
        Path.authoring,
        Path.mediaPage,
        Path.topics,
        Path.quizzes,
        Path.uploadGrades,
      ]
    : [
        Path.architectures,
        Path.authoring,
        // Path.authoringBeta,
        Path.mediaPage,
        Path.topics,
        Path.quizzes,
        Path.uploadGrades,
      ]

const MenuForDesigner = [Path.authoring, Path.mediaPage, Path.topics]

export default Path
export { Menu, MenuForDesigner }
