import { TextareaAutosize } from '@mui/base'
import { QuizQuestion } from 'apis/entities/quiz.entity'
import SidebarLayout from 'components/SidebarLayout'
import { useApi } from 'providers/ApiProvider'
import { Permission, Role, useAuth } from 'providers/AuthProvider'
import React from 'react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Path from 'routes/Path'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import Spinner from 'views/Spinner'

function QuizCreatePage() {
  const navigate = useNavigate()
  const { getRole, permissions, getAuth0UserDetails } = useAuth()
  const [isCreating, setIsCreating] = useState(false)
  const { createQuiz, addQuizQuestion } = useApi()
  const [isClickDelete, setIsClickDelete] = useState<boolean>(false)
  const refInputDescription = React.createRef<HTMLTextAreaElement>()
  const refInputName = React.createRef<HTMLTextAreaElement>()

  const onClickCreate = async () => {
    if (!refInputName.current) {
      return
    }
    const name = refInputName.current.value
    const description = refInputDescription.current?.value
    if (!name) {
      ToastUtil.warning('Quiz name cannot be empty')
      return
    }

    try {
      setIsCreating(true)
      const user = getAuth0UserDetails()
      const auth0Id = user?.id
      const username = user?.username
      const firstName = user?.firstName
      const email = user?.email
      const quiz = await createQuiz(
        name,
        description,
        auth0Id,
        username,
        firstName,
        email,
      )
      if (quiz) {
        // add one question placeholder
        await addQuizQuestion(quiz.id, {} as QuizQuestion)
        navigate(`${Path.quizzes.path}/${quiz.id}`)
      } else {
        ToastUtil.error('Failed to create quiz')
      }
    } catch (error) {
      ToastUtil.error('Failed to create quiz')
    } finally {
      setIsCreating(false)
    }
  }

  const hasPermission = useCallback(
    (permission: string, createdAuth0Id?: string): boolean => {
      // https://horizonsfl.atlassian.net/browse/HLP-1465
      if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
      if (
        permissions &&
        permissions.includes(permission) &&
        getAuth0UserDetails()?.id === createdAuth0Id
      ) {
        // same user
        return true
      }
      // Author can only edit
      if (permissions && permissions.includes(permission)) {
        return true
      }
      return false
    },
    [getAuth0UserDetails, getRole, permissions],
  )

  const onClickDelete = () => {
    setIsClickDelete(true)
  }

  const onConfirmDelete = async () => {
    // navigate to quizzes page
    navigate(`${Path.quizzes.path}`)
  }

  const getActionButtonStyle = (loading: boolean) => {
    const style = StyleUtil.buttonPrimary
    if (loading) {
      return `${style} loading disabled`
    }
    return style
  }

  return (
    <SidebarLayout>
      <div className={`${StyleUtil.container} flex flex-col pb-[6vh] gap-4`}>
        <div className="flex flex-row items-center pr-[5%]">
          <p className={StyleUtil.headlineSmall}>
            <span
              className="cursor-pointer hover:opacity-80"
              onClick={() => {
                navigate(`${Path.quizzes.path}`)
              }}
            >
              Mentem quiz tool
            </span>
            <span className="text-[#000]"> / Create draft</span>
          </p>
        </div>
        <div className="flex flex-row items-center">
          {hasPermission(Permission.DeleteQuizzes) && (
            <>
              <div className="grow" />
              <div className="flex items-center justify-end h-[20px] pr-[5%] mt-2">
                {isClickDelete && (
                  <>
                    <span className="mr-4 text-[16px]">Are you sure?</span>
                    <button
                      data-testid="btn-delete-from-quiz-yes"
                      onClick={onConfirmDelete}
                      className={getActionButtonStyle(false)}
                    >
                      Yes
                    </button>
                    <span
                      data-testid="btn-delete-from-quiz-no"
                      onClick={() => {
                        setIsClickDelete(false)
                        return false
                      }}
                      className="ml-4 cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                    >
                      Cancel
                    </span>
                  </>
                )}
                {!isClickDelete && (
                  <div className="flex flex-row gap-4 items-center">
                    <span
                      data-testid="btn-delete"
                      onClick={onClickDelete}
                      className="mentem-link-underline"
                    >
                      Delete draft
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col gap-4 pr-[5%]">
          <div className="quiz-create-name-desc-container">
            <TextareaAutosize
              id="quiz-create-name-input"
              placeholder="Type quiz name here"
              className="quiz-create-name-input"
              ref={refInputName}
            />
            <TextareaAutosize
              id="quiz-create-description-input"
              placeholder="Type course description here"
              className="quiz-create-description-input"
              ref={refInputDescription}
            />
          </div>
          <div className="self-end">
            {isCreating && <Spinner />}
            {!isCreating && (
              <button
                className={StyleUtil.buttonPrimary}
                onClick={onClickCreate}
              >
                Create draft
              </button>
            )}
          </div>
        </div>
      </div>
    </SidebarLayout>
  )
}

export default QuizCreatePage
