import { StyleUtil } from 'utils/StyleUtil'
import closeSvg from '../images/close2.svg'
import Spinner from './Spinner'
import React from 'react'

interface Props {
  id: string
  title: string
  subtitle?: string
  isLoading?: boolean
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
}

export default function WidgetConfirmation({
  id,
  title,
  subtitle,
  isLoading,
  onConfirm,
  onCancel,
  onClose,
}: Props): JSX.Element {
  return (
    <div
      id={id}
      className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center"
    >
      <div className="h-auto flex flex-col bg-white shadow-xl rounded-[12px] p-[12px] gap-[8px] pb-[24px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={onClose}
        />
        <p className="mentem-widget-title pl-[12px] pr-[48px]">
          {title.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        {subtitle && (
          <p className="mentem-widget-subtitle pl-[12px] pr-[48px]">
            {subtitle}
          </p>
        )}
        <div className="flex flex-row gap-4 items-center justify-center pt-[16px]">
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              <button
                type="button"
                className={StyleUtil.buttonSecondary}
                onClick={onConfirm}
              >
                Confirm
              </button>
              <button
                type="button"
                className={StyleUtil.buttonPrimary}
                onClick={onCancel}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
