import { StyleUtil } from 'utils/StyleUtil'
import closeSvg from '../images/close2.svg'
import Spinner from './Spinner'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useApi } from 'providers/ApiProvider'
import DropDownMenu, { DropDownItem } from './DropDownMenu'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import { Document } from '../apis/entities/document.entity'
import { Tooltip } from 'react-tooltip'
import { ToastUtil } from 'utils/ToastUtil'
import { GeneratedQuestion, QuizQuestion } from 'apis/entities/quiz.entity'
import { useAuth } from 'providers/AuthProvider'
import Path from 'routes/Path'
import { useNavigate } from 'react-router-dom'
import SearchInput from './SearchInput'
import { getDateText, getTimeText } from 'utils/StringUtil'
import LargeSpinner from './LargeSpinner'
import Emitter, { Events } from 'core/emitter'

interface Props {
  multiple: boolean
  onGenerated: () => void
  onClose: () => void
}

export default function WidgetQuestionGeneratorOld({
  multiple,
  onGenerated,
  onClose,
}: Props): JSX.Element {
  const navigate = useNavigate()
  const { getAuth0UserDetails } = useAuth()
  const [isFetched, setIsFetched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [documents, setDocuments] = useState<Document[]>([])
  const {
    getDocuments,
    generateMultipleQuestionsStart,
    generateMultipleQuestionsStatus,
    createQuiz,
  } = useApi()
  const [orderByTopic, setOrderByTopic] = useState<Direction>('')
  const [orderByPublishedBy, setOrderByPublishedBy] = useState<Direction>('')
  const [orderByDateTime, setOrderByDateTime] = useState<Direction>('')
  // default render rows
  const [renderRowCount, setRenderRowCount] = useState<number>(20)
  const ROW_COUNT = 20
  const refNumberOfQuestions = useRef<number>(30)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')

  const tooltipStyle = {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '6px',
  }

  const tooltipStyleReverse = {
    backgroundColor: '#6b69c1',
    color: '#fff',
    borderRadius: '6px',
  }

  enum SortBy {
    Topic = 'topic',
    PublishedBy = 'publishedUserName',
    DateTime = 'updatedAt',
  }

  type Direction = 'ascending' | 'descending' | ''

  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>(null)

  const onInputChangedSearch = (value: string) => {
    setSearchText(value)
  }

  const onEnterSearch = async () => {
    // do nth
  }

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.Topic:
        return orderByTopic === 'ascending' ? tooltipStyleReverse : tooltipStyle
      case SortBy.PublishedBy:
        return orderByPublishedBy === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.DateTime:
        return orderByDateTime === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      default:
        return tooltipStyle
    }
  }

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.Topic:
        if (orderByTopic === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.PublishedBy:
        if (orderByPublishedBy === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.DateTime:
        if (orderByDateTime === 'ascending') return 'Sort new to old'
        return 'Sort old to new'
      default:
        return ''
    }
  }

  const createQuizByGenerator = useCallback(
    async (questions: GeneratedQuestion[]) => {
      try {
        const user = getAuth0UserDetails()
        const auth0Id = user?.id
        const username = user?.username
        const firstName = user?.firstName
        const email = user?.email

        const quizQuestions = questions.map((q, i) => {
          return {
            text: q.questionText,
            learningOutcome: q.learningOutcome,
            answers: [q.correctAnswer, q.incorrectAnswer1, q.incorrectAnswer2],
            position: i + 1,
          } as QuizQuestion
        })

        const documentIds = checkedIds
        const quiz = await createQuiz(
          ' ', // space for no title
          '',
          auth0Id,
          username,
          firstName,
          email,
          quizQuestions,
          documentIds,
        )
        if (quiz) {
          onGenerated()
          navigate(`${Path.quizzes.path}/${quiz.id}`)
        }
      } catch (error) {
        console.error('Failed to create quiz by generator', error)
      } finally {
        setIsGenerating(false)
      }
    },
    [getAuth0UserDetails, checkedIds, createQuiz, onGenerated, navigate],
  )

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.Topic:
        setOrderByTopic(ascending ? 'ascending' : 'descending')
        break
      case SortBy.PublishedBy:
        setOrderByPublishedBy(ascending ? 'ascending' : 'descending')
        break
      case SortBy.DateTime:
        setOrderByDateTime(ascending ? 'ascending' : 'descending')
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? 'ascending' : 'descending' })
  }

  const sortedDocuments = useMemo(() => {
    let sortableItems = documents?.length ? [...documents] : []
    if (searchText) {
      sortableItems = sortableItems.filter((x) =>
        x.topic.toLowerCase().includes(searchText.toLowerCase()),
      )
    }
    if (sortConfig !== null) {
      sortableItems.sort((a: Document, b: Document) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [documents, searchText, sortConfig])

  const fetchedDocuments = useCallback(async () => {
    setIsLoading(true)
    try {
      const documents = await getDocuments(`published=1&isTopic=1`)
      // console.log('documents', documents)
      setDocuments(documents)
      setIsFetched(true)
    } catch (error) {
      console.error('error', error)
    } finally {
      setIsLoading(false)
    }
  }, [getDocuments])

  const fetchGenerateStatus = useCallback(
    async (jobId: string) => {
      console.log('fetchGenerateStatus', jobId)
      if (jobId) {
        const eventSource = generateMultipleQuestionsStatus(jobId)
        eventSource.onmessage = (event) => {
          const eventData = JSON.parse(event.data)
          if (eventData.isComplete) {
            console.log(`Task ${jobId} is complete.`)
            eventSource.close()
            if (eventData.error) {
              console.log(`Task ${jobId} failed: ${eventData.error}`)
              ToastUtil.error(eventData.error)
            } else {
              console.log(`Task ${jobId} succeeded.`)
              // console.log('onGenerated', eventData.questions)
              // next step
              createQuizByGenerator(eventData.questions)
            }
          } else {
            console.log(`Task ${jobId} is still running.`)
          }
        }
      }
    },
    [createQuizByGenerator, generateMultipleQuestionsStatus],
  )

  const onSelectNumberOfQuestions = (item: DropDownItem) => {
    const value = item.value as number
    refNumberOfQuestions.current = value
  }

  const handleScroll = useCallback(
    (e: Event) => {
      // check if scrolling to bottom
      const target = e.target as HTMLElement
      if (
        Math.ceil(target.scrollTop + target.clientHeight) >=
        Math.floor(target.scrollHeight)
      ) {
        // console.log('scroll to bottom')
        // get the number of rows
        const rows = document.querySelectorAll('tbody tr')
        const startIndex = rows.length
        // render 10 more rows if available
        const endIndex = Math.min(
          startIndex + ROW_COUNT,
          sortedDocuments.length,
        )
        if (endIndex <= startIndex) return
        // console.log('renderRows', startIndex, endIndex, renderRowCount)
        setRenderRowCount(endIndex)
      }
    },
    [sortedDocuments],
  )

  const onClickGenerate = async () => {
    if (multiple) {
      generateMultipleQuestions()
    } else {
      generateNewQuestion()
    }
  }

  const generateNewQuestion = async () => {
    console.log('selectedTopicId', selectedDocumentId)
    if (!selectedDocumentId) {
      ToastUtil.warning('Please select a topic')
      return
    }
    Emitter.emit(Events.GenerateNewQuestion, { documentId: selectedDocumentId })
    onClose()
  }

  const generateMultipleQuestions = async () => {
    const topicIds = checkedIds
    const numberOfQuestions = refNumberOfQuestions.current
    console.log('numberOfQuestions', numberOfQuestions)
    if (topicIds.length === 0) {
      ToastUtil.warning('Please select a topic')
      return
    }
    if (numberOfQuestions) {
      setIsGenerating(true)
      const res = await generateMultipleQuestionsStart(
        topicIds,
        numberOfQuestions,
      )
      console.log('res', res)
      if (res && res.jobId) {
        const jobId = res.jobId
        fetchGenerateStatus(jobId)
      } else {
        ToastUtil.error('Failed to generate questions')
        setIsGenerating(false)
      }
    }
  }

  const getPreviewUrl = (doc: Document): string => {
    if (doc.published) {
      return `https://${process.env.REACT_APP_PUBLISH_URL}/lx/${doc.id}/index.html`
    } else {
      return `https://${process.env.REACT_APP_PREVIEW_URL}/${doc.id}/index.html`
    }
  }

  const onChangeCheckbox = useCallback(
    (checked: boolean, id: string) => {
      if (checked) {
        setCheckedIds([...checkedIds, id])
      } else {
        setCheckedIds(checkedIds.filter((y) => y !== id))
      }
    },
    [checkedIds],
  )

  useEffect(() => {
    fetchedDocuments()
  }, [fetchedDocuments])

  // detect scroll to bottom by page parent
  useEffect(() => {
    const parent = document.getElementById('widget-topic-container')
      ?.parentElement?.parentElement?.parentElement
    if (parent) {
      parent.addEventListener('scroll', handleScroll)
      return () => {
        parent?.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll, sortedDocuments])

  const renderRow = (document: Document, index: number) => {
    return (
      <tr key={index}>
        <td className="mentem-table-cell-2 mentem-title-column">
          <div className="flex flex-row items-center justify-center">
            {multiple && (
              <input
                type="checkbox"
                className="self-center"
                data-id="checkbox"
                checked={checkedIds.includes(document.id)}
                onChange={(e) =>
                  onChangeCheckbox(e.target.checked, document.id)
                }
                disabled={isGenerating}
              />
            )}
            {!multiple && (
              <input
                type="radio"
                name="topicId"
                className="self-center"
                value={document.id}
                checked={selectedDocumentId === document.id}
                onChange={(e) => setSelectedDocumentId(e.target.value)}
              />
            )}
            <a
              className="mentem-link pl-2 underline w-[80%]"
              href={getPreviewUrl(document)}
              target="_blank"
              rel="noreferrer"
            >
              {document.topic || '(No title)'}
            </a>
          </div>
        </td>
        <td className="mentem-table-cell-1 text-center">
          <div>{document.publishedUserName}</div>
        </td>
        <td className="mentem-table-cell-2 text-center">
          <div>
            <div className="flex items-center justify-center">
              {getDateText(document.updatedAt)}
            </div>
            <div className="flex items-center justify-center">
              {getTimeText(document.updatedAt)}
            </div>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div
      id="widget-topic-container"
      className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center"
    >
      <div className="max-h-[75%] w-3/4 flex flex-col bg-white shadow-xl rounded-[12px] p-[12px] gap-[12px] pb-[24px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={onClose}
        />
        {isLoading && <Spinner />}
        {isGenerating && (
          <LargeSpinner text="Generating questions. This may take a couple of minutes, please be patient." />
        )}
        {!isGenerating && isFetched && (
          <>
            <div className="flex flex-row items-center justify-start gap-4 px-[12px]">
              <div className="w-1/2">
                <SearchInput
                  onInputChanged={onInputChangedSearch}
                  onEnter={onEnterSearch}
                  isSearching={false}
                  placeholder="Search topic name or key words"
                />
              </div>
              {multiple && (
                <div className="flex flex-row items-center justify-center gap-4 w-1/2">
                  <p className="mentem-widget-title">
                    Number of questions to generate
                  </p>
                  <DropDownMenu
                    items={[
                      // start from 10 to 30 numbers interval 5
                      ...Array.from({ length: 5 }, (_, i) => i).map((i) => {
                        const value = i * 5 + 10
                        return {
                          id: value.toString(),
                          name: value.toString(),
                          value: value,
                          default: value === 30,
                        }
                      }),
                    ]}
                    onSelected={onSelectNumberOfQuestions}
                  />
                </div>
              )}
            </div>
            {isFetched && sortedDocuments.length === 0 && (
              <p className="text-center">No data available</p>
            )}
            {isFetched && sortedDocuments.length > 0 && (
              <div className="mt-2 overflow-auto">
                <table className="mentem-table">
                  <thead>
                    <tr>
                      <th
                        className={`${StyleUtil.stickyTableHeader1} h-[55px] w-[50%] min-w-[200px]`}
                      >
                        <div className="flex flex-row items-center px-2">
                          <span className="grow">Existing topics</span>
                          <img
                            className="cursor-pointer"
                            src={sortSvg}
                            alt="sort"
                            onClick={() => onClickSort(SortBy.Topic)}
                            data-tooltip-id="tooltip-topic"
                          />
                        </div>
                      </th>
                      <th
                        className={`${StyleUtil.stickyTableHeader2} w-[25%] min-w-[150px]`}
                      >
                        <div className="flex flex-row items-center px-2">
                          <span className="grow">Created by</span>
                          <img
                            className="cursor-pointer"
                            src={sortSvg}
                            alt="sort"
                            onClick={() => onClickSort(SortBy.PublishedBy)}
                            data-tooltip-id="tooltip-published-by"
                          />
                        </div>
                      </th>
                      <th
                        className={`${StyleUtil.stickyTableHeader1} w-[25%] min-w-[160px]`}
                      >
                        <div className="flex flex-row items-center px-2">
                          <span className="grow">Last updated</span>
                          <img
                            className="cursor-pointer pl-2 pr-2"
                            src={sort2Svg}
                            alt="sort"
                            onClick={() => onClickSort(SortBy.DateTime)}
                            data-tooltip-id="tooltip-date-time"
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedDocuments
                      .slice(
                        0,
                        Math.min(sortedDocuments.length, renderRowCount),
                      )
                      .map((document, index: number) => {
                        return renderRow(document, index)
                      })}
                  </tbody>
                </table>
                <Tooltip
                  id="tooltip-topic"
                  className="mentem-tooltip"
                  style={getTooptipStyle(SortBy.Topic)}
                  place="top"
                  noArrow={true}
                >
                  {getSortingText(SortBy.Topic)}
                </Tooltip>
                <Tooltip
                  id="tooltip-published-by"
                  className="mentem-tooltip"
                  style={getTooptipStyle(SortBy.PublishedBy)}
                  place="top"
                  noArrow={true}
                >
                  {getSortingText(SortBy.PublishedBy)}
                </Tooltip>
                <Tooltip
                  id="tooltip-date-time"
                  className="mentem-tooltip"
                  style={getTooptipStyle(SortBy.DateTime)}
                  place="top"
                  noArrow={true}
                >
                  {getSortingText(SortBy.DateTime)}
                </Tooltip>
              </div>
            )}
            <div className="grow" />
            <div className="flex flex-col items-center justify-center">
              <button
                type="button"
                className={StyleUtil.buttonPrimary}
                onClick={onClickGenerate}
              >
                Auto-generate
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
