import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'app/AuthSlice'
import quizReducer from 'app/QuizSlice'
import questionsReducer from 'app/QuestionsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    quiz: quizReducer,
    questions: questionsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
