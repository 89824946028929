import { Route, Routes } from 'react-router-dom'
import AuthoringPage from 'pages/AuthoringPage'
import Path from 'routes/Path'
import Topics from './pages/Topics'
import SourcingPage from 'pages/SourcingPage'
import QuizReviewPage from 'pages/QuizReviewPage'
import { ToastContainer } from 'react-toastify'
import ProtectedRoute from 'routes/ProtectedRoute'
import HomePage from 'pages/HomePage'
import MediaPage from 'pages/MediaPage'
import ErrorPage from 'pages/ErrorPage'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { ApiProvider } from './providers/ApiProvider'
import { store } from './app/store'
import { Provider } from 'react-redux'
import AuthProvider from './providers/AuthProvider'
import { AppProvider } from 'providers/AppProvider'
import { ReactNode } from 'react'
import JoQuestionsPage from './pages/JoQuestionsPage'
import QuizReviewDetailsPage from 'pages/QuizReviewDetailsPage'
import QuizCreatePage from 'pages/QuizCreatePage'
import AuthoringPageBeta from 'pages/AuthoringPageBeta'
import ArchitecturePage from 'pages/ArchitecturePage'
import { ArchitectureApiProvider } from 'providers/ArchitectureApiProvider'
import UploadGrades from './pages/UploadGrades'

interface Auth0ProviderProps {
  children: ReactNode
}

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: Auth0ProviderProps) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      {...props}
    >
      {children}
    </Auth0Provider>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback>
        <Provider store={store}>
          <AppProvider>
            <ArchitectureApiProvider>
              <ApiProvider>
                <AuthProvider>
                  <ToastContainer />
                  <Routes>
                    <Route
                      path={Path.root.path}
                      element={<ProtectedRoute component={HomePage} />}
                    />
                    <Route
                      path={Path.error.path}
                      element={<ProtectedRoute component={ErrorPage} />}
                    />
                    <Route
                      path={Path.architectures.path}
                      element={<ProtectedRoute component={ArchitecturePage} />}
                    />
                    <Route
                      path={`${Path.architectures.path}/:clientId`}
                      element={<ProtectedRoute component={ArchitecturePage} />}
                    />
                    <Route
                      path={`${Path.architectures.path}/:clientId/:mode`}
                      element={<ProtectedRoute component={ArchitecturePage} />}
                    />
                    <Route
                      path={Path.authoring.path}
                      element={<ProtectedRoute component={AuthoringPage} />}
                    />
                    <Route
                      path={Path.authoringBeta.path}
                      element={<ProtectedRoute component={AuthoringPageBeta} />}
                    />
                    <Route
                      path={Path.sourcing.path}
                      element={<ProtectedRoute component={SourcingPage} />}
                    />
                    <Route
                      path={Path.topics.path}
                      element={<ProtectedRoute component={Topics} />}
                    />
                    <Route
                      path={Path.questionGenerator.path}
                      element={<ProtectedRoute component={JoQuestionsPage} />}
                    />
                    <Route
                      path={Path.quizzes.path}
                      element={<ProtectedRoute component={QuizReviewPage} />}
                    />
                    <Route
                      path={`${Path.quizzes.path}/create`}
                      element={<ProtectedRoute component={QuizCreatePage} />}
                    />
                    <Route
                      path={`${Path.quizzes.path}/:quizId`}
                      element={
                        <ProtectedRoute component={QuizReviewDetailsPage} />
                      }
                    />
                    <Route
                      path={Path.mediaPage.path}
                      element={<ProtectedRoute component={MediaPage} />}
                    />
                    <Route
                      path={Path.uploadGrades.path}
                      element={<ProtectedRoute component={UploadGrades} />}
                    />
                  </Routes>
                </AuthProvider>
              </ApiProvider>
            </ArchitectureApiProvider>
          </AppProvider>
        </Provider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  )
}

export default App
