import SidebarLayout from 'components/SidebarLayout'
import { Role, useAuth } from 'providers/AuthProvider'
import ErrorView from 'views/ErrorView'

function ErrorPage() {
  const { getRole } = useAuth()

  return (
    <SidebarLayout>{getRole() === Role.None && <ErrorView />}</SidebarLayout>
  )
}

export default ErrorPage
