import { ReactNode } from 'react'
import '../sidebar.css'
import SidebarMenu from './SidebarMenu'
import { Role, useAuth } from 'providers/AuthProvider'
import { useApp } from 'providers/AppProvider'
import ErrorView from 'views/ErrorView'
import menuLeft from 'images/menu-left.png'
import menuRight from 'images/menu-right.png'
import Spinner from 'views/Spinner'

interface Props {
  children: ReactNode
}

export default function SidebarLayout({ children }: Props): JSX.Element {
  const { getRole, mentemUser, auth0User, isLoadingMentemUser } = useAuth()
  const { isMinimized, setIsMinimized } = useApp()

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized)
  }

  const getMenuButtonStyle = () => {
    return isMinimized ? 'sidebar-button-right' : 'sidebar-button-left'
  }

  const getMenuButtonImage = () => {
    return isMinimized ? menuRight : menuLeft
  }

  const getMenuStyle = () => {
    return isMinimized ? 'sidebar-menu-minimized' : 'sidebar-menu'
  }

  const getContentStyle = () => {
    return isMinimized ? 'sidebar-content-minimized' : 'sidebar-content'
  }

  // console.log('SidebarLayout', getRole(), mentemUser)

  return (
    <div className="sidebar-container">
      <div className={getMenuStyle()}>
        <SidebarMenu isMinimized={isMinimized} />
      </div>
      <div className={getContentStyle()}>
        {isLoadingMentemUser && <Spinner />}
        {(mentemUser || auth0User) &&
          !isLoadingMentemUser &&
          getRole() === Role.None && <ErrorView />}
        {(mentemUser || auth0User) &&
          !isLoadingMentemUser &&
          getRole() !== Role.None &&
          children}
      </div>
      <div className={getMenuButtonStyle()}>
        <button onClick={toggleMinimize}>
          <img src={getMenuButtonImage()} alt="menu-button" />
        </button>
      </div>
    </div>
  )
}
