import { useState, useCallback, useRef } from 'react'
import { useApi } from 'providers/ApiProvider'
import { Tag } from 'apis/entities/tag.entity'
import { StyleUtil } from 'utils/StyleUtil'

interface AddTagsInputProps {
  onInputChanged: (value: string) => void
  refInupt: React.RefObject<HTMLInputElement>
  placeholder?: string
  style?: any
}

const AddTagsInput = (props: AddTagsInputProps) => {
  const refInput = props.refInupt
  const refDropDown = useRef<HTMLDivElement>(null)
  const [tags, setTags] = useState<Tag[]>([])
  const { getTagsByQuery } = useApi()
  const separator = ','

  const onInput = useCallback(
    async (e: any) => {
      const value = refInput.current?.value || ''
      props.onInputChanged(value)

      // check separator from input
      let query = value
      const words = value.split(separator)
      // console.log(words)
      if (words && words?.length > 1) {
        // pick the last one
        query = words[words.length - 1].trim()
      }

      if (query) {
        // console.log('onInput:', query)
        const data = await getTagsByQuery(query)
        if (data) {
          setTags(data)
          refDropDown.current!.hidden = data.length === 0
        }
      } else {
        refDropDown.current!.hidden = true
      }
    },
    [props, getTagsByQuery, refInput],
  )

  const onKeyUp = useCallback(async (e: any) => {
    if (e.key === 'Enter') {
      // console.log('onEnter')
      refDropDown.current!.hidden = true
    }
  }, [])

  const onClickTag = (tag: Tag) => {
    // console.log(tag)
    if (refInput.current !== null) {
      const words = refInput.current.value.split(separator)
      if (words.length === 0) return
      if (words.length === 1) {
        words[0] = tag.name
      } else {
        words[words.length - 1] = tag.name
      }
      // remove duplicates
      const newWords = [...new Map(words.map((item) => [item, item])).values()]
      refInput.current.value = `${newWords.join(separator)}${separator}`

      // hide dropdown
      refDropDown.current!.hidden = true
    }
  }

  const onMouseLeave = () => {
    refDropDown.current!.hidden = true
    setTags([])
  }

  const onMouseEnter = () => {
    refDropDown.current!.hidden = false
  }

  return (
    <div
      className="flex flex-col items-center justify-items-center"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      style={props.style}
    >
      <div className="w-full max-w-lg">
        <div className="relative mb-4">
          <label className="w-full">
            <input
              data-testid="input-add-tags"
              id="input-add-tags"
              ref={refInput}
              type="search"
              className={StyleUtil.inputSearch}
              placeholder={
                props.placeholder || `Create new subject(s) for image(s)`
              }
              aria-label="Search"
              aria-describedby="btn-search"
              onInput={onInput}
              onKeyUp={onKeyUp}
            />
          </label>
        </div>
      </div>
      <div
        ref={refDropDown}
        className={StyleUtil.dropDownSearch}
        aria-orientation="vertical"
        hidden={true}
      >
        <ul className="divide-slate-200 divide-y">
          {tags &&
            tags.map((tag, index) => {
              return (
                <li
                  key={index}
                  className="flex bg-white py-1 hover:bg-gray-100"
                  onClick={() => onClickTag(tag)}
                >
                  <p
                    className="block cursor-pointer px-4 py-2 text-sm text-gray-700"
                    id={tag.id}
                  >
                    {tag.name}
                  </p>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default AddTagsInput
