/* eslint-disable jsx-a11y/heading-has-content */

import { Image } from 'apis/entities/image.entity'
import { useEffect, useRef, useState } from 'react'
import { getFileSize } from 'utils/FileUtil'

interface ImageThumbnailProps {
  index: number
  image: Image
  checked?: boolean
  showFileSize?: boolean
  onClick?: (imageId: string, index: number) => void
  onCopy?: (url: string) => void
  onDelete?: (imageId: string) => void
  onSelect?: (imageId: string, checked: boolean) => void
}

export default function ImageThumbnail(props: ImageThumbnailProps) {
  const url = props.image.url
  const size = props.image.size
  const fileSize = getFileSize(size)
  const [showDescription, setShowDescription] = useState<boolean>(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const tooltipTimeoutRef = useRef<number | null>(null)

  const onMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      setMousePosition({ x: e.clientX - rect.left, y: e.clientY - rect.top })
    }
  }

  const onMouseEnter = () => {
    setShowTooltip(true)
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current)
    }
    tooltipTimeoutRef.current = window.setTimeout(() => {
      setShowTooltip(false)
    }, 10000000)
  }

  const onMouseLeave = () => {
    setShowTooltip(false)
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current)
    }
  }
  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current)
      }
    }
  }, [])
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLDivElement
    // console.log(target)
    if (target.dataset.id !== undefined) {
      if (props.onClick === undefined) return
      props.onClick(target.dataset.id, props.index)
    }
  }

  const onCopy = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLDivElement
    // console.log(target)
    if (target.dataset.url !== undefined) {
      if (props.onCopy === undefined) return
      props.onCopy(target.dataset.url)
    }
  }

  const onDelete = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLDivElement
    if (target.dataset.id !== undefined) {
      if (props.onDelete === undefined) return
      props.onDelete(target.dataset.id)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    // console.log(target.checked, target.dataset.id)
    if (props.onSelect === undefined || target.dataset.id === undefined) return
    props.onSelect(target.dataset.id, target.checked)
  }

  const onToggleShowDescription = (e: React.MouseEvent<HTMLElement>) => {
    setShowDescription(!showDescription)
  }

  return (
    <div
      className="relative w-full"
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={containerRef}
    >
      <article
        id="image-thumbnail"
        data-testid="image-thumbnail"
        tabIndex={0}
        className="hasImage focus:shadow-outline relative w-full rounded-xl bg-gray-100 text-transparent shadow-sm hover:text-white focus:outline-none"
      >
        <img
          alt={props.image.name}
          className="sticky aspect-[4/3] h-full w-full rounded-xl bg-fixed object-cover"
          src={url}
        />
        {showTooltip && props.image.description && (
          <div
            className="absolute rounded bg-white p-2 text-black shadow-lg"
            style={{
              top: mousePosition.y + 10,
              left: mousePosition.x + 10,
              zIndex: 1000,
            }}
          >
            {props.image.description}
          </div>
        )}
        {/* SuperAdmin */}
        {props.image.description && props.showFileSize === true && (
          <div className="image-desc-bg pointer-events-none absolute top-0 h-full w-full"></div>
        )}
        <section
          data-testid="section-image-thumbnail"
          data-id={props.image.id}
          className="absolute top-0 z-20 flex h-full w-full flex-col break-words rounded-xl py-1 text-xs"
        >
          {props.onClick && (
            <div
              className="absolute top-0 z-[-1] flex h-full w-full"
              data-id={props.image.id}
              onClick={onClick}
            ></div>
          )}
          <div className="m-1 flex items-center">
            {props.showFileSize === true && (
              <p
                data-testid="text-file-size"
                className="size ml-2 rounded-[3px] bg-white p-1 text-xs text-black"
              >
                {size >= 2 * 1048576 ? (
                  <span className="text-[red]">{fileSize}</span>
                ) : (
                  <span>{fileSize}</span>
                )}
              </p>
            )}
            {props.showFileSize === false && props.image.description && (
              <p
                onClick={onToggleShowDescription}
                className="size ml-1 cursor-pointer rounded-[30px] border-[1px] border-black bg-white p-[1px] pl-2 pr-2 text-[12px] font-bold text-black hover:bg-gray-100"
              >
                Show description
              </p>
            )}
            <div className="grow"></div>
            {props.onDelete && (
              <button
                data-testid="btn-delete"
                type="button"
                className="ml-auto mr-2 rounded-md p-1 hover:bg-gray-300 focus:outline-none"
                data-id={props.image.id}
                onClick={onDelete}
              >
                <svg
                  className="pointer-events-none ml-auto h-4 w-4 fill-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    className="pointer-events-none"
                    d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                  />
                </svg>
              </button>
            )}
            {props.onSelect && (
              <div className="ml-auto mr-1 flex rounded-md bg-white p-1 hover:bg-gray-100">
                <input
                  data-testid="checkbox-image-thumbnail"
                  type="checkbox"
                  data-id={props.image.id}
                  onChange={onChange}
                  checked={props.checked}
                />
              </div>
            )}
            {props.onCopy && (
              <button
                data-testid="btn-copy"
                type="button"
                className="ml-auto mr-1 rounded-md bg-white p-1 hover:bg-gray-100"
                data-url={url}
                onClick={onCopy}
              >
                <svg
                  className="pointer-events-none ml-auto h-[18px] w-[18px] fill-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                >
                  <path
                    fill="#3C415D"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9949 3.72998C13.9037 2.15517 12.5977 0.90625 11 0.90625H3L2.82373 0.911343C1.24892 1.00259 0 2.30857 0 3.90625V11.9062L0.00509269 12.0825C0.0963391 13.6573 1.40232 14.9062 3 14.9062H4V15.9062C4 17.5631 5.34315 18.9062 7 18.9062H15C16.6569 18.9062 18 17.5631 18 15.9062V7.90625C18 6.2494 16.6569 4.90625 15 4.90625H14V3.90625L13.9949 3.72998ZM12 4.90625V3.90625C12 3.39341 11.614 2.97074 11.1166 2.91298L11 2.90625H3C2.48716 2.90625 2.06449 3.29229 2.00673 3.78963L2 3.90625V11.9062C2 12.4191 2.38604 12.8418 2.88338 12.8995L3 12.9062H4V7.90625C4 6.2494 5.34315 4.90625 7 4.90625H12ZM6 13.9062V15.9062C6 16.4585 6.44772 16.9062 7 16.9062H15C15.5523 16.9062 16 16.4585 16 15.9062V7.90625C16 7.35397 15.5523 6.90625 15 6.90625H13H7C6.44772 6.90625 6 7.35397 6 7.90625V13.9062Z"
                  />
                </svg>
              </button>
            )}
          </div>
          {/* SuperAdmin */}
          {/*{props.image.description && props.showFileSize === true && (*/}
          {/*  <div*/}
          {/*    data-testid="text-description"*/}
          {/*    className="image-desc pointer-events-none absolute top-0 flex h-full w-full items-center justify-center px-6 pb-6 pt-10"*/}
          {/*  >*/}
          {/*    <span className="inline-flex h-full w-full items-center justify-center rounded-[6px] bg-white p-2 text-[16px] text-black">*/}
          {/*      {props.image.description}*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*)}*/}
        </section>
        {/* Author and Admin */}
        {showDescription && props.image.description && (
          <section
            data-testid="btn-show-description"
            onClick={onToggleShowDescription}
            className="absolute top-0 z-20 flex h-full w-full flex-col break-words rounded-xl bg-white bg-opacity-[0.8] py-1 text-xs"
          >
            <div className="z-99 absolute top-0 flex h-full w-full items-center justify-center px-4 py-4">
              <span className="inline-flex h-full w-full items-center justify-center rounded-[6px] bg-white p-2 text-[16px] text-black">
                {props.image.description}
              </span>
            </div>
          </section>
        )}
      </article>
    </div>
  )
}
