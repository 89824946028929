import { useCallback, useEffect, useRef, useState } from 'react'
import { ToastUtil } from 'utils/ToastUtil'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { ArchTopic } from 'apis/entities/architecture.entity'
import Emitter, { Events } from 'core/emitter'
import Spinner from './Spinner'

interface Props {
  topic: ArchTopic
  position: { x: number; y: number }
  onClose: () => void
}

export default function WidgetArchEditTopic({
  topic,
  position,
  onClose,
}: Props): JSX.Element {
  const { updateArchTopic } = useArchitectureApi()
  const [loading, setLoading] = useState(false)

  const [x, setX] = useState(position.x)
  const [y, setY] = useState(position.y)

  const refName = useRef<string>(topic.name)
  const refLink = useRef<string>(topic.link)

  const saveTopic = useCallback(async () => {
    try {
      setLoading(true)
      if (refName.current && refName.current.trim() === '') {
        ToastUtil.error('Name cannot be empty')
        return
      }
      // validate URL
      if (refLink.current && refLink.current.trim() !== '') {
        try {
          new URL(refLink.current)
        } catch (e) {
          ToastUtil.warning('Invalid URL')
          return
        }
      }

      const updatedTopic = await updateArchTopic(
        topic.id,
        refName.current,
        undefined,
        refLink.current,
      )
      ToastUtil.success('Topic updated')
      // emit event to update the topic
      const newArchTopic = {
        ...topic,
        name: refName.current,
        link: refLink.current,
        updatedAt: updatedTopic.updatedAt,
      }
      Emitter.emit(Events.UpdatedArchTopic, { topic: newArchTopic })
      // close the widget
      onClose()
    } catch (e) {
      console.error(e)
      ToastUtil.error('Failed to update topic')
    } finally {
      setLoading(false)
    }
  }, [onClose, topic, updateArchTopic])

  useEffect(() => {
    setX(position.x + 20)
    setY(position.y - 80)
  }, [position])

  return (
    <div
      className="w-full h-full absolute z-[999]"
      onClick={
        // click outside the widget to close
        (e) => {
          if (e.target === e.currentTarget) {
            onClose()
          }
        }
      }
    >
      {/* set the position */}
      <div
        className={`absolute w-[34%] h-fit`}
        style={{ top: `${y}px`, left: `${x}px` }}
      >
        <div className="h-full w-full flex flex-col bg-white shadow-xl rounded-[12px] border-[1px] border-[#D5D5DB] p-[12px]">
          <div className="flex flex-col items-center p-[12px]">
            {topic && (
              <div className="flex flex-col items-center gap-[12px] w-full">
                {/* Topic Name input */}
                <input
                  type="text"
                  className="arch-add-input w-full border-[1px] border-[#6B69C1] p-[8px]"
                  placeholder="Type topic name here"
                  defaultValue={topic.name}
                  onChange={(e) => {
                    refName.current = e.target.value
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      saveTopic()
                    }
                  }}
                  autoFocus
                />
                {/* Topic link */}
                <input
                  type="text"
                  className="arch-add-input w-full border-[1px] border-[#6B69C1] p-[8px]"
                  placeholder="Copy topic URL here"
                  defaultValue={topic.link}
                  onChange={(e) => {
                    refLink.current = e.target.value
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      saveTopic()
                    }
                  }}
                />
              </div>
            )}
          </div>
          {loading && <Spinner />}
        </div>
      </div>
    </div>
  )
}
