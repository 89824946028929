import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { ArchClient } from 'apis/entities/architecture.entity'
import { useRef, useState } from 'react'
import ButttonSpinner from './ButtonSpinner'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import Svg, { Icon, IconClass } from 'components/Svg'

interface WidgetArchManageClientProps {
  clients: ArchClient[]
  onClose: () => void
  onAdded: (client: ArchClient) => void
  onRenamed: (client: ArchClient) => void
}

export default function WidgetArchManageClient({
  clients,
  onClose,
  onAdded,
  onRenamed,
}: WidgetArchManageClientProps) {
  const { updateArchClient, createArchClient } = useArchitectureApi()
  const refAddClient = useRef<HTMLInputElement>(null)
  const [isAdding, setIsAdding] = useState(false)
  const [isUpdatingIds, setIsUpdatingIds] = useState<string[]>([])
  const [currentClients, setCurrentClients] = useState<ArchClient[]>(
    clients.sort((a, b) => a.name.localeCompare(b.name)),
  )

  const addClient = async () => {
    try {
      setIsAdding(true)
      const clientName = refAddClient.current?.value
      if (!clientName) {
        ToastUtil.warning('Client name is required')
        return
      }
      const client = await createArchClient(clientName)
      onAdded(client)

      const sortedClients = [...currentClients, client].sort((a, b) =>
        a.name.localeCompare(b.name),
      )

      // update the current clients
      setCurrentClients(sortedClients)
      // clear the input text
      refAddClient.current!.value = ''
      ToastUtil.success('Added successfully')
    } catch (error) {
      console.error(error)
      ToastUtil.error('Could not add')
    } finally {
      setIsAdding(false)
    }
  }

  const renameClient = async (clientId: string) => {
    try {
      const clientName = (
        document.getElementById(`client-${clientId}`) as HTMLInputElement
      ).value
      setIsUpdatingIds([...isUpdatingIds, clientId])
      await updateArchClient(clientId, clientName)
      onRenamed({ id: clientId, name: clientName } as ArchClient)
      // update the current clients
      const sortedClients = currentClients
        .map((client) =>
          client.id === clientId ? { ...client, name: clientName } : client,
        )
        .sort((a, b) => a.name.localeCompare(b.name))

      setCurrentClients(sortedClients)
      ToastUtil.success('Renamed successfully')
    } catch (error) {
      console.error(error)
      ToastUtil.error('Could not rename')
    } finally {
      setIsUpdatingIds(isUpdatingIds.filter((id) => id !== clientId))
    }
  }

  // on input text chaange, change the color of the input text
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if empty, change the color to #92929d, otherwise #000000
    e.target.style.color = e.target.value ? '#000000' : '#92929d'
  }

  return (
    <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex items-center justify-center">
      <div className="h-auto w-1/2 min-w-[577px] flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px] pb-[36px]">
        <div className="flex flex-row">
          <div className="grow" />
          <div className="self-end cursor-pointer" onClick={onClose}>
            <Svg icon={Icon.Close} className={IconClass.FillBlack2} />
          </div>
        </div>
        <div className="flex flex-col gap-[12px] px-[24px] max-h-[70vh] overflow-auto mentem-scrollbar-2">
          <div className="flex flex-row gap-[36px] items-center">
            <input
              type="text"
              placeholder="Client name"
              disabled={isAdding}
              ref={refAddClient}
              className="arch-add-client-input-text"
              onChange={onInputChange}
            />
            <button
              className={`${StyleUtil.buttonSecondary} min-w-[130px]`}
              onClick={addClient}
            >
              {isAdding ? <ButttonSpinner /> : 'Add client'}
            </button>
          </div>
          {currentClients.map((client) => (
            <div
              key={client.id}
              className="flex flex-row gap-[36px] items-center"
            >
              <input
                id={`client-${client.id}`}
                type="text"
                defaultValue={client.name}
                placeholder="Client name"
                className="arch-client-input-text"
                onChange={onInputChange}
              />
              <button
                className={`${StyleUtil.buttonPrimary} min-w-[130px]`}
                onClick={() => renameClient(client.id)}
              >
                {isUpdatingIds.includes(client.id) ? (
                  <ButttonSpinner />
                ) : (
                  'Rename client'
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
