import SidebarLayout from 'components/SidebarLayout'
import { Role, useAuth } from 'providers/AuthProvider'
import { StyleUtil } from 'utils/StyleUtil'
import ArchReadOnlyView from 'views/ArchReadOnlyView'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { ToastUtil } from 'utils/ToastUtil'
import {
  ArchClient,
  ArchCourseForTable,
  ArchIntake,
  ArchProgram,
  ArchTopic,
} from 'apis/entities/architecture.entity'
import DropDownMenu, {
  DropDownItem,
  DropDownMenuRefType,
} from 'views/DropDownMenu'
import WidgetArchCourseDetailsView from 'views/WidgetArchCourseDetailsView'
import Emitter, { Events } from 'core/emitter'
import ArchManageView from 'views/ArchManageView'
import WidgetArchManageClient from 'views/WidgetArchManageClient'
import WidgetArchCopy from 'views/WidgetArchCopy'
import { ArchActionType, sortIntakeOptionsByCreatedAt } from 'utils/ArchUtil'
import WidgetArchEditTopic from 'views/WidgetArchEditTopic'
import WidgetArchUpload from 'views/WidgetArchUpload'
import { useNavigate, useParams } from 'react-router-dom'

type FilteredCourseDict = {
  [clientId: string]: ArchCourseForTable
}

const ClientOptionShowAll = {
  id: 'all',
  name: 'All clients',
  value: 'all',
  isLabel: false,
}

const ProgramOptionShowAll = {
  id: 'all',
  name: 'All programs',
  value: 'all',
  isLabel: false,
}

const IntakeOptionShowAll = {
  id: 'all',
  name: 'All intakes',
  value: 'all',
  isLabel: false,
}

function ArchitecturePage() {
  const navigate = useNavigate()
  const { clientId, mode } = useParams()
  const refCurrentClientId = useRef<string | undefined>(clientId)
  // console.log('clientId', clientId)
  // console.log('mode', mode)
  const { isLogged, getRole } = useAuth()
  const { getArchClients, getArchForTable, getArchClient } =
    useArchitectureApi()
  const [isFetchingClients, setIsFetchingClients] = useState(false)
  const [isFetchingArchitectures, setIsFetchingArchitectures] = useState(false)
  const [clients, setClients] = useState<ArchClient[]>([])
  const [selectedClient, setSelectedClient] = useState<ArchClient | null>(null)
  const [selectedProgram, setSelectedProgram] = useState<ArchProgram | null>(
    null,
  )
  const [selectedIntake, setSelectedIntake] = useState<ArchIntake | null>(null)

  const refSelectedClient = useRef<ArchClient | null>(null)
  const refSelectedProgram = useRef<ArchProgram | null>(null)
  const refSelectedIntake = useRef<ArchIntake | null>(null)

  const [clientOptions, setClientOptions] = useState<any>([ClientOptionShowAll])
  const [programOptions, setProgramOptions] = useState<any>([
    ProgramOptionShowAll,
  ])
  const [intakeOptions, setIntakeOptions] = useState<any>([IntakeOptionShowAll])
  const isAdmin = getRole() === Role.Admin || getRole() === Role.SuperAdmin

  const [courses, setCourses] = useState<ArchCourseForTable[]>([])
  const refCourses = useRef<ArchCourseForTable[]>([]) // for cache
  const refFilteredCourses = useRef<FilteredCourseDict>({}) // for cache
  const [showCourseDetails, setShowCourseDetails] = useState(false)
  const [courseDetails, setCourseDetails] = useState<ArchCourseForTable | null>(
    null,
  )

  const [showManageClient, setShowManageClient] = useState(false)

  enum AdminMenu {
    View = 'view',
    Manage = 'manage',
  }

  const [selectedAdminMenu, setSelectedAdminMenu] = useState<AdminMenu>(
    mode === AdminMenu.Manage ? AdminMenu.Manage : AdminMenu.View,
  )

  const [showWidgetArchCopy, setShowWidgetArchCopy] = useState(false)
  // ids for copy, program and intake are single, course, module, topic are multiple
  const [copyProgramId, setCopyProgramId] = useState<string | undefined>(
    undefined,
  )
  const [copyIntakeId, setCopyIntakeId] = useState<string | undefined>(
    undefined,
  )
  const [copyCourseIds, setCopyCourseIds] = useState<string[] | undefined>(
    undefined,
  )
  const [copyModuleIds, setCopyModuleIds] = useState<string[] | undefined>(
    undefined,
  )
  const [copyTopicIds, setCopyTopicIds] = useState<string[] | undefined>(
    undefined,
  )

  const [showWidgetEditTopic, setShowWidgetEditTopic] = useState(false)
  const [editTopic, setEditTopic] = useState<ArchTopic | undefined>(undefined)
  const [editTopicPosition, setEditTopicPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined)

  const [showWidgetUpload, setShowWidgetUpload] = useState(false)
  const [uploadInakeId, setUploadIntakeId] = useState<string | undefined>(
    undefined,
  )

  // ref for dropdown menu
  const refDropDownMenuClients = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuPrograms = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuIntakes = useRef<DropDownMenuRefType>(null)

  const setCoursesForTable = (courses: ArchCourseForTable[]) => {
    // flatten courses
    setCourses(courses)
  }

  const updateProgramOptions = () => {
    // set all program options by distinct program names
    const tmpProgramOptions: any[] = []

    // all clients
    if (refSelectedClient.current === null) {
      let allCourses = refCourses.current
      allCourses.forEach((course) => {
        if (!tmpProgramOptions.find((p) => p.name === course.programName)) {
          tmpProgramOptions.push({
            id: course.programId,
            name: course.programName,
            value: course.programId,
          })
        }
      })
    } else {
      // filter by selected client, use selected client's object
      const programs = refSelectedClient.current.programs
      programs.forEach((program) => {
        if (!tmpProgramOptions.find((p) => p.name === program.name)) {
          tmpProgramOptions.push({
            id: program.id,
            name: program.name,
            value: program.id,
          })
        }
      })
    }
    // console.log(refSelectedClient.current)
    // console.log('tmpProgramOptions', tmpProgramOptions)

    // sort program options by alphabet ascending
    tmpProgramOptions.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    setProgramOptions([ProgramOptionShowAll, ...tmpProgramOptions])
  }

  const updateIntakeOptions = () => {
    // set all intake options by distinct intake names
    const tmpIntakeOptions: any[] = []

    // all clients
    if (refSelectedClient.current === null) {
      let allCourses = refCourses.current
      allCourses.forEach((course) => {
        if (!tmpIntakeOptions.find((i) => i.name === course.intakeName)) {
          tmpIntakeOptions.push({
            id: course.intakeId,
            name: course.intakeName,
            value: course.intakeId,
            createdAt: course.intakeCreatedAt,
          })
        }
      })
    } else {
      // filter by selected client, use selected client's object
      const programs = refSelectedClient.current.programs
      programs.forEach((program) => {
        program.intakes.forEach((intake) => {
          if (!tmpIntakeOptions.find((i) => i.name === intake.name)) {
            tmpIntakeOptions.push({
              id: intake.id,
              name: intake.name,
              value: intake.id,
              createdAt: intake.createdAt,
            })
          }
        })
      })
    }

    // sort intake options by date first, otherwise by alphabet ascending
    const sortedTmpIntakeOptions =
      sortIntakeOptionsByCreatedAt(tmpIntakeOptions)

    // console.log('sortedTmpIntakeOptions', sortedTmpIntakeOptions)
    setIntakeOptions([IntakeOptionShowAll, ...sortedTmpIntakeOptions])
  }

  const fetchArchitectures = useCallback(
    async (silent: boolean = false) => {
      if (!silent) {
        setIsFetchingArchitectures(true)
      }
      try {
        const res = await getArchForTable()
        if (res && res.courses) {
          // only set one course per client to dict by default
          const tmpFilteredCourses: FilteredCourseDict = {}
          res.courses.forEach((course) => {
            if (!tmpFilteredCourses[course.clientId]) {
              tmpFilteredCourses[course.clientId] = course
            }
          })
          refFilteredCourses.current = tmpFilteredCourses
          // for cache
          refCourses.current = res.courses
          setCoursesForTable(res.courses)
        }
      } catch (error) {
        ToastUtil.error('Failed to fetch architectures')
        refCourses.current = []
      } finally {
        setIsFetchingArchitectures(false)
      }
    },
    [getArchForTable],
  )

  const fetchReloadedClient = useCallback(
    async (targetClientId: string) => {
      try {
        // console.log('currentClientId', refCurrentClientId.current)
        // console.log('targetClientId', targetClientId)
        const currentClientId = refCurrentClientId.current
        if (!currentClientId) {
          return null
        }
        const reloadedClient = await getArchClient(targetClientId)
        // update selected client if it's the same
        if (currentClientId === targetClientId) {
          // console.log('reloadedClient', reloadedClient)
          refSelectedClient.current = reloadedClient
        }
        // console.log('client', client)
        // update clients
        const tmpClients = clients.map((c) => {
          if (c.id === targetClientId) {
            return reloadedClient
          }
          return c
        })
        // console.log('tmpClients', tmpClients)
        setClients(tmpClients)
        updateProgramOptions()
        updateIntakeOptions()
      } catch (error) {
        ToastUtil.error('Failed to fetch client')
        return null
      }
    },
    [clients, refCurrentClientId, getArchClient],
  )

  const fetchClients = useCallback(
    async (silent: boolean = false) => {
      if (!silent) {
        setIsFetchingClients(true)
      }
      try {
        const clients = await getArchClients()
        setClients(clients)

        // Set client options
        const allOptions = clients.map((client) => ({
          id: client.id,
          name: client.name,
          value: client.id,
          default: client.id === refCurrentClientId.current,
        }))

        // sort by alphabet
        allOptions.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })

        setClientOptions([ClientOptionShowAll, ...allOptions])

        await fetchArchitectures(silent)

        // console.log('clients', clients)
        // pre-select client if clientId is provided
        const currentClientId = refCurrentClientId.current
        if (currentClientId) {
          const client = clients.find((client) => client.id === currentClientId)
          // console.log('client', client)
          if (client) {
            refSelectedClient.current = client
            if (!silent) {
              setSelectedClient(client)
            }
            filterTable(client.id)
          }
        }
        updateProgramOptions()
        updateIntakeOptions()
      } catch (error) {
        ToastUtil.error('Failed to fetch clients')
      } finally {
        setIsFetchingClients(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refCurrentClientId, getArchClients],
  )

  const filterTable = (clientId: string) => {
    const tmpCourses = refCourses.current.filter(
      (course) => course.clientId === clientId,
    )
    setCoursesForTable(tmpCourses)
  }

  const onSelectClient = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      // label
      return
    } else if (value === 'all') {
      setSelectedClient(null)
      refSelectedClient.current = null

      // change the last path of url
      window.history.replaceState(null, '', `/architectures`)
      // go back to view mode
      setSelectedAdminMenu(AdminMenu.View)
      // reset courses
      setCoursesForTable(refCourses.current)

      // reset selected program and intake
      setSelectedProgram(null)
      refSelectedProgram.current = null
      setSelectedIntake(null)
      refSelectedIntake.current = null

      // reset dropdowns
      refDropDownMenuPrograms.current?.setSelectedItemId(
        ProgramOptionShowAll.id,
      )
      refDropDownMenuIntakes.current?.setSelectedItemId(IntakeOptionShowAll.id)
    } else {
      // selected client
      const client = clients.find((client) => client.id === value)
      // console.log('client', client)
      if (client) {
        refSelectedClient.current = client
        setSelectedClient(client)
        filterTable(client.id)

        // reset selected program and intake
        setSelectedProgram(null)
        refSelectedProgram.current = null
        setSelectedIntake(null)
        refSelectedIntake.current = null
      }
    }

    updateProgramOptions()
    updateIntakeOptions()
    updateCourses()

    // change the last path of url with client id silently
    if (value !== 'all') {
      const mode = selectedAdminMenu === AdminMenu.Manage ? '/manage' : ''
      window.history.replaceState(null, '', `/architectures/${value}${mode}`)
      refCurrentClientId.current = value
    }
  }

  const updateCourses = () => {
    // filter courses by selected client, program, or intake
    const clientId = refSelectedClient.current?.id
    const programName = refSelectedProgram.current?.name
    const intakeName = refSelectedIntake.current?.name
    // console.log('updateCourses')
    // console.log('clientId:', clientId)
    // console.log('programName:', programName)
    // console.log('intakeName:', intakeName)

    const allCourses = refCourses.current
    let tmpCourses: ArchCourseForTable[] = []
    if (clientId && programName && intakeName) {
      tmpCourses = allCourses.filter(
        (course) =>
          course.clientId === clientId &&
          course.programName === programName &&
          course.intakeName === intakeName,
      )
    } else if (clientId && programName) {
      tmpCourses = allCourses.filter(
        (course) =>
          course.clientId === clientId && course.programName === programName,
      )
    } else if (clientId && intakeName) {
      tmpCourses = allCourses.filter(
        (course) =>
          course.clientId === clientId && course.intakeName === intakeName,
      )
    } else if (clientId) {
      tmpCourses = allCourses.filter((course) => course.clientId === clientId)
    } else if (programName) {
      tmpCourses = allCourses.filter(
        (course) => course.programName === programName,
      )
    } else if (intakeName) {
      tmpCourses = allCourses.filter(
        (course) => course.intakeName === intakeName,
      )
    } else {
      tmpCourses = allCourses
    }
    // console.log('tmpCourses', tmpCourses)
    setCoursesForTable(tmpCourses)
  }

  const onSelectProgram = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      return
    } else if (value === 'all') {
      setSelectedProgram(null)
      refSelectedProgram.current = null
    } else {
      const program = { id: item.id, name: item.name } as ArchProgram
      setSelectedProgram(program)
      refSelectedProgram.current = program
    }

    // reset intake options
    setSelectedIntake(null)
    refSelectedIntake.current = null
    // reset dropdown
    refDropDownMenuIntakes.current?.setSelectedItemId(IntakeOptionShowAll.id)

    updateCourses()
  }

  const onSelectIntake = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      return
    } else if (value === 'all') {
      setSelectedIntake(null)
      refSelectedIntake.current = null
    } else {
      const intake = { id: item.id, name: item.name } as ArchIntake
      setSelectedIntake(intake)
      refSelectedIntake.current = intake
    }
    updateCourses()
  }

  const renderSelectedOptions = () => {
    const words: string[] = []
    if (selectedClient) {
      words.push(selectedClient.name)
    } else {
      words.push('Client')
    }
    if (selectedProgram) {
      words.push(selectedProgram.name)
    } else {
      words.push('Program')
    }
    if (selectedIntake) {
      words.push(selectedIntake.name)
    } else {
      words.push('Intake')
    }
    words.push('Course')
    let text = words.join('/')
    return <p className="arch-subheading">{text}</p>
  }

  const onShowCourseDetails = (data: { course: ArchCourseForTable }) => {
    setCourseDetails(data.course)
    setShowCourseDetails(true)
  }

  const onShowCopy = (data: { type: ArchActionType; ids: string[] }) => {
    // reset copy ids
    setCopyProgramId(undefined)
    setCopyIntakeId(undefined)
    setCopyCourseIds(undefined)
    setCopyModuleIds(undefined)
    setCopyTopicIds(undefined)

    switch (data.type) {
      case ArchActionType.Program:
        setCopyProgramId(data.ids[0])
        break
      case ArchActionType.Intake:
        setCopyIntakeId(data.ids[0])
        break
      case ArchActionType.Course:
        setCopyCourseIds(data.ids)
        break
      case ArchActionType.Module:
        setCopyModuleIds(data.ids)
        break
      case ArchActionType.Topic:
        setCopyTopicIds(data.ids)
        break
    }
    setShowWidgetArchCopy(true)
  }

  const onShowEditTopic = (data: {
    topic: ArchTopic
    position: { x: number; y: number }
  }) => {
    setEditTopic(data.topic)
    setEditTopicPosition(data.position)
    setShowWidgetEditTopic(true)
  }

  const onClickManageClient = () => {
    setShowManageClient(true)
  }

  const onAddedClient = (client: ArchClient) => {
    setClients([...clients, client])
    setClientOptions([
      ...clientOptions,
      { id: client.id, name: client.name, value: client.id },
    ])
  }

  const onRenamedClient = (client: ArchClient) => {
    // emit event to update client name in other components
    Emitter.emit(Events.RenamedArchClient, { client })

    const tmpClients = clients.map((c) => {
      if (c.id === client.id) {
        return client
      }
      return c
    })
    setClients(tmpClients)
    setClientOptions(
      clientOptions.map((option: any) => {
        if (option.id === client.id) {
          return { id: client.id, name: client.name, value: client.id }
        }
        return option
      }),
    )

    // update current courses for renamed client
    setCoursesForTable(
      courses.map((course) => {
        if (course.clientId === client.id) {
          return {
            ...course,
            clientName: client.name,
          }
        }
        return course
      }),
    )
  }

  const onShowUploadExcel = (data: { intakeId: string }) => {
    setShowWidgetUpload(true)
    setUploadIntakeId(data.intakeId)
  }

  const onReloadClient = useCallback(
    async (data: { clientId: string; updateCourseTable: boolean }) => {
      // console.log('onReloadClient', data)
      await fetchReloadedClient(data.clientId)
      if (data.updateCourseTable === true) {
        await fetchArchitectures(true)
      }
    },
    [fetchArchitectures, fetchReloadedClient],
  )

  useEffect(() => {
    if (isLogged) {
      fetchClients()
    }
  }, [isLogged, fetchClients])

  useEffect(() => {
    Emitter.on(Events.ShowArchCourseDetails, onShowCourseDetails)
    Emitter.on(Events.ShowArchCopy, onShowCopy)
    Emitter.on(Events.ShowArchEditTopic, onShowEditTopic)
    Emitter.on(Events.UploadArchExcel, onShowUploadExcel)
    Emitter.on(Events.ReloadArchClient, onReloadClient)
    return () => {
      Emitter.off(Events.ShowArchCourseDetails, onShowCourseDetails)
      Emitter.off(Events.ShowArchCopy, onShowCopy)
      Emitter.off(Events.ShowArchEditTopic, onShowEditTopic)
      Emitter.off(Events.UploadArchExcel, onShowUploadExcel)
      Emitter.off(Events.ReloadArchClient, onReloadClient)
    }
  }, [onReloadClient])

  return (
    <>
      <SidebarLayout>
        <div className={StyleUtil.containerNoScroll}>
          <div className="px-24 h-full">
            <p className={StyleUtil.headline}>Architectures</p>
            <div className="mt-8 flex flex-col gap-[24px] h-full">
              <div className="flex flex-row">
                {renderSelectedOptions()}
                {isAdmin && (
                  <>
                    <div className="grow" />
                    <button
                      className={StyleUtil.buttonPrimary}
                      onClick={onClickManageClient}
                    >
                      Edit client(s)
                    </button>
                  </>
                )}
              </div>
              {!isFetchingClients &&
                !isFetchingArchitectures &&
                clients.length > 0 && (
                  <div className="flex flex-row items-center gap-[24px] pb-[12px]">
                    <div className="w-[20%]">
                      <DropDownMenu
                        items={clientOptions}
                        onSelected={onSelectClient}
                        border={true}
                        ref={refDropDownMenuClients}
                      />
                    </div>
                    <div className="w-[60%]">
                      <DropDownMenu
                        items={programOptions}
                        onSelected={onSelectProgram}
                        border={true}
                        ref={refDropDownMenuPrograms}
                      />
                    </div>
                    <div className="w-[20%]">
                      <DropDownMenu
                        items={intakeOptions}
                        onSelected={onSelectIntake}
                        border={true}
                        ref={refDropDownMenuIntakes}
                      />
                    </div>
                  </div>
                )}
              {selectedAdminMenu === AdminMenu.View && (
                <ArchReadOnlyView
                  courses={courses}
                  allCourses={refCourses.current}
                  isLoading={isFetchingClients || isFetchingArchitectures}
                  filteredProgramName={selectedProgram?.name}
                  filteredIntakeName={selectedIntake?.name}
                />
              )}
              {isAdmin && selectedAdminMenu === AdminMenu.Manage && (
                <ArchManageView
                  client={selectedClient}
                  isLoading={isFetchingClients || isFetchingArchitectures}
                  filteredProgramName={selectedProgram?.name}
                  filteredIntakeName={selectedIntake?.name}
                  onUpdateProgram={() => {
                    fetchClients(true)
                  }}
                  onUpdateIntake={() => {
                    fetchClients(true)
                  }}
                />
              )}
              {isAdmin && selectedClient && (
                <div className="flex flex-row">
                  <div className="grow" />
                  <button
                    className={StyleUtil.buttonPrimary}
                    onClick={() => {
                      if (selectedClient) {
                        if (selectedAdminMenu === AdminMenu.View) {
                          navigate(`/architectures/${selectedClient.id}/manage`)
                        } else {
                          // save and exit
                          Emitter.emit(Events.ArchSaveAndExit, {})
                          navigate(`/architectures/${selectedClient.id}`)
                        }
                      } else {
                        navigate(`/architectures`)
                      }

                      // toggle admin menu
                      setSelectedAdminMenu(
                        selectedAdminMenu === AdminMenu.View
                          ? AdminMenu.Manage
                          : AdminMenu.View,
                      )
                    }}
                  >
                    {selectedAdminMenu === AdminMenu.View
                      ? 'Manage'
                      : 'Save and exit'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </SidebarLayout>
      {showCourseDetails && courseDetails && (
        <WidgetArchCourseDetailsView
          course={courseDetails}
          onClose={() => setShowCourseDetails(false)}
        />
      )}
      {/* Widgets */}
      {showManageClient && (
        <WidgetArchManageClient
          clients={clients}
          onClose={() => setShowManageClient(false)}
          onAdded={onAddedClient}
          onRenamed={onRenamedClient}
        />
      )}
      {showWidgetArchCopy && (
        <WidgetArchCopy
          programId={copyProgramId}
          intakeId={copyIntakeId}
          courseIds={copyCourseIds}
          moduleIds={copyModuleIds}
          topicIds={copyTopicIds}
          onClose={() => {
            setShowWidgetArchCopy(false)
          }}
        />
      )}
      {showWidgetEditTopic && editTopic && editTopicPosition && (
        <WidgetArchEditTopic
          topic={editTopic}
          position={editTopicPosition}
          onClose={() => {
            setShowWidgetEditTopic(false)
          }}
        />
      )}
      {showWidgetUpload && uploadInakeId && (
        <WidgetArchUpload
          intakeId={uploadInakeId}
          placeholder="Drag Excel here to upload"
          onClose={() => {
            setShowWidgetUpload(false)
          }}
        />
      )}
    </>
  )
}

export default ArchitecturePage
