/* eslint-disable jsx-a11y/heading-has-content */
import {
  getExtension,
  getExtensionIcon,
  getFilenameWithoutExtension,
  getFileSize,
} from 'utils/FileUtil'
import { useState } from 'react'
import { StyleUtil } from '../utils/StyleUtil'
import { FileObject } from 'apis/entities/file.entity'

interface FilePreivewProps {
  fileObject: FileObject
  onDelete: (id: string) => void
  onNameChange: (id: string, newName: string) => void
}

export default function FilePreview(props: FilePreivewProps) {
  const objectURL = props.fileObject.objectURL
  const file = props.fileObject.file
  const fileSize = getFileSize(file.size)
  const [newFileName, setNewFileName] = useState(file.name)
  // split into 2 lines
  let fileExtension = getExtension(file.name)
  if (fileExtension !== '') {
    fileExtension = '.' + fileExtension
  }
  const onDelete = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLButtonElement
    if (target.dataset.id !== undefined) {
      props.onDelete(target.dataset.id)
    }
  }

  const iconClazz = 'w-[70px] mr-2'
  // 'sticky aspect-[4/3] h-full w-full rounded-md bg-fixed object-cover'
  return (
    // <li
    //   id={objectURL}
    //   className="block w-full p-1 pt-[36px]"
    // >
    <article
      tabIndex={0}
      className="imagePreview focus:shadow-outline relative w-full text-black focus:outline-none"
    >
      <div className="flex">
        <div className="flex-none">
          {[
            '.jpg',
            '.jpeg',
            '.png',
            '.gif',
            '.bmp',
            '.tif',
            '.tiff',
            '.psd',
            '.raw',
            '.svg',
            '.ico',
            '.webp',
          ].includes(fileExtension) ? (
            <img alt={file.name} className={iconClazz} src={objectURL} />
          ) : (
            <img
              alt={file.name}
              className={iconClazz}
              src={getExtensionIcon(file.name)}
            />
          )}
        </div>
        <span className="flex-1">
          <p className="size pl-1 text-xs">name: {fileSize}</p>
          <p className="size pl-1 text-xs">size: {file.name}</p>
          <input
            type="text"
            value={newFileName}
            className={StyleUtil.inputSearch}
            onChange={(e) => {
              const name = getFilenameWithoutExtension(e.target.value)
              setNewFileName(name + fileExtension)
              props.onNameChange(objectURL, name + fileExtension)
            }}
          />
          <button
            type="button"
            title="btn-delete"
            className="delete float-right mt-3 rounded-md hover:bg-gray-300 focus:outline-none"
            data-id={objectURL}
            onClick={onDelete}
          >
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="pointer-events-none"
            >
              <path
                d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </div>
      <div className="flex justify-between"></div>
    </article>
    // </li>
  )
}
