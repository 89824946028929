import { useState, useCallback, useRef } from 'react'
import { useApi } from 'providers/ApiProvider'
import { Tag } from 'apis/entities/tag.entity'
import { StyleUtil } from 'utils/StyleUtil'

interface SearchTagsInputProps {
  onInputChanged: (value: string) => void
  onEnter: () => void
  onFocus?: () => void
  onBlur?: () => void
  isSearching: boolean
  autoComplete?: boolean
}

const SearchTagsInput = (props: SearchTagsInputProps) => {
  const refInput = useRef<HTMLInputElement>(null)
  const refDropDown = useRef<HTMLDivElement>(null)
  const [tags, setTags] = useState<Tag[]>([])
  const { getTagsByQuery } = useApi()
  const separator = ','

  const onInput = useCallback(
    async (e: any) => {
      const value = refInput.current?.value || ''
      props.onInputChanged(value)

      if (props.autoComplete === undefined || props.autoComplete === false)
        return

      // check separator from input
      let query = value
      const words = value.split(separator)
      // console.log(words)
      if (words && words?.length > 1) {
        // pick the last one
        query = words[words.length - 1].trim()
      }

      if (query) {
        // console.log('onInput:', query)
        const data = await getTagsByQuery(query)
        if (data && props.isSearching === false) {
          setTags(data)
          if (refDropDown.current !== null) {
            refDropDown.current!.hidden = data.length === 0
          }
        }
      } else {
        refDropDown.current!.hidden = true
      }
    },
    [props, getTagsByQuery],
  )

  const onKeyUp = useCallback(
    async (e: any) => {
      if (e.key === 'Enter') {
        // console.log('onEnter')
        refDropDown.current!.hidden = true
        // if (value.length > 0) {
        props.onEnter()
        refDropDown.current!.hidden = true
        // }
      }
    },
    [props],
  )

  const onFocus = useCallback(
    (e: any) => {
      // console.log('onFocus')
      props.onFocus?.()
    },
    [props],
  )

  const onBlur = useCallback(
    (e: any) => {
      // console.log('onBlur')
      props.onBlur?.()
    },
    [props],
  )

  const onClickTag = (tag: Tag) => {
    // console.log(tag)
    if (refInput.current !== null) {
      const words = refInput.current.value.split(separator)
      if (words.length === 0) return
      if (words.length === 1) {
        words[0] = tag.name
      } else {
        words[words.length - 1] = tag.name
      }
      // remove duplicates
      const newWords = [...new Map(words.map((item) => [item, item])).values()]
      refInput.current.value = `${newWords.join(separator)}${separator}`
      props.onInputChanged(refInput.current.value)
      // hide dropdown
      refDropDown.current!.hidden = true
    }
  }

  const onMouseLeave = () => {
    if (props.autoComplete === undefined || props.autoComplete === false) return
    refDropDown.current!.hidden = true
    setTags([])
  }

  const onMouseEnter = () => {
    if (props.autoComplete === undefined || props.autoComplete === false) return
    refDropDown.current!.hidden = false
  }

  return (
    <div
      className="flex flex-col items-start justify-items-start"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <div className="w-full max-w-lg">
        <div className="relative">
          <label className="w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="lightgray"
              className="pointer-events-none absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform"
            >
              <path
                fillRule="evenodd"
                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                clipRule="evenodd"
              />
            </svg>
            <input
              data-testid="input-search"
              ref={refInput}
              type="search"
              className={StyleUtil.inputSearchWithIcon}
              placeholder="Filter by key words here"
              aria-label="Search"
              aria-describedby="btn-search"
              onInput={onInput}
              onKeyUp={onKeyUp}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </label>
        </div>
      </div>
      <div
        ref={refDropDown}
        className={StyleUtil.dropDownSearch}
        aria-orientation="vertical"
        hidden={true}
      >
        <ul className="divide-slate-200 divide-y">
          {tags &&
            tags.map((tag, index) => {
              return (
                <li
                  key={index}
                  className="flex bg-white py-1 hover:bg-gray-100"
                  onClick={() => onClickTag(tag)}
                >
                  <p
                    className="block cursor-pointer px-4 py-2 text-sm text-gray-700"
                    id={tag.id}
                  >
                    {tag.name}
                  </p>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default SearchTagsInput
