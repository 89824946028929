import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { isDev, isPrd } from 'utils/EnvUtil'

// Disable react-beautiful-dnd dev warnings
;(window as any)['__react-beautiful-dnd-disable-dev-warnings'] = true

// init Sentry
if (isDev || isPrd) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 0.1,
    environment: process.env.REACT_APP_ENV,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
