import { DocumentUser } from 'apis/entities/document.entity'
import SidebarLayout from 'components/SidebarLayout'
import { useApi } from 'providers/ApiProvider'
import { Permission, Role, useAuth } from 'providers/AuthProvider'
import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import DropDownMenu, { DropDownItem } from 'views/DropDownMenu'
import SearchInput from 'views/SearchInput'
import Spinner from 'views/Spinner'
import copySvg from 'images/copy.svg'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import { Tooltip } from 'react-tooltip'
import * as Sentry from '@sentry/react'

import { ReactComponent as FcImagesSvg } from 'images/file-catalogue-images.svg'
import { ReactComponent as FcDocumentsSvg } from 'images/file-catalogue-documents.svg'
import { ReactComponent as FcPresentationsSvg } from 'images/file-catalogue-presentations.svg'
import { ReactComponent as FcSpreadsheetsSvg } from 'images/file-catalogue-spreadsheets.svg'
import { ReactComponent as FcAudioSvg } from 'images/file-catalogue-audios.svg'
import { ReactComponent as FcVideoSvg } from 'images/file-catalogue-videos.svg'
import { ReactComponent as FcOtherSvg } from 'images/file-catalogue-others.svg'

import FilePreview from '../views/FilePreview'
import { ButtonCopy } from 'components/ButtonCopy'
import { getExtensionIcon } from '../utils/FileUtil'
import { LxFile } from '../apis/entities/lx-file.entity'
import { getDateText, getTimeText } from 'utils/StringUtil'
import ImagesSection from './ImagesSection'
import menuRight from '../images/menu-right-alt.svg'
import menuLeft from '../images/menu-left-alt.svg'
import close2 from '../images/close2.svg'
import hasNotesSvg from '../images/has-notes.svg'
import noNotesSvg from '../images/no-notes.svg'

enum SortBy {
  PublishedBy = 'publishedUserName',
  DateTime = 'createdAt',
  Filename = 'name',
}

enum Section {
  None = 0,
  Search = 1,
  Upload = 2,
  ManageSection = 3,
  ImagesSection = 4,
}

type Direction = 'ascending' | 'descending' | ''

const publishedByOptionLabel = {
  id: '0',
  name: 'Published by',
  value: '0',
  isLabel: true,
}

const publishedByOptionShowAll = {
  id: '1',
  name: 'All authors',
  value: '',
  isLabel: false,
}

const tooltipStyle = {
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '6px',
}

const tooltipStyleReverse = {
  backgroundColor: '#6b69c1',
  color: '#fff',
  borderRadius: '6px',
}
interface UploadedLxFile {
  url: string
  filename: string
}
interface LxFileContainer {
  objectURL: string
  filenameOverride: string
  file: File
}

const NotesModal = ({
  isOpen,
  onClose,
  initialNotes,
  onSave,
}: // onFilenameSave,
{
  isOpen: boolean
  onClose: () => void
  initialNotes: string
  onSave: (notes: string) => void
}) => {
  const [notes, setNotes] = useState(initialNotes)

  const handleSave = () => {
    onSave(notes)
    onClose()
  }

  if (!isOpen) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(255,255,255,0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: 'relative', // For absolute positioning of the close button
          backgroundColor: '#fff',
          padding: '40px 24px 12px 24px',
          borderRadius: '6px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          boxShadow: `0px 381px 107px 0px rgba(0, 0, 0, 0.00), 
            0px 244px 98px 0px rgba(0, 0, 0, 0.01), 
            0px 137px 82px 0px rgba(0, 0, 0, 0.05), 
            0px 61px 61px 0px rgba(0, 0, 0, 0.09), 
            0px 15px 34px 0px rgba(0, 0, 0, 0.10)`,
        }}
      >
        <div
          style={{
            position: 'absolute', // Position the close button
            top: '20px', // Slightly down from the top edge
            right: '20px', // Slightly in from the right edge
            cursor: 'pointer', // Change cursor to pointer
          }}
          onClick={onClose} // Call onClose when the close button is clicked
        >
          <img src={close2} alt="Close" />
        </div>

        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          style={{
            width: '500px',
            height: '96px',
            border: '1px solid #D5D5DB',
            borderRadius: '3px',
            padding: '12px 0px 36px 12px',
            color: '#92929D',
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 600,
            lineHeight: '20px',
            fontStyle: 'normal',
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault() // Prevent the default action to avoid adding a new line
              handleSave() // Call the save function
            }
          }}
          placeholder="Add notes here"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            gap: '10px',
            marginBottom: 20,
          }}
        >
          <button className={StyleUtil.buttonPrimary} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

function SearchSection(props: any) {
  const [editingDocs, setEditingDocs] = useState<LxFile[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [documents, setDocuments] = useState<LxFile[]>([])
  const refPublishedBy = useRef<string>('')
  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>(null)
  const [publishedByOptions, setPublishedByOptions] = useState<any>([
    publishedByOptionLabel,
  ])
  const [isClickDelete, setIsClickDelete] = useState<boolean>(false)
  const refSearchTextInput = useRef<string>('')
  const refStartDate = useRef<Date | undefined>(undefined)
  const [isFetchedPublishedBy, setIsFetchedPublishedBy] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [orderByFilename, setOrderByFilename] = useState<Direction>('')
  const [orderByPublishedBy, setOrderByPublishedBy] = useState<Direction>('')
  const [orderByDateTime, setOrderByDateTime] = useState<Direction>('')
  const { fileTypeFilter, isManagement } = props
  const { getLxFiles, getLxFilePublishedBy, deleteLxFile, updateLxFile } =
    useApi()
  const { isLogged, getRole, permissions, auth0User: user } = useAuth()

  const searchDocuments = async () => {
    if (!isLogged) return
    const filter = `published=1&publishedBy=${
      refPublishedBy.current
    }&fileTypeFilter=${JSON.stringify(fileTypeFilter)}`
    const search = refSearchTextInput.current
    setIsSearching(true)
    const docs = await getLxFiles(filter, search, refStartDate.current)
    if (docs) {
      setDocuments(docs)
    }
    setIsSearching(false)
  }

  useEffect(() => {
    if (!isLogged) return
    getLxFilePublishedBy().then((users) => {
      let allOptions = [publishedByOptionLabel]
      if (users) {
        let options = users.map((u: DocumentUser, _index: number) => {
          return {
            id: u.publishedAuth0Id,
            name: u.publishedUserName,
            value: u.publishedAuth0Id,
            isLabel: false,
          }
        })
        allOptions = allOptions.concat(options)
        allOptions.push(publishedByOptionShowAll)
        setPublishedByOptions(allOptions)
        setIsFetchedPublishedBy(true)
      }
    })
  }, [getLxFilePublishedBy, isLogged])

  useEffect(() => {
    if (!isLogged) return
    const filter = `published=1&publishedBy=${
      refPublishedBy.current
    }&fileTypeFilter=${JSON.stringify(fileTypeFilter)}`
    const search = refSearchTextInput.current
    setIsSearching(true)

    getLxFiles(filter, search, refStartDate.current).then((docs) => {
      if (docs) {
        setDocuments(docs)
      }
      setIsSearching(false)
    })
  }, [fileTypeFilter, isLogged, getLxFiles])

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.Filename:
        if (orderByFilename === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.PublishedBy:
        if (orderByPublishedBy === 'ascending') return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.DateTime:
        if (orderByDateTime === 'ascending') return 'Sort new to old'
        return 'Sort old to new'
      default:
        return ''
    }
  }

  const sortedDocuments = useMemo(() => {
    let sortableItems = documents?.length ? [...documents] : []
    if (sortConfig !== null) {
      sortableItems.sort((a: LxFile, b: LxFile) => {
        const valA =
          typeof a[sortConfig.key] === 'string'
            ? a[sortConfig.key].toLowerCase()
            : a[sortConfig.key] || ''
        const valB =
          typeof b[sortConfig.key] === 'string'
            ? b[sortConfig.key].toLowerCase()
            : b[sortConfig.key] || ''

        if (valA < valB) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (valA > valB) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [documents, sortConfig])

  const hasPermission = (
    permission: string,
    publishedAuth0Id?: string,
  ): boolean => {
    // https://horizonsfl.atlassian.net/browse/HLP-1465
    if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
    if (
      permissions &&
      permissions.includes(permission) &&
      user?.sub === publishedAuth0Id
    ) {
      // same user
      return true
    }
    return false
  }

  const getActionButtonStyle = (loading: boolean) => {
    const style = StyleUtil.buttonPrimary
    if (loading) {
      return `${style} loading disabled`
    }
    return style
  }

  const onInputChangedSearch = (value: string) => {
    refSearchTextInput.current = value
  }

  const onEnterSearch = async () => {
    await searchDocuments()
  }

  const onSelectDate = async (item: DropDownItem) => {
    const value = item.value
    // console.log('onSelectDate', value)
    if (value === undefined) {
      // show all
      refStartDate.current = undefined
    } else if (value > 0) {
      const startDate = new Date()
      // caculate start date by value which is number of days
      startDate.setDate(startDate.getDate() - value)
      // console.log('startDate', startDate)
      refStartDate.current = startDate
    }
    await searchDocuments()
  }

  const onSelectPublishedBy = async (item: DropDownItem) => {
    refPublishedBy.current = item.value
    await searchDocuments()
  }

  const onClickDelete = () => {
    if (checkedIds.length === 0) {
      ToastUtil.warning('Please select at least one file')
    } else {
      setIsClickDelete(true)
    }
  }

  const onChangeCheckbox = (checked: boolean, id: string) => {
    setIsClickDelete(false)
    if (checked) {
      setCheckedIds([...checkedIds, id])
      // If all checkboxes are checked
      if (checkedIds.length === documents.length - 1) {
        setSelectAll(true)
      }
    } else {
      setCheckedIds(checkedIds.filter((y) => y !== id))
      // If any checkbox is unchecked
      setSelectAll(false)
    }
  }

  const onConfirmDelete = async () => {
    setIsLoading(true)

    for (const x of checkedIds) {
      await deleteLxFile(x)
    }
    await searchDocuments()
    setIsLoading(false)
    ToastUtil.success(`Deleted successfully`)

    document.querySelectorAll('[data-id="checkbox"]').forEach((x: any) => {
      x.checked = false
    })
    setCheckedIds([])
    setIsClickDelete(false)
  }

  const onClickCopy = async (lxFile: LxFile) => {
    navigator.clipboard.writeText(lxFile.fileUrl)
    ToastUtil.success('URL link copied to clipboard')
  }

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.Filename:
        setOrderByFilename(ascending ? 'ascending' : 'descending')
        break
      case SortBy.PublishedBy:
        setOrderByPublishedBy(ascending ? 'ascending' : 'descending')
        break
      case SortBy.DateTime:
        setOrderByDateTime(ascending ? 'ascending' : 'descending')
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? 'ascending' : 'descending' })
  }

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.Filename:
        return orderByFilename === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.PublishedBy:
        return orderByPublishedBy === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.DateTime:
        return orderByDateTime === 'ascending'
          ? tooltipStyleReverse
          : tooltipStyle
      default:
        return tooltipStyle
    }
  }

  const saveNotes = useCallback(
    async (notes: string) => {
      if (!editingDocs.length) return

      for (const doc of editingDocs) {
        await updateLxFile(doc.id, {
          comments: notes,
        })
      }
      setDocuments((currentDocuments) =>
        currentDocuments.map((doc) =>
          editingDocs.some((editingDoc) => editingDoc.id === doc.id)
            ? {
                ...doc,
                comments: notes,
              }
            : doc,
        ),
      )
      ToastUtil.success(`Changed successfully`)
    },
    [editingDocs, updateLxFile, setDocuments],
  )

  return (
    <>
      {editingDocs.length ? (
        <NotesModal
          // key={`notes-modal-${editingDocs.id}`}
          isOpen={editingDocs.length > 0}
          onClose={() => {
            setEditingDocs([])
          }}
          initialNotes={editingDocs.length === 1 ? editingDocs[0].comments : ''}
          onSave={saveNotes}
          // onFilenameSave={saveFilename}
        />
      ) : null}
      {!isFetchedPublishedBy && <Spinner />}
      {isFetchedPublishedBy && (
        <div className="flex">
          <div className="flex-1">
            <div className="flex flex-none flex-row items-center">
              <div className="w-1/3">
                <SearchInput
                  onInputChanged={onInputChangedSearch}
                  onEnter={onEnterSearch}
                  isSearching={isSearching}
                />
              </div>
              <div className="w-10"></div>
              <DropDownMenu
                items={[
                  {
                    id: '0',
                    name: 'Date',
                    value: undefined,
                    isLabel: true,
                  },
                  { id: '1', name: 'Last 7 days', value: 7 },
                  { id: '2', name: 'Last 30 days', value: 30 },
                  { id: '3', name: 'Last 6 months', value: 183 },
                  {
                    id: '4',
                    name: 'All dates',
                    value: undefined,
                    default: true,
                  },
                ]}
                onSelected={onSelectDate}
                customHACK={true}
              />
              <div className="w-20"></div>

              <DropDownMenu
                items={publishedByOptions}
                onSelected={onSelectPublishedBy}
                customHACK={true}
              />
            </div>
            <div className="flex h-[100%] flex-col pt-4">
              {isSearching && <Spinner />}
              {!isSearching && documents.length === 0 && (
                <div className="flex flex-none">
                  <div className="flex flex-none">
                    <span className="mr-4 text-[16px] font-bold">
                      No file(s) found.
                    </span>
                  </div>
                </div>
              )}
              {!isSearching && documents.length > 0 && (
                <>
                  <div className="flex flex-none justify-start">
                    <div className="flex flex-none items-center">
                      {isClickDelete && (
                        <>
                          <span className="mr-4 text-[16px]">
                            Are you sure?
                          </span>
                          <button
                            data-testid="btn-delete-from-catalogue-yes"
                            onClick={onConfirmDelete}
                            className={getActionButtonStyle(isLoading)}
                          >
                            Yes
                          </button>
                          <span
                            data-testid="btn-delete-from-catalogue-no"
                            onClick={() => {
                              setIsClickDelete(false)
                              return false
                            }}
                            className="ml-4 cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                          >
                            Cancel
                          </span>
                        </>
                      )}
                      {!isClickDelete &&
                        hasPermission(Permission.DeleteCatalog) &&
                        isManagement && (
                          <>
                            <input
                              type="checkbox"
                              onChange={() => {
                                setSelectAll(!selectAll)
                                if (!selectAll) {
                                  // If currently not all are selected, select all
                                  setCheckedIds(documents.map((doc) => doc.id))
                                } else {
                                  // If currently all are selected, deselect all
                                  setCheckedIds([])
                                }
                              }}
                              checked={selectAll}
                            />
                            <div
                              className="w-[8px] cursor-pointer"
                              // onClick={onClickLabel}
                            >
                              &nbsp;
                            </div>
                            <label
                              className="checkbox-label cursor-pointer"
                              // onClick={onClickLabel}
                            >
                              Select all
                            </label>
                            <span
                              data-testid="btn-delete-from-catalogue"
                              onClick={onClickDelete}
                              className="ml-5 cursor-pointer text-[14px] font-semibold text-blue-800 underline hover:opacity-[0.8] "
                            >
                              Delete from catalogue
                            </span>
                          </>
                        )}
                    </div>
                  </div>
                  {isManagement && <div className="h-[26px]"></div>}
                  {!isManagement && <div className="h-[6px]"></div>}
                  <div className="flex-1 overflow-y-auto">
                    <table className="mentem-table">
                      <thead>
                        <tr>
                          <th className={StyleUtil.stickyTableHeader1}>
                            <div className="flex flex-row items-center px-2">
                              <span className="grow">File name</span>
                              <img
                                className="cursor-pointer"
                                src={sortSvg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.Filename)}
                                data-tooltip-id="tooltip-filename"
                              />
                            </div>
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader2} w-[17%] min-w-[160px]`}
                          >
                            <div className="flex flex-row items-center px-2">
                              <span className="grow">Published by</span>
                              <img
                                className="cursor-pointer"
                                src={sortSvg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.PublishedBy)}
                                data-tooltip-id="tooltip-published-by"
                              />
                            </div>
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader1} w-[17%] min-w-[149px]`}
                          >
                            <div className="flex flex-row items-center px-2">
                              <span className="grow">Date/Time</span>
                              <img
                                className="cursor-pointer pl-2 pr-2"
                                src={sort2Svg}
                                alt="sort"
                                onClick={() => onClickSort(SortBy.DateTime)}
                                data-tooltip-id="tooltip-date-time"
                              />
                            </div>
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader2} w-[1%]`}
                          >
                            View
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader1} w-[1%]`}
                          >
                            Notes
                          </th>
                          <th
                            className={`${StyleUtil.stickyTableHeader2} w-[1%]`}
                          >
                            Copy link
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedDocuments.map(
                          (lxFile: LxFile, index: number) => {
                            return (
                              <tr data-testid="tr-document" key={index}>
                                <td className="mentem-table-cell-2 mentem-title-column">
                                  <div className="flex flex-row">
                                    {isManagement && (
                                      <input
                                        type="checkbox"
                                        className="self-center"
                                        disabled={
                                          !hasPermission(
                                            Permission.DeleteCatalog,
                                          )
                                        }
                                        data-id="checkbox"
                                        checked={checkedIds.includes(lxFile.id)}
                                        onChange={(e) =>
                                          onChangeCheckbox(
                                            e.target.checked,
                                            lxFile.id,
                                          )
                                        }
                                      />
                                    )}
                                    <div className="pl-2">
                                      <div className="mentem-title-box">
                                        {lxFile.name}
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td className="mentem-table-cell-1">
                                  <div className="flex items-center justify-center">
                                    {lxFile.publishedUserName || 'Unknown'}
                                  </div>
                                </td>
                                <td className="mentem-table-cell-2">
                                  <div className="flex items-center justify-center">
                                    {getDateText(lxFile.createdAt)}
                                  </div>
                                  <div className="flex items-center justify-center">
                                    {getTimeText(lxFile.createdAt)}
                                  </div>
                                </td>
                                <td className="mentem-table-cell-1">
                                  <div className="flex items-center justify-center">
                                    <a
                                      href={lxFile.fileUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={getExtensionIcon(lxFile.name)}
                                        alt={lxFile.name}
                                      />
                                    </a>
                                  </div>
                                </td>
                                <td className="mentem-table-cell-2">
                                  <div className="flex items-center justify-center">
                                    {/*commented out because makes page slow*/}
                                    {/*  <Tooltip id={`tt-comments-${doc.id}`}></Tooltip>*/}
                                    <button
                                      onClick={() => {
                                        if (
                                          hasPermission(
                                            Permission.UpdateCatalog,
                                          )
                                        ) {
                                          if (checkedIds.length === 0) {
                                            setEditingDocs([lxFile])
                                          } else {
                                            setEditingDocs(
                                              documents.filter((doc) =>
                                                checkedIds.includes(doc.id),
                                              ),
                                            )
                                          }
                                        } else {
                                          ToastUtil.warning(
                                            'You do not have permission to edit',
                                          )
                                        }
                                      }}
                                    >
                                      <img
                                        data-tooltip-id={`tt-comments-${lxFile.id}`}
                                        data-tooltip-content={
                                          lxFile.comments || 'No notes'
                                        }
                                        src={
                                          lxFile.comments
                                            ? hasNotesSvg
                                            : noNotesSvg
                                        }
                                        alt={lxFile.comments}
                                      />{' '}
                                    </button>
                                  </div>
                                </td>
                                <td className="mentem-table-cell-1">
                                  <div className="flex items-center justify-center">
                                    <img
                                      className="cursor-pointer"
                                      src={copySvg}
                                      alt="copy"
                                      onClick={() => {
                                        onClickCopy(lxFile)
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          },
                        )}
                      </tbody>
                    </table>
                    <Tooltip
                      id="tooltip-filename"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.Filename)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.Filename)}
                    </Tooltip>
                    <Tooltip
                      id="tooltip-published-by"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.PublishedBy)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.PublishedBy)}
                    </Tooltip>
                    <Tooltip
                      id="tooltip-date-time"
                      className="mentem-tooltip"
                      style={getTooptipStyle(SortBy.DateTime)}
                      place="top"
                      noArrow={true}
                    >
                      {getSortingText(SortBy.DateTime)}
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function MediaPage() {
  const [sideBarMinimized, setSideBarMinimized] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState<Section>(
    Section.ImagesSection,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fileTypeFilter, setFileTypeFilter] = useState({
    documents: false,
    presentations: false,
    spreadsheets: false,
    audio: false,
    videos: false,
    other: false,
  })
  const [description, setDescription] = useState('')
  const isActiveSection = (targetSection: Section) => {
    let className =
      activeSection === targetSection
        ? 'text-white bg-primary border-primary'
        : 'text-[#3C415D] bg-white'
    className = `${className} mentem-menu-button btn-sm btn rounded-3xl hover:text-white hover:bg-primary hover:border-primary normal-case ml-6 mr-6 mt-2 mb-2`
    return className
  }

  const { auth0User: user, permissions, getRole } = useAuth()
  const { uploadLxFile } = useApi()

  const refInput = useRef<HTMLInputElement | null>(null)
  const [uploadedFiles, setUploadedFiles] = useState<UploadedLxFile[]>([])
  const [filesToUpload, setFilesToUpload] = useState<LxFileContainer[]>([])
  const refSelectedFileUrls = useRef<string[]>([])

  const [uploadProgress, setUploadProgress] = useState<any>({})

  const addFiles = (newFiles: File[]) => {
    const tmpFiles = newFiles.map((file) => {
      return {
        objectURL: URL.createObjectURL(file),
        file: file,
        filenameOverride: file.name,
      }
    })
    setFilesToUpload(filesToUpload.concat(tmpFiles))
    refSelectedFileUrls.current = refSelectedFileUrls.current.concat(
      tmpFiles.map((file) => file.objectURL),
    )
  }

  // for drag and drop
  // let counter = 0

  const hasFiles = (e: React.DragEvent<HTMLDivElement>) => {
    const types = e.dataTransfer.types
    return types.indexOf('Files') > -1
  }

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    // console.log(e.dataTransfer.files)
    const newFiles: File[] = []
    for (const file of e.dataTransfer.files) {
      if (!file.type.startsWith('image/')) {
        // Exclude image files
        newFiles.push(file)
      } else {
        ToastUtil.error(
          `File catalogue does not support image upload. Please search the image catalogue or make a design request if you need a new asset.`,
        )
      }
    }
    addFiles(newFiles)
  }
  const onDragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!hasFiles(e)) {
      return
    }
    // ++counter && refOverlay.current?.classList.add('draggedover')
  }

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // 1 > --counter && refOverlay.current?.classList.remove('draggedover')
  }

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    if (hasFiles(e)) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onInputFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || []
    console.log(files)
    const newFiles: File[] = []
    for (const file of files) {
      if (!file.type.startsWith('image/')) {
        // Exclude image files
        newFiles.push(file)
      } else {
        ToastUtil.error(
          `File catalogue does not support image upload. Please search the image catalogue or make a design request if you need a new asset.`,
        )
      }
    }
    addFiles(newFiles)
    // reset selected files
    refInput.current!.value = ''
  }

  const onDeletePreview = (id: string) => {
    // delete file by id
    // console.log(`delete id = ${id}`)
    const newFiles = filesToUpload.filter((file) => {
      return file.objectURL !== id
    })
    setFilesToUpload(newFiles)
  }

  const onCopy = (url: string) => {
    // check if url is encoded
    url = url.indexOf('%') > -1 ? url : encodeURI(url)
    navigator.clipboard.writeText(url)
    ToastUtil.success('URL link copied to clipboard')
  }

  const onSubmit = async () => {
    // console.log(refAddTagInput.current?.value)
    if (filesToUpload.length === 0) {
      ToastUtil.error('Please select files')
      return
    }

    let username = ''
    if (user?.given_name && user?.family_name) {
      username = `${user?.given_name} ${user?.family_name}`
    } else {
      username = user?.nickname || user?.name || ''
    }

    setIsLoading(true)
    const uploadedFiles: UploadedLxFile[] = []
    const failedFiles: LxFileContainer[] = []
    for (const theFile of filesToUpload) {
      let formData = new FormData()
      formData.append('file', theFile.file)
      formData.append('auth0Id', user?.sub || '')
      formData.append('filenameOverride', theFile.filenameOverride)
      formData.append('description', description)
      formData.append('username', username)

      try {
        const data = await uploadLxFile(
          formData,
          (percentCompleted: number) => {
            // Update progress state with percentCompleted
            setUploadProgress((progressInfo: any) => ({
              ...progressInfo,
              [theFile.objectURL]: {
                progress: percentCompleted,
                status: 'uploading', // Indicate that this file is currently being uploaded
              },
            }))
          },
        )
        // On successful upload, update the status to 'success'
        setUploadProgress((progressInfo: any) => ({
          ...progressInfo,
          [theFile.objectURL]: {
            ...progressInfo[theFile.objectURL],
            status: 'success',
          },
        }))
        uploadedFiles.push({
          url: data.fileUrl,
          filename: theFile.filenameOverride,
        })
      } catch (err: any) {
        // On failure, update the status to 'failed'
        setUploadProgress((progressInfo: any) => ({
          ...progressInfo,
          [theFile.objectURL]: {
            ...progressInfo[theFile.objectURL],
            progress: 100, // Optional, to show the progress bar as "full"
            status: 'failed',
          },
        }))
        console.error(err)
        Sentry.captureException(err)
        if (err.response?.data?.message) {
          ToastUtil.error(err.response.data.message)
        } else if (err.message) {
          ToastUtil.error(err.message)
        } else {
          ToastUtil.error(`Upload failed<br/>${err}`)
        }
        failedFiles.push(theFile)
      }
    }
    setIsLoading(false)
    setUploadedFiles(uploadedFiles)
    // reset files
    setFilesToUpload(failedFiles)
    refSelectedFileUrls.current = []

    uploadedFiles.length &&
      ToastUtil.success(`Uploaded ${uploadedFiles.length} files successfully`)

    if (!failedFiles.length) {
      setDescription('')
    }
  }

  const hasPermission = (
    permission: string,
    publishedAuth0Id?: string,
  ): boolean => {
    // https://horizonsfl.atlassian.net/browse/HLP-1465
    if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
    if (
      permissions &&
      permissions.includes(permission) &&
      user?.sub === publishedAuth0Id
    ) {
      // same user
      return true
    }
    return false
  }
  return (
    <SidebarLayout>
      <style>
        {`
                      .mentem-table tr:hover td {
                      background-color: #f4e7ff !important;
                    }
                      .mentem-table tr:hover td:nth-child(2n) {
                      background-color: #eaddf6 !important;
                    }
                    .file-catalogue-tab.minimized {
                      width: 60px;
                      height: 60px;
                      padding-top: 12px;
                      margin-top: 12px
                    }
            #media-sidebar {
              position: relative;
              border-right: #D5D5DB solid 1px;
              width: 150px;
              height: 100vh;
              background: white;
              z-index: 100;
            }
            #media-sidebar.minimized {
              // width: 50px;
            }
              .accent-thing-container {
                position: absolute;
                top: 50%;
                right: 0;
                height: 96px;
                transform: translate(-2px, -50%);
              }
              
              .accent-thing {
                width: 3.75px; /* Width of the scrollbar */
                height: 100%; /* Height of the scrollbar */
                background-color: #BA61FF; /* Purple color for the scrollbar */
                border-radius: 2px; /* Rounded corners for the scrollbar */
              }
            `}
      </style>
      {[Section.Search, Section.ManageSection, Section.ImagesSection].includes(
        activeSection,
      ) && (
        <div
          style={{
            position: 'fixed',
          }}
        >
          <div
            id="media-sidebar"
            className={sideBarMinimized ? 'minimized' : ''}
            style={{
              width: sideBarMinimized ? '70px' : '150px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                textAlign: 'center',
                maxWidth: sideBarMinimized ? '70px' : '130px',
              }}
            >
              <button
                className="w-full"
                onClick={() => {
                  setActiveSection(Section.ImagesSection)
                  setFileTypeFilter({
                    documents: false,
                    presentations: false,
                    spreadsheets: false,
                    audio: false,
                    videos: false,
                    other: false,
                  })
                }}
              >
                <div
                  className={`file-catalogue-tab ${
                    activeSection === Section.ImagesSection ? 'active' : ''
                  } ${sideBarMinimized ? 'minimized' : ''}`}
                >
                  <FcImagesSvg className="mx-auto w-[27px]" />
                  {sideBarMinimized ? '' : <p>Images</p>}
                </div>
              </button>
              <button
                className="w-full"
                onClick={() => {
                  if (
                    ![Section.Search, Section.ManageSection].includes(
                      activeSection,
                    )
                  ) {
                    setActiveSection(Section.Search)
                  }
                  setFileTypeFilter({
                    documents: true,
                    presentations: false,
                    spreadsheets: false,
                    audio: false,
                    videos: false,
                    other: false,
                  })
                }}
              >
                <div
                  className={`file-catalogue-tab ${
                    fileTypeFilter.documents ? 'active' : ''
                  } ${sideBarMinimized ? 'minimized' : ''}`}
                >
                  <FcDocumentsSvg className="mx-auto w-[27px]" />
                  {sideBarMinimized ? '' : <p>Documents</p>}
                </div>
              </button>
              <button
                className="w-full"
                onClick={() => {
                  if (
                    ![Section.Search, Section.ManageSection].includes(
                      activeSection,
                    )
                  ) {
                    setActiveSection(Section.Search)
                  }
                  setFileTypeFilter({
                    documents: false,
                    presentations: true,
                    spreadsheets: false,
                    audio: false,
                    videos: false,
                    other: false,
                  })
                }}
              >
                <div
                  className={`file-catalogue-tab ${
                    fileTypeFilter.presentations ? 'active' : ''
                  } ${sideBarMinimized ? 'minimized' : ''}`}
                >
                  <FcPresentationsSvg className="mx-auto w-[27px]" />
                  {sideBarMinimized ? '' : <p>Presentations</p>}
                </div>
              </button>
              <button
                className="w-full"
                onClick={() => {
                  if (
                    ![Section.Search, Section.ManageSection].includes(
                      activeSection,
                    )
                  ) {
                    setActiveSection(Section.Search)
                  }
                  setFileTypeFilter({
                    documents: false,
                    presentations: false,
                    spreadsheets: true,
                    audio: false,
                    videos: false,
                    other: false,
                  })
                }}
              >
                <div
                  className={`file-catalogue-tab ${
                    fileTypeFilter.spreadsheets ? 'active' : ''
                  } ${sideBarMinimized ? 'minimized' : ''}`}
                >
                  <FcSpreadsheetsSvg className="mx-auto w-[27px]" />
                  {sideBarMinimized ? '' : <p>Spreadsheets</p>}
                </div>
              </button>
              <button
                className="w-full"
                onClick={() => {
                  if (
                    ![Section.Search, Section.ManageSection].includes(
                      activeSection,
                    )
                  ) {
                    setActiveSection(Section.Search)
                  }
                  setFileTypeFilter({
                    documents: false,
                    presentations: false,
                    spreadsheets: false,
                    audio: true,
                    videos: false,
                    other: false,
                  })
                }}
              >
                <div
                  className={`file-catalogue-tab ${
                    fileTypeFilter.audio ? 'active' : ''
                  } ${sideBarMinimized ? 'minimized' : ''}`}
                >
                  <FcAudioSvg className="mx-auto w-[27px]" />
                  {sideBarMinimized ? '' : <p>Audio</p>}
                </div>
              </button>
              <button
                className="w-full"
                onClick={() => {
                  if (
                    ![Section.Search, Section.ManageSection].includes(
                      activeSection,
                    )
                  ) {
                    setActiveSection(Section.Search)
                  }
                  setFileTypeFilter({
                    documents: false,
                    presentations: false,
                    spreadsheets: false,
                    audio: false,
                    videos: true,
                    other: false,
                  })
                }}
              >
                <div
                  className={`file-catalogue-tab ${
                    fileTypeFilter.videos ? 'active' : ''
                  } ${sideBarMinimized ? 'minimized' : ''}`}
                >
                  <FcVideoSvg className="mx-auto w-[27px]" />
                  {sideBarMinimized ? '' : <p>Videos</p>}
                </div>
              </button>
              <button
                className="w-full"
                onClick={() => {
                  if (
                    ![Section.Search, Section.ManageSection].includes(
                      activeSection,
                    )
                  ) {
                    setActiveSection(Section.Search)
                  }
                  setFileTypeFilter({
                    documents: false,
                    presentations: false,
                    spreadsheets: false,
                    audio: false,
                    videos: false,
                    other: true,
                  })
                }}
              >
                <div
                  className={`file-catalogue-tab ${
                    fileTypeFilter.other ? 'active' : ''
                  } ${sideBarMinimized ? 'minimized' : ''}`}
                >
                  <FcOtherSvg className="mx-auto w-[27px]" />
                  {sideBarMinimized ? '' : <p>Others</p>}
                </div>
              </button>
            </div>
            <div className="accent-thing-container">
              <div className="accent-thing"></div>
            </div>
            <div
              className={
                sideBarMinimized
                  ? 'sidebar-button-left'
                  : 'sidebar-button-right'
              }
              style={{
                left: sideBarMinimized ? 50 : 150,
                position: 'relative',
                backgroundColor: '#BA61FF',
              }}
            >
              <button onClick={() => setSideBarMinimized(!sideBarMinimized)}>
                <img
                  src={sideBarMinimized ? menuRight : menuLeft}
                  alt="menu-button"
                  style={{
                    // filter: 'invert(100%)',
                    // backgroundColor: '#BA61FF',
                    marginLeft: 7,
                    marginTop: 7,
                    width: 27,
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={StyleUtil.container}
        style={{
          ...((activeSection === Section.Search ||
            activeSection === Section.ManageSection ||
            activeSection === Section.ImagesSection) && {
            marginLeft: '150px',
            width: 'auto',
          }),
        }}
      >
        <p className={StyleUtil.headline}>
          Media catalogue /{' '}
          {activeSection === Section.ImagesSection
            ? 'Images'
            : activeSection === Section.Search && fileTypeFilter.documents
            ? 'Documents'
            : activeSection === Section.Search && fileTypeFilter.presentations
            ? 'Presentations'
            : activeSection === Section.Search && fileTypeFilter.spreadsheets
            ? 'Spreadsheets'
            : activeSection === Section.Search && fileTypeFilter.audio
            ? 'Audio'
            : activeSection === Section.Search && fileTypeFilter.videos
            ? 'Videos'
            : activeSection === Section.Search && fileTypeFilter.other
            ? 'Others'
            : activeSection === Section.Upload
            ? 'Upload'
            : activeSection === Section.ManageSection &&
              fileTypeFilter.documents
            ? 'Documents'
            : activeSection === Section.ManageSection &&
              fileTypeFilter.presentations
            ? 'Presentations'
            : activeSection === Section.ManageSection &&
              fileTypeFilter.spreadsheets
            ? 'Spreadsheets'
            : activeSection === Section.ManageSection && fileTypeFilter.audio
            ? 'Audio'
            : activeSection === Section.ManageSection && fileTypeFilter.videos
            ? 'Videos'
            : activeSection === Section.ManageSection && fileTypeFilter.other
            ? 'Others'
            : ''}
        </p>
        {activeSection !== Section.ImagesSection && (
          <div className="pt-8 text-center">
            <button
              className={isActiveSection(Section.Search)}
              onClick={() =>
                setActiveSection(
                  activeSection === Section.Search
                    ? Section.None
                    : Section.Search,
                )
              }
            >
              <span className="pl-1 pr-1">Search</span>
            </button>
            <button
              className={isActiveSection(Section.Upload)}
              onClick={() =>
                setActiveSection(
                  activeSection === Section.Upload
                    ? Section.None
                    : Section.Upload,
                )
              }
            >
              <span className="pl-1 pr-1">Upload</span>
            </button>

            {hasPermission(Permission.UpdateCatalog) && (
              <button
                className={isActiveSection(Section.ManageSection)}
                onClick={() =>
                  setActiveSection(
                    activeSection === Section.ManageSection
                      ? Section.None
                      : Section.ManageSection,
                  )
                }
              >
                <span className="pl-1 pr-1">Manage</span>
              </button>
            )}
          </div>
        )}
        <main className="mb-8 mt-4 max-w-full">
          {activeSection === Section.Search && (
            <SearchSection
              fileTypeFilter={fileTypeFilter}
              setFileTypeFilter={setFileTypeFilter}
              isManagement={false}
            />
          )}
          {activeSection === Section.Upload && (
            <>
              <div className="mb-4 ml-[2%] mr-[2%] mt-4 flex w-full flex-col">
                <div className="flex justify-center py-4">
                  <div className="relative w-full max-w-lg">
                    <label className="w-full">
                      <input
                        data-testid="input-description"
                        id="input-description"
                        type="text"
                        className={StyleUtil.inputSearch}
                        placeholder="Add file note(s) here if required"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <article
                aria-label="File Upload Modal"
                className="relative flex h-full flex-col rounded-md bg-white shadow-xl"
                style={{
                  maxWidth: '829px',
                  // maxHeight: '695px',
                  padding: '72px',
                  margin: '0 auto',
                  borderRadius: '15px',
                }}
                onDrop={onDropHandler}
                onDragOver={onDragOverHandler}
                onDragLeave={onDragLeaveHandler}
                onDragEnter={onDragEnterHandler}
              >
                <section className="m-0 flex h-full h-full w-full flex-col overflow-hidden p-0">
                  <header className="border-1 flex flex-col items-center justify-center border border-gray-400 py-16">
                    <p className="flex flex-wrap justify-center py-8 font-semibold text-gray-900">
                      <span>Drag file(s) here to upload</span>
                    </p>
                    <input
                      data-testid="hidden-input"
                      id="hidden-input"
                      title="hidden-input"
                      type="file"
                      multiple={true}
                      className="hidden"
                      ref={refInput}
                      onChange={onInputFiles}
                    />
                    <button
                      data-testid="btn-select"
                      type="button"
                      id="btn-select"
                      onClick={() => refInput.current?.click()}
                      className={`${StyleUtil.buttonPrimary} mb-8 mt-8`}
                    >
                      <span className="pl-2 pr-2">Select files</span>
                    </button>
                  </header>

                  {filesToUpload.length !== 0 && (
                    <h1 className="pt-[36px] font-semibold text-gray-900 sm:text-lg">
                      Preview
                    </h1>
                  )}

                  <ul id="gallery" className="flex flex-1 flex-wrap">
                    {filesToUpload.map((file, index) => {
                      const fileUploadProgress = uploadProgress[file.objectURL]
                      const progressBarColor = fileUploadProgress
                        ? fileUploadProgress.status === 'failed'
                          ? 'red'
                          : 'blue' // Or whatever color signifies normal progress
                        : 'transparent' // No progress yet
                      return (
                        <li
                          id={file.objectURL}
                          className="block w-full p-1 pt-[36px]"
                          key={file.objectURL}
                        >
                          <FilePreview
                            key={index}
                            fileObject={file}
                            onDelete={onDeletePreview}
                            onNameChange={(id, newName) => {
                              const newFiles = filesToUpload.map((file) => {
                                if (file.objectURL === id) {
                                  file.filenameOverride = newName
                                }
                                return file
                              })
                              setFilesToUpload(newFiles)
                            }}
                          />
                          <progress
                            value={fileUploadProgress?.progress || 0}
                            max="100"
                            style={{ color: progressBarColor }}
                          ></progress>
                          {fileUploadProgress?.progress || 0}%
                          {fileUploadProgress &&
                            fileUploadProgress.status === 'failed' && (
                              <span style={{ color: 'red' }}>
                                Upload failed!
                              </span>
                            )}
                        </li>
                      )
                    })}
                  </ul>
                </section>
                {isLoading && <Spinner />}
                <div className="flex justify-start text-blue-900">
                  <ul className="w-full">
                    {uploadedFiles &&
                      uploadedFiles.map((upFile, index) => {
                        return (
                          <li key={index} className="mt-[32px] ">
                            <div className="mb-[32px] flex flex-row items-center justify-center">
                              <img
                                alt={upFile.filename}
                                className="w-[36px] p-2"
                                src={getExtensionIcon(upFile.filename)}
                              />
                              <span className="font-w text-lg font-medium text-gray-700">
                                {upFile.filename}
                              </span>
                            </div>
                            <div className="flex flex-row items-center justify-center">
                              <a
                                href={upFile.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {upFile.url}
                              </a>
                              <ButtonCopy url={upFile.url} onClick={onCopy} />
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </div>
                <footer className="m-0 mt-[36px] flex justify-center p-0">
                  <button
                    data-testid="btn-submit"
                    type="button"
                    id="btn-submit"
                    onClick={onSubmit}
                    disabled={isLoading}
                    className={StyleUtil.buttonPrimary}
                  >
                    <span className="pl-2 pr-2 ">Upload</span>
                  </button>
                </footer>
              </article>
            </>
          )}
          {hasPermission(Permission.UpdateCatalog) &&
            activeSection === Section.ManageSection && (
              <SearchSection
                fileTypeFilter={fileTypeFilter}
                setFileTypeFilter={setFileTypeFilter}
                isManagement={true}
              />
            )}

          {activeSection === Section.ImagesSection && <ImagesSection />}
        </main>
      </div>
    </SidebarLayout>
  )
}

export default MediaPage
