import { useState } from 'react'
import { Link } from 'react-router-dom'
import { HandbookAPI } from 'apis/HandbookAPI'
import { Contentlet } from 'apis/entities/contentlet.entity'
import { stemmer } from 'stemmer'
import SidebarLayout from 'components/SidebarLayout'
import { StyleUtil } from 'utils/StyleUtil'

function SourcingPage() {
  const [isLoading, setIsLoading] = useState(false)
  const [contentlets, setContentlets] = useState<Contentlet[]>() //default undefined
  const [searchInput, setSearchInput] = useState('')

  const search = async (text: string) => {
    try {
      setIsLoading(true)
      const data = await HandbookAPI.search(text)
      console.log(data)
      setContentlets(data.contentlets)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  const onSubmitForm = function (e: React.FormEvent) {
    e.preventDefault()
    if (searchInput) {
      const searchTerm = stemmer(searchInput)
      search(searchTerm)
    }
  }

  const handleCompare = () => {
    let url = 'https://www.handbook.unsw.edu.au/'
    if (searchInput) {
      url += 'search?q=' + encodeURIComponent(searchInput.trim())
    }
    window.open(url, '_blank', 'noreferrer')
  }

  const searchBar = () => {
    return (
      <form className="flex items-center" noValidate onSubmit={onSubmitForm}>
        <div className="relative w-full">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="search"
            className={`${StyleUtil.inputSearchWithIcon}`}
            placeholder="What are you looking for?"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={`${StyleUtil.buttonPrimary} ml-2`}>
          <svg
            className="mr-2 -ml-1 w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
          Search
        </button>
        <button
          onClick={handleCompare}
          className={`${StyleUtil.buttonPrimary} ml-2`}
        >
          Open UNSW Handbook
        </button>
      </form>
    )
  }

  const highlightedKeywords = (text: string) => {
    try {
      if (text && searchInput.length > 1) {
        const span = `<span style="background-color:#385676;">`
        const regExp = new RegExp(searchInput, 'gi')
        const replaceMask = `${span}$&</span>`
        return text.replace(regExp, replaceMask)
      }
    } catch (err) {
      console.error(err)
    }
    return text
  }

  const searchResultsByHandbook = () => {
    return contentlets?.map((element, index) => {
      return (
        <div key={index} className="p-1 m-4">
          <Link
            to={
              'https://www.handbook.unsw.edu.au' + element.URL_MAP_FOR_CONTENT
            }
            target="_blank"
          >
            <h2 className="font-bold underline text-black">
              <div
                dangerouslySetInnerHTML={{
                  __html: highlightedKeywords(
                    `${element.code} ${element.title}`,
                  ),
                }}
              ></div>
            </h2>
          </Link>
          <div
            className="text-black"
            dangerouslySetInnerHTML={{
              __html: highlightedKeywords(element.description),
            }}
          />
        </div>
      )
    })
  }

  const noSearchResults = () => {
    return (
      <div className="p-1 m-4 text-black text-center">No result found.</div>
    )
  }

  return (
    <SidebarLayout>
      <div className={StyleUtil.container}>
        <p className={StyleUtil.headline}>Custom search for UNSW handbook</p>
        <div className="text-center py-4">
          Input keywords to search courses and programs. <br></br>This search
          tends to get more results than searching directly from UNSW Handbook.
        </div>
        <div className="pt-8 sticky top-0 bg-white z-50">{searchBar()}</div>
        {isLoading && (
          <div>
            <p className="p-8 text-center text-black">Loading...</p>
          </div>
        )}
        {!isLoading && (
          <div
            id="result"
            data-testid="result"
            className="pt-2 pb-20 pl-10 pr-10 relative z-0"
          >
            {contentlets && contentlets.length > 0 && searchResultsByHandbook()}
            {contentlets?.length === 0 && noSearchResults()}
          </div>
        )}
      </div>
    </SidebarLayout>
  )
}

export default SourcingPage
