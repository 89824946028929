import { useState } from 'react'

export type ChipItem = {
  id: string
  name: string
  value?: any
}

type Props = {
  items: ChipItem[]
  onSelected: (item: ChipItem[] | []) => void
}

export default function ChipList({ items, onSelected }: Props) {
  // const [selectedId, setSelectedId] = useState<string>()
  const [ids, setIds] = useState<string[]>([])

  const onClick = (item: ChipItem) => {
    const updatedIds = ids.includes(item.id)
      ? ids.filter((id) => id !== item.id) // Remove if already selected
      : [...ids, item.id] // Add if not selected yet

    setIds(updatedIds)
    onSelected(items.filter((item) => updatedIds.includes(item.id)))
    // if (selectedId === item.id) {
    //   setSelectedId(undefined)
    //   onSelected(null)
    // } else {
    //   setSelectedId(item.id)
    //   onSelected(item)
    // }
  }

  const getStyle = (itemId: string) => {
    const style =
      '[word-wrap: break-word] mentem-chip-item text-[#3C415D] my-[5px] mr-3 flex h-[24px] cursor-pointer items-center justify-between rounded-[6px] border border-[#dfe0e1] bg-[#eceff1] bg-[transparent] px-[12px] hover:text-primary shadow-none transition-[opacity] duration-300 ease-linear hover:border-primary hover:!shadow-none'
    if (ids.includes(itemId)) {
      return `${style} bg-primary text-white hover:text-white border-primary`
    }
    return `${style}`
  }

  return (
    <div className="flex flex-wrap items-center">
      {items.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => onClick(item)}
            data-te-chip-init
            data-te-ripple-init
            className={getStyle(item.id)}
            data-te-ripple-color="dark"
          >
            {item.name}
          </div>
        )
      })}
    </div>
  )
}
