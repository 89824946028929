import { useEffect, useRef, useState } from 'react'
import Spinner from '../views/Spinner'

const DocModifyVal = (props: any) => {
  const [docVal, setDocVal] = useState(props.what)
  const [docModifyLoading, setDocModifyLoading] = useState<boolean>(false)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  const insertAtCursor = (text: string) => {
    const textarea = textareaRef.current
    if (!textarea) return

    const start = textarea.selectionStart
    const end = textarea.selectionEnd

    const newText =
      docVal.substring(0, start) + text + docVal.substring(end, docVal.length)
    setDocVal(newText)
    textarea.focus()
    textarea.setSelectionRange(start + 1, start + 1)
  }

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px'
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = scrollHeight + 'px'
    }
  }, [docVal])

  return (
    <>
      {docModifyLoading && <Spinner />}
      {!docModifyLoading && (
        <div className="py-1">
          <textarea
            ref={textareaRef}
            className={`mb-[-5px] w-full p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500 ${
              props.className || ''
            }`}
            value={docVal || ''}
            placeholder={props.placeholder || ''}
            style={{ overflow: 'hidden', ...props.style }}
            onChange={(e) => {
              if (props.changeVal) {
                setDocVal(props.changeVal(docVal, e.target.value))
              } else {
                setDocVal(e.target.value)
              }
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                if (e.shiftKey) {
                  // shift + enter => new line
                  e.preventDefault()
                  insertAtCursor('\n')
                } else {
                  e.preventDefault()
                  setDocModifyLoading(true)
                  await props.updateVal(docVal)
                  setDocModifyLoading(false)
                }
              }
            }}
          />
        </div>
      )}
    </>
  )
}
export default DocModifyVal
