export const getDateText = (dateString: string): string => {
  const x = new Date(dateString)
  const date = x.getDate()
  const month = x.toLocaleString('default', { month: 'short' })
  const year = x.getFullYear()
  return `${date}\u00A0${month}\u00A0${year}`
}

export const getTimeText = (dateString: string): string => {
  const x = new Date(dateString)
  const hour = x.getHours()
  const minute = x.getMinutes()
  // add leading zero
  const hourStr = hour < 10 ? `0${hour}` : `${hour}`
  const minuteStr = minute < 10 ? `0${minute}` : `${minute}`
  return `${hourStr}:${minuteStr}`
}
