interface Props {
  url: string
  onClick: (url: string) => void
}

export function ButtonCopy({ url, onClick }: Props) {
  return (
    <button
      type="button"
      className="ml-2 mr-2 focus:outline-none hover:bg-gray-300 rounded-md"
      data-id={url}
      onClick={() => onClick(url)}
    >
      <svg
        className="pointer-events-none fill-current w-4 h-4 ml-auto"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9949 3.72998C13.9037 2.15517 12.5977 0.90625 11 0.90625H3L2.82373 0.911343C1.24892 1.00259 0 2.30857 0 3.90625V11.9062L0.00509269 12.0825C0.0963391 13.6573 1.40232 14.9062 3 14.9062H4V15.9062C4 17.5631 5.34315 18.9062 7 18.9062H15C16.6569 18.9062 18 17.5631 18 15.9062V7.90625C18 6.2494 16.6569 4.90625 15 4.90625H14V3.90625L13.9949 3.72998ZM12 4.90625V3.90625C12 3.39341 11.614 2.97074 11.1166 2.91298L11 2.90625H3C2.48716 2.90625 2.06449 3.29229 2.00673 3.78963L2 3.90625V11.9062C2 12.4191 2.38604 12.8418 2.88338 12.8995L3 12.9062H4V7.90625C4 6.2494 5.34315 4.90625 7 4.90625H12ZM6 13.9062V15.9062C6 16.4585 6.44772 16.9062 7 16.9062H15C15.5523 16.9062 16 16.4585 16 15.9062V7.90625C16 7.35397 15.5523 6.90625 15 6.90625H13H7C6.44772 6.90625 6 7.35397 6 7.90625V13.9062Z"
          fill="#51597B"
        />
      </svg>
    </button>
  )
}
